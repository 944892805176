import React, { Dispatch } from 'react';
import MaterialTable, { Column, EditComponentProps } from '@material-table/core';
import { Car } from '../../../api';
import { Action, CarServiceWithIsNewAndIsUpdated } from '../car.state';
import {
  MaterialTableHeader,
  materialTableComponents,
  materialTableIcons,
  materialTableLocalization,
  materialTableOptions,
} from '../../../components/MaterialTable';
import { dateRequired, carServiceTitleAndDescription } from '../../../validation';
import { DateTime } from 'luxon';
import { dateFormat } from '../../../formatters';
import { isDate } from 'lodash';
import { getJSDateOrNull, StandardDatePicker } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { TextField } from '@mui/material';

type CarServiceWithTableData = CarServiceWithIsNewAndIsUpdated & {
  //Material table requires tableData
  tableData?: {
    id: number;
    index: number;
    editing: string;
  };
};

interface CarServiceTableProps {
  carId: Car['id'];
  carServices: CarServiceWithTableData[];
  dispatch: Dispatch<Action>;
}

type WithHelperText = {
  helperText?: string;
};

const validators = {
  service_date: dateRequired,
  title: carServiceTitleAndDescription,
  description: carServiceTitleAndDescription,
} as const;

const createValidator = (fieldName: keyof typeof validators): ((row: CarServiceWithTableData) => string | boolean) => {
  return (row: CarServiceWithTableData): string | true => {
    const result = validators[fieldName].validate(row[fieldName]);
    if (result.error !== undefined) {
      return result.error.message;
    }
    return true;
  };
};

export const CarServiceTable: React.FC<CarServiceTableProps> = ({ carId, carServices, dispatch }) => {
  const data: Array<CarServiceWithTableData> = carServices;
  const columns: Array<Column<CarServiceWithTableData>> = [
    {
      title: 'Päivämäärä',
      field: 'service_date',
      type: 'date',
      width: '12rem',
      validate: createValidator('service_date'),
      render: (rowData) =>
        rowData.service_date ? DateTime.fromJSDate(rowData.service_date).toFormat(dateFormat) : null,
      editComponent: (props: EditComponentProps<CarServiceWithTableData> & WithHelperText) => (
        <StandardDatePicker
          value={props.value ?? null}
          className="service-date"
          slotProps={{
            textField: {
              name: 'service_date',
              error: props.error,
              helperText: props.helperText,
            },
          }}
          onChange={(date) => props.onRowDataChange({ ...props.rowData, service_date: getJSDateOrNull(date) })}
        />
      ),
    },
    {
      title: 'Otsikko',
      field: 'title',
      width: '12rem',
      validate: createValidator('title'),
      editComponent: (props: EditComponentProps<CarServiceWithTableData> & WithHelperText) => (
        <TextField
          className="title"
          name="title"
          id="title"
          error={props.error}
          helperText={props.helperText}
          placeholder="Otsikko"
          onChange={(e) =>
            props.onRowDataChange({
              ...props.rowData,
              title: e.target.value,
            })
          }
          InputProps={{
            style: { fontSize: '0.875rem', lineHeight: '150%' },
          }}
          value={props.value ?? ''}
        />
      ),
    },
    {
      title: 'Kuvaus',
      field: 'description',
      render: (rowData) => <span style={{ whiteSpace: 'pre-wrap' }}>{rowData.description}</span>,
      validate: createValidator('description'),
      editComponent: (props: EditComponentProps<CarServiceWithTableData> & WithHelperText) => (
        <TextField
          className="description"
          name="description"
          id="description"
          style={{ width: '100%' }}
          multiline={true}
          error={props.error}
          helperText={props.helperText}
          placeholder="Kuvaus"
          onChange={(e) =>
            props.onRowDataChange({
              ...props.rowData,
              description: e.target.value,
            })
          }
          InputProps={{
            style: { fontSize: '0.875rem', lineHeight: '150%' },
          }}
          value={props.value ?? ''}
        />
      ),
    },
  ];

  return (
    <MaterialTable
      icons={materialTableIcons}
      localization={{
        ...materialTableLocalization,
        body: {
          emptyDataSourceMessage: 'Ei huoltoja',
          addTooltip: 'Lisää huolto',
          editTooltip: 'Muokkaa huoltoa',
          editRow: {
            cancelTooltip: 'Peruuta',
            saveTooltip: 'Tallenna',
          },
        },
      }}
      data={data}
      columns={columns}
      title={<MaterialTableHeader title={'Huollot'} />}
      options={{
        ...materialTableOptions,
        addRowPosition: 'first',
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
      }}
      components={materialTableComponents}
      editable={{
        onRowAdd: (newCarService) => {
          return new Promise<void>((resolve) => {
            const updatedData = [...data];
            const tempId = Math.random();
            updatedData.unshift({ ...newCarService, id: tempId, car_id: carId, isNew: true });
            dispatch({
              type: 'SET_CAR_SERVICES',
              payload: { carServices: updatedData },
            });
            resolve();
          });
        },
        onRowUpdate: (newCarService, oldCarService) => {
          return new Promise<void>((resolve) => {
            const updatedData = [...data];
            const carServiceToUpdateIndex = updatedData.find(
              (carService) => carService.id === oldCarService?.tableData?.id,
            );
            if (carServiceToUpdateIndex) {
              let dateInCorrectFormat = newCarService.service_date;
              if (!isDate(dateInCorrectFormat) && dateInCorrectFormat) {
                dateInCorrectFormat = DateTime.fromISO(dateInCorrectFormat).toJSDate();
              }
              const index = updatedData.indexOf(carServiceToUpdateIndex);
              updatedData[index] = {
                ...newCarService,
                service_date: dateInCorrectFormat,
                isUpdated: true,
              };
            }
            dispatch({
              type: 'SET_CAR_SERVICES',
              payload: { carServices: updatedData },
            });
            resolve();
          });
        },
      }}
    />
  );
};
