import { DateTime } from 'luxon';
import { PriceBasis, PriceBasisAdditionalServices } from './api';

export function formatFloat(float: number): string {
  return float.toFixed(2);
}

export function formatFloatNumber(float: number): number {
  return Number(float.toFixed(2));
}

export function formatPrice(float: number): string {
  return float.toFixed(2).replace('.', ',');
}

export const dateFormat = 'dd.MM.yyyy';

export const dateFormatUnderscore = 'dd_MM_yyyy';

export function formatDate(date: Date | DateTime): string {
  if (!DateTime.isDateTime(date)) {
    date = DateTime.fromJSDate(date);
  }
  return date.toFormat(dateFormat);
}

export const dateFormatShort = 'dd.MM';

export function formatShortDate(date: Date | DateTime): string {
  if (!DateTime.isDateTime(date)) {
    date = DateTime.fromJSDate(date);
  }
  return date.toFormat(dateFormatShort);
}

export const timeFormatWithSeconds = 'HH:mm:ss';

export const timeFormat = 'HH:mm';

export function formatTime(date: Date | DateTime): string {
  if (!DateTime.isDateTime(date)) {
    date = DateTime.fromJSDate(date);
  }
  return date.toFormat(timeFormat);
}

export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export function formatDateTime(date: Date | DateTime): string {
  if (!DateTime.isDateTime(date)) {
    date = DateTime.fromJSDate(date);
  }
  return date.toFormat(dateTimeFormat);
}

export function formatTimeString(time: string): string {
  if (!DateTime.fromFormat(time, 'hh:mm:ss').isValid) {
    return time;
  }
  return formatTime(DateTime.fromFormat(time, 'hh:mm:ss'));
}

export function formatShipmentRowState(shipmentRowState: string): string {
  switch (shipmentRowState) {
    case 'ready_for_pickup':
      return 'Noudettavissa';
    case 'picked_up':
      return 'Noudettu';
    case 'delivered':
      return 'Toimitettu';
    case 'lost':
      return 'Hukassa';
    case 'failed':
      return 'Epäonnistunut';
    default:
      return '';
  }
}

export function formatPricingUnit(pricingUnit?: string): string {
  switch (pricingUnit) {
    case 'volume_m3':
      return 'm3';
    case 'length_ldm':
      return 'Lvm';
    case 'weight_kg':
      return 'kg';
    case 'working_hours':
      return 'tuntia';
    default:
      return '';
  }
}

export function formatDistancePricingBasis(pricingUnit?: string): string {
  switch (pricingUnit) {
    case 'fixed_area':
      return 'alue';
    case 'distance_km':
      return 'km';
    default:
      return '';
  }
}

export function formatAreaType(areaType?: string): string {
  switch (areaType) {
    case 'postal_code':
      return 'Postinumero';
    case 'city':
      return 'Kaupunki';
    default:
      return '';
  }
}
export function formatProfession(profession: string): string {
  switch (profession) {
    case 'kuski':
      return 'Kuljettaja';
    case 'appari':
      return 'Apukuljettaja';
    case 'muu':
      return 'Muu';
    case 'alihankkija':
      return 'Alihankkija';
    case 'asentaja':
      return 'Asentaja';
    case 'toimihenkilo':
      return 'Toimihenkilö';
    default:
      return '';
  }
}

export function formatCarType(profession: string): string {
  switch (profession) {
    case 'kaappi':
      return 'Kaappi';
    case 'pikkuauto':
      return 'Pikkuauto';
    case '3-aks pilkki':
      return '3-akselinen pilkki';
    case '2-aks pilkki':
      return '2-akselinen pilkki';
    case 'pikkupilkki':
      return 'Pikkupilkki';
    case 'erikoispilkki':
      return 'Erikoispilkki';
    case 'lavetti':
      return 'Lavetti';
    case '4-aks pilkki':
      return '4-akselinen pilkki';
    default:
      return '';
  }
}

export function formatMonth(date: DateTime): string {
  return date.toLocaleString({ month: 'long' }, { locale: 'fi-fi' });
}

export function formatWeekDay(date: DateTime): string {
  return date.toLocaleString({ weekday: 'short' }, { locale: 'fi-fi' });
}

export function formatWeekDayLong(date: DateTime): string {
  return date.toLocaleString({ weekday: 'long' }, { locale: 'fi-fi' });
}

export function formatFutureWorkHourType(futureWorkHourType: string): string {
  switch (futureWorkHourType) {
    case 'sick':
      return 'Sairas';
    case 'pekkanen':
      return 'Pekkanen';
    case 'vacation':
      return 'Loma';
    case 'allowance':
      return 'Päiväraha';
    case 'partial_allowance':
      return 'Osapäiväraha';
    case 'own_time_off':
      return 'Omavapaa';
    case 'compensatory_time_off':
      return 'Tasuri';
    case 'bank':
      return 'Pankki';
    case 'partial_sick':
      return 'Osapäiväsairas';
    case 'break':
      return 'Tauko';
    case 'overlapping_break':
      return 'Päälleikkäinen tauko';
    case 'normal':
      return 'Normaali';
    case 'waiting':
      return 'Odotus';
    default:
      return '';
  }
}

export const formatPriceBasis = (newPriceBasis: PriceBasis | undefined, showError: boolean): string => {
  if (!newPriceBasis) {
    return '';
  }
  const newPriceBasisObject = newPriceBasis;
  const basicPrice = newPriceBasisObject.basic_price
    ? `- Perushinta: ${formatPrice(newPriceBasisObject.basic_price)} € \n`
    : '';
  const priceBasis =
    newPriceBasisObject.pricing_unit_value && newPriceBasisObject.distance_value
      ? `(${newPriceBasisObject.pricing_unit_value} ${formatPricingUnit(newPriceBasisObject.pricing_unit)} / ${
          newPriceBasisObject.distance_value
        } ${formatDistancePricingBasis(newPriceBasisObject.distance_pricing_basis)}) \n`
      : '';
  const expressDelivery = newPriceBasisObject.express_delivery
    ? `- Pikalisä: ${formatPrice(newPriceBasisObject.express_delivery)} € \n`
    : '';
  const priceRatioForCombinedShipment =
    newPriceBasisObject.price_ratio_for_combined_shipment && newPriceBasisObject.price_ratio_for_combined_shipment !== 1
      ? `- Osuus yhteistoimituksesta: ${formatFloat(newPriceBasisObject.price_ratio_for_combined_shipment * 100)} % \n`
      : '';
  const workingHours =
    newPriceBasisObject.working_hours || newPriceBasisObject.price_per_hour
      ? `- Työtunnit: ${newPriceBasisObject.working_hours} h * ${
          newPriceBasisObject.price_per_hour
            ? formatPrice(newPriceBasisObject.price_per_hour)
            : newPriceBasisObject.price_per_hour
        } € \n`
      : '';
  const combinationVehiclePricePerHour = newPriceBasisObject.combination_vehicle_price_per_hour
    ? `- Yhdistelmälisä: ${newPriceBasisObject.working_hours} h * ${formatPrice(
        newPriceBasisObject.combination_vehicle_price_per_hour,
      )} € \n`
    : '';
  const carType = newPriceBasisObject.car_type ? `- Autotyyppi: ${newPriceBasisObject.car_type} \n` : '';
  const additionalServices =
    newPriceBasisObject.additional_services?.length && newPriceBasisObject.additional_services?.length > 0
      ? `${newPriceBasisObject?.additional_services
          ?.map(
            (additionalService: PriceBasisAdditionalServices) =>
              `- ${additionalService.name} / ${
                additionalService.unit_price ? formatPrice(additionalService.unit_price) : additionalService.unit_price
              } € * ${additionalService.amount} kpl `,
          )
          .join('\n')}`
      : '';
  const error = newPriceBasisObject.error_message ? `- Hinnoitteluvirhe: ${newPriceBasisObject.error_message} \n` : '';
  if (showError && error) {
    return error;
  }
  const priceBasisString =
    basicPrice +
    priceBasis +
    expressDelivery +
    priceRatioForCombinedShipment +
    workingHours +
    combinationVehiclePricePerHour +
    carType +
    additionalServices;
  return priceBasisString;
};

export const Organizations = {
  Onninen: 'onninen',
  Fescon: 'Fescon Oy',
  Peri: 'Peri Suomi',
  Tibnor: 'tibnor',
  Timecap: 'timecap',
  Krautayp: 'krauta-yp',
};
