import { DateTime } from 'luxon';
import { timeFormat } from '../../formatters';
import {
  address,
  billingReferenceNumber,
  city,
  dateNotRequired,
  hourlyWorkReason,
  jobNumber,
  legacyEtaisyysField,
  lengthLdm,
  name,
  office,
  phoneNumber,
  postalCode,
  price,
  referenceNumber,
  shipmentAdditionalServiceQuantity,
  volumeM3,
  weightKgAllowNull,
  workHours,
} from '../../validation';
import { Field, FieldName, getAgreedDeliveryWindow, getPickupWindow, State } from './shipment.state';

type ValidationResult = {
  hasError: boolean;
  feedback?: string;
};

const isPositiveNumber = (field: any): boolean => {
  return typeof field.value === 'number' && field.value > 0;
};
const isAnySizeFieldDefined = (fields: State['fields']): boolean => {
  return (
    isPositiveNumber(fields.length_ldm) || isPositiveNumber(fields.volume_m3) || isPositiveNumber(fields.weight_kg)
  );
};

const getValidationResult = (joiSchema: any, field: Field<any>): ValidationResult => {
  const result = joiSchema.validate(field.value);
  const validationResult: ValidationResult = {
    hasError: result.error !== undefined,
    feedback: result.error?.message,
  };
  return validationResult;
};

const validateThatSizeIsDefined = (fields: State['fields']): ValidationResult => {
  const hasError = !isAnySizeFieldDefined(fields);
  const feedback = hasError ? 'Lavametrit, tilavuus tai paino täytyy olla määriteltynä' : undefined;

  const validationResult: ValidationResult = {
    hasError,
    feedback,
  };
  return validationResult;
};

const validatePickupAndDeliveryDates = (field: Field<any>, fields: State['fields']): ValidationResult => {
  const validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  if (field == null || field?.value === '' || field?.value === null) {
    return validationResult; // field.required validation will take care of missing values
  }
  const minMaxDateResult = getValidationResult(dateNotRequired, field);
  if (minMaxDateResult.hasError) {
    return minMaxDateResult;
  }
  const pickupWindow = getPickupWindow(fields);
  const agreedDeliveryWindow = getAgreedDeliveryWindow(fields);
  if (pickupWindow && pickupWindow?.startsAt > agreedDeliveryWindow?.endsAt) {
    validationResult.hasError = true;
    validationResult.feedback = 'Toimitusajan tulee olla noutoajan jälkeen';
  }
  return validationResult;
};

const validateDeliveryTimeWindow = (field: Field<any>, fields: State['fields']): ValidationResult => {
  const validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  const deliveryWindowTime = DateTime.fromJSDate(field.value as Date);
  const deliveryWindowStart = fields.delivery_time_window_start.value
    ? DateTime.fromJSDate(fields.delivery_time_window_start.value)
    : null;
  const deliveryWindowEnd = fields.delivery_time_window_end.value
    ? DateTime.fromJSDate(fields.delivery_time_window_end.value)
    : null;
  if (!deliveryWindowTime.isValid) {
    validationResult.hasError = true;
    validationResult.feedback = 'Virheellinen aika';
  } else if (
    deliveryWindowStart &&
    deliveryWindowEnd &&
    deliveryWindowStart.isValid &&
    deliveryWindowEnd.isValid &&
    deliveryWindowStart.toFormat(timeFormat) > deliveryWindowEnd.toFormat(timeFormat)
  ) {
    validationResult.hasError = true;
    validationResult.feedback = '"Aikaisintaan" ei voi olla myöhemmin kuin "Viimeistään"';
  }
  return validationResult;
};

const validateField = (fieldName: FieldName, fields: State['fields']): ValidationResult => {
  const field = fields[fieldName];
  let validationResult: ValidationResult = {
    hasError: false,
    feedback: undefined,
  };
  switch (fieldName) {
    case 'reference_number': {
      validationResult = getValidationResult(referenceNumber, field);
      break;
    }
    case 'billing_reference_number': {
      validationResult = getValidationResult(billingReferenceNumber, field);
      break;
    }
    case 'orderer_phone_number':
    case 'delivery_phone_number':
    case 'pickup_phone_number':
    case 'delivery_phone_number_secondary': {
      validationResult = getValidationResult(phoneNumber, field);
      break;
    }
    case 'delivery_city':
    case 'pickup_city': {
      validationResult = getValidationResult(city, field);
      break;
    }
    case 'delivery_address':
    case 'pickup_address': {
      validationResult = getValidationResult(address, field);
      break;
    }
    case 'orderer':
    case 'delivery_name':
    case 'pickup_name': {
      validationResult = getValidationResult(name, field);
      break;
    }
    case 'length_ldm': {
      validationResult = getValidationResult(lengthLdm, field);
      break;
    }
    case 'weight_kg': {
      validationResult = getValidationResult(weightKgAllowNull, field);
      break;
    }
    case 'volume_m3': {
      validationResult = getValidationResult(volumeM3, field);
      break;
    }
    case 'legacy_etaisyys_field': {
      validationResult = getValidationResult(legacyEtaisyysField, field);
      break;
    }
    case 'job_number': {
      validationResult = getValidationResult(jobNumber, field);
      break;
    }
    case 'billing_office_organization_specific_id':
    case 'pickup_office_organization_specific_id':
    case 'delivery_office_organization_specific_id': {
      validationResult = getValidationResult(office, field);
      break;
    }
    case 'pickup_postal_code':
    case 'delivery_postal_code': {
      validationResult = getValidationResult(postalCode, field);
      break;
    }
    case 'pickup_date':
    case 'pickup_time':
    case 'delivery_date':
    case 'ordered_at':
    case 'arrived_to_pickup_location_at':
    case 'picked_up_at':
    case 'arrived_to_delivery_location_at':
    case 'delivered_at':
    case 'billed_at': {
      validationResult = validatePickupAndDeliveryDates(field, fields);
      break;
    }
    case 'delivery_time_window_start':
    case 'delivery_time_window_end': {
      validationResult = validateDeliveryTimeWindow(field, fields);
      break;
    }
    case 'hourly_work_reason':
    case 'wait_reason': {
      validationResult = getValidationResult(hourlyWorkReason, field);
      break;
    }
    case 'hourly_work_hours':
    case 'wait_hours': {
      validationResult = getValidationResult(workHours, field);
      break;
    }
    case 'price': {
      validationResult = getValidationResult(price, field);
      break;
    }
    case 'sizes': {
      validationResult = validateThatSizeIsDefined(fields);
      break;
    }
  }
  if (field.required && (field.value === undefined || field.value === null || field.value === '')) {
    validationResult.hasError = true;
    validationResult.feedback = 'Kenttä on pakollinen';
  }
  return validationResult;
};

export const validateFields = (
  state: State,
): { fields: State['fields']; additionalServiceFields: State['additionalServiceFields'] } => {
  const newFields = { ...state.fields } as any;
  for (const fieldName of Object.keys(state.fields)) {
    newFields[fieldName] = {
      ...newFields[fieldName],
      ...validateField(fieldName as FieldName, state.fields),
    };
  }
  const newAdditionalServiceFields = [];
  for (const field of state.additionalServiceFields) {
    const validationResult = shipmentAdditionalServiceQuantity
      .allow('')
      .allow(0) // This is handled in request creation
      .validate(field.value);
    newAdditionalServiceFields.push({
      ...field,
      hasError: validationResult.error !== undefined,
      feedback: validationResult.error?.message,
    });
  }
  return {
    fields: newFields,
    additionalServiceFields: newAdditionalServiceFields,
  };
};
