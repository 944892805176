import React, { BaseSyntheticEvent, Dispatch, SetStateAction } from 'react';
import {
  PatchDriverLoadShipmentsByIdRequestBodyStateEnum,
  Shipment,
  PatchDriverLoadShipmentsByIdRequestBody,
  ShipmentUpdateAdditionalServicePutBody,
} from '../../../api';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  List,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import { AdditionalServiceField, Field } from '../../shipments/shipment.state';
import { setAdditionalServicesAndValidate, setValueAndValidate } from './driverShipmentValidation';
import { getJSDateOrNull } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { MobileDateTimePicker } from '../../../components/DateAndTimePickers/MobileDateTimePicker';

export type State = {
  fields: {
    [P in FieldNames]: Field<Shipment[P]>;
  };
} & { additionalServiceFields: AdditionalServiceField[] };

export type FieldNames = keyof Pick<
  PatchDriverLoadShipmentsByIdRequestBody,
  | 'hourly_work_hours'
  | 'hourly_work_reason'
  | 'note'
  | 'wait_reason'
  | 'wait_hours'
  | 'recipient'
  | 'state'
  | 'arrived_to_pickup_location_at'
  | 'arrived_to_delivery_location_at'
  | 'picked_up_at'
  | 'delivered_at'
  | 'weight_kg'
  | 'volume_m3'
  | 'length_ldm'
  | 'other_contract_number'
>;

const FormContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexFlow: 'row wrap',
  '& > *': {
    flex: 1,
  },
});

export const areAllFieldsValid = (state: State) => {
  return (
    !Object.values(state.fields).some((field) => field.hasError) &&
    !state.additionalServiceFields.some((field) => field.hasError)
  );
};

export const isStateEmpty = (state: PatchDriverLoadShipmentsByIdRequestBody) => {
  const { additional_services, ...rest } = state;
  return isArray(additional_services) && additional_services.length === 0 && isEmpty(rest);
};

export const getInitialState = (shipment: Shipment, additionalServices: AdditionalServiceField[]) => {
  const initialValues = {
    hasError: false,
    feedback: undefined,
    required: false,
  };
  return {
    fields: {
      hourly_work_hours: {
        ...initialValues,
        value: shipment.hourly_work_hours,
      },
      hourly_work_reason: {
        ...initialValues,
        value: shipment.hourly_work_reason,
      },
      note: {
        ...initialValues,
        value: shipment.note ?? '',
      },
      other_contract_number: {
        ...initialValues,
        value: shipment.other_contract_number ?? '',
      },
      wait_hours: {
        ...initialValues,
        value: shipment.wait_hours,
      },
      wait_reason: {
        ...initialValues,
        value: shipment.wait_reason,
      },
      recipient: {
        ...initialValues,
        value: shipment.recipient ?? '',
      },
      state: {
        ...initialValues,
        value: shipment.state,
      },
      arrived_to_pickup_location_at: {
        ...initialValues,
        value: shipment.arrived_to_pickup_location_at,
      },
      arrived_to_delivery_location_at: {
        ...initialValues,
        value: shipment.arrived_to_delivery_location_at,
      },
      picked_up_at: {
        ...initialValues,
        value: shipment.picked_up_at,
      },
      delivered_at: {
        ...initialValues,
        value: shipment.delivered_at,
      },
      weight_kg: {
        ...initialValues,
        value: shipment.weight_kg,
      },
      volume_m3: {
        ...initialValues,
        value: shipment.volume_m3,
      },
      length_ldm: {
        ...initialValues,
        value: shipment.length_ldm,
      },
    },
    additionalServiceFields: additionalServices,
  };
};

export const getPatchDriverLoadShipmentsByIdRequestBody = (
  state: State,
  shipment: Shipment,
  additionalServices: AdditionalServiceField[],
) => {
  return {
    ...(state.fields.hourly_work_hours.value !== shipment.hourly_work_hours && {
      hourly_work_hours: state.fields.hourly_work_hours.value ? state.fields.hourly_work_hours.value : 0,
    }),
    ...(state.fields.hourly_work_reason.value !== shipment.hourly_work_reason && {
      hourly_work_reason: state.fields.hourly_work_reason.value,
    }),
    ...(state.fields.note.value !== shipment.note && { note: state.fields.note.value }),
    ...(state.fields.other_contract_number.value !== shipment.other_contract_number && {
      other_contract_number: state.fields.other_contract_number.value,
    }),
    ...(state.fields.recipient.value &&
      state.fields.recipient.value !== shipment.recipient && { recipient: state.fields.recipient.value }),
    ...(state.fields.state.value !== shipment.state && {
      state: state.fields.state.value as unknown as PatchDriverLoadShipmentsByIdRequestBodyStateEnum,
    }),
    ...(state.fields.wait_hours.value !== shipment.wait_hours && {
      wait_hours: state.fields.wait_hours.value ? state.fields.wait_hours.value : 0,
    }),
    ...(state.fields.weight_kg.value !== shipment.weight_kg && {
      weight_kg: state.fields.weight_kg.value ? state.fields.weight_kg.value : 0,
    }),
    ...(state.fields.volume_m3.value !== shipment.volume_m3 && {
      volume_m3: state.fields.volume_m3.value ? state.fields.volume_m3.value : 0,
    }),
    ...(state.fields.length_ldm.value !== shipment.length_ldm && {
      length_ldm: state.fields.length_ldm.value ? state.fields.length_ldm.value : 0,
    }),
    ...(state.fields.wait_reason.value !== shipment.wait_reason && { wait_reason: state.fields.wait_reason.value }),
    ...(state.fields.arrived_to_pickup_location_at.value !== shipment.arrived_to_pickup_location_at && {
      arrived_to_pickup_location_at: state.fields.arrived_to_pickup_location_at.value,
    }),
    ...(state.fields.arrived_to_delivery_location_at.value !== shipment.arrived_to_delivery_location_at && {
      arrived_to_delivery_location_at: state.fields.arrived_to_delivery_location_at.value,
    }),
    ...(state.fields.picked_up_at.value !== shipment.picked_up_at && {
      picked_up_at: state.fields.picked_up_at.value,
    }),
    ...(state.fields.delivered_at.value !== shipment.delivered_at && {
      delivered_at: state.fields.delivered_at.value,
    }),
    ...(state.additionalServiceFields && {
      additional_services: state.additionalServiceFields
        .map((field) => {
          const originalAdditionalService = additionalServices.find(
            (as) => as.additionalService.id === field.additionalService.id,
          );
          if (field.value !== originalAdditionalService?.value)
            return {
              additional_service_id: field.additionalService.id,
              shipment_additional_service_id: field.shipmentAdditionalServiceId,
              amount: field.value || 0,
            };
        })
        .filter((as) => !!as) as ShipmentUpdateAdditionalServicePutBody[],
    }),
  };
};

export const RecipientAndState: React.FC<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
}> = ({ state, setState, disabled }) => {
  const stateMenuItems = [
    PatchDriverLoadShipmentsByIdRequestBodyStateEnum.Noudettavissa,
    PatchDriverLoadShipmentsByIdRequestBodyStateEnum.Noudettu,
    PatchDriverLoadShipmentsByIdRequestBodyStateEnum.Toimitettu,
  ].map((key) => (
    <MenuItem key={key} value={key}>
      {key}
    </MenuItem>
  ));

  return (
    <>
      <FormContainer>
        <TextField
          name="recipient"
          disabled={disabled}
          fullWidth
          label="Vastaanottaja"
          value={state.fields.recipient.value}
          error={state.fields.recipient.hasError}
          helperText={state.fields.recipient.feedback}
          onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
          InputProps={{
            disableUnderline: disabled,
          }}
        />
        <FormControl>
          <InputLabel shrink id="state_label">
            Tila
          </InputLabel>
          <Select
            disableUnderline={disabled}
            disabled={disabled}
            name="state"
            labelId="state_label"
            value={state.fields.state.value}
            onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
          >
            {stateMenuItems}
          </Select>
        </FormControl>
      </FormContainer>
    </>
  );
};

export const AdditionalServices: React.FC<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
}> = ({ state, setState, disabled }) => {
  if (
    (state.additionalServiceFields.length === 0 || disabled) &&
    !state.additionalServiceFields.some((as) => as.value)
  ) {
    return;
  }
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" gutterBottom>
          Lisäpalvelut
        </Typography>
        <List sx={{ width: '100%' }}>
          {state.additionalServiceFields
            .filter(
              (additionalServiceField) =>
                !(additionalServiceField.additionalService.archived && (additionalServiceField.value || 0) === 0),
            )
            .map((additionalServiceField) => {
              return (
                <li key={additionalServiceField.additionalService.id} className="additional-service-list-item">
                  <FormControl error={additionalServiceField.hasError} sx={{ width: '100%' }}>
                    <InputLabel
                      className="additional-service-label"
                      htmlFor={`additionalService-${additionalServiceField.additionalService.name}`}
                    >
                      {additionalServiceField.additionalService.name}
                    </InputLabel>
                    <Input
                      disableUnderline={disabled}
                      disabled={disabled}
                      id={`additionalService_${additionalServiceField.additionalService.id}`}
                      name={`additionalService_${additionalServiceField.additionalService.id}`}
                      className="additional-service"
                      // disabled={disabled}
                      type="number"
                      error={additionalServiceField.hasError}
                      value={additionalServiceField.value ?? ''}
                      inputProps={{ min: 0, max: 1000 }}
                      startAdornment={<InputAdornment position="start">kpl</InputAdornment>}
                      onChange={(e) =>
                        setAdditionalServicesAndValidate(
                          state,
                          setState,
                          e.target.value,
                          additionalServiceField.additionalService.id,
                        )
                      }
                    />
                    {additionalServiceField.feedback ? (
                      <FormHelperText className="additional-service-helper-text">
                        {additionalServiceField.feedback}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </li>
              );
            })}
        </List>
      </CardContent>
    </Card>
  );
};

export const Hours: React.FC<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
}> = ({ state, setState, disabled }) => {
  if (
    disabled &&
    !(
      state.fields.hourly_work_hours.value ||
      state.fields.hourly_work_reason.value ||
      state.fields.wait_hours.value ||
      state.fields.wait_reason.value
    )
  ) {
    return;
  }
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" gutterBottom>
          Tunnit
        </Typography>
        <FormContainer>
          <FormControl error={state.fields.hourly_work_hours.hasError}>
            <InputLabel>Tuntityö</InputLabel>
            <Input
              disableUnderline={disabled}
              disabled={disabled}
              name="hourly_work_hours"
              type="number"
              startAdornment={<InputAdornment position="start">h</InputAdornment>}
              value={state.fields.hourly_work_hours.value}
              //disable changing value with mouse scroll
              onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
              onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
            />
            {state.fields.hourly_work_hours.hasError ? (
              <FormHelperText>{state.fields.hourly_work_hours.feedback}</FormHelperText>
            ) : null}
          </FormControl>
          <TextField
            disabled={disabled}
            name="hourly_work_reason"
            fullWidth
            label="Tuntityön syy"
            value={state.fields.hourly_work_reason.value}
            error={state.fields.hourly_work_reason.hasError}
            helperText={state.fields.hourly_work_reason.feedback}
            onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
            InputProps={{
              disableUnderline: disabled,
            }}
          />
        </FormContainer>
        <FormContainer>
          <FormControl error={state.fields.wait_hours.hasError}>
            <InputLabel>Odotus</InputLabel>
            <Input
              disableUnderline={disabled}
              disabled={disabled}
              name="wait_hours"
              type="number"
              startAdornment={<InputAdornment position="start">h</InputAdornment>}
              value={state.fields.wait_hours.value}
              //disable changing value with mouse scroll
              onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
              onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
            />
            {state.fields.wait_hours.hasError ? (
              <FormHelperText>{state.fields.wait_hours.feedback}</FormHelperText>
            ) : null}
          </FormControl>
          <TextField
            disabled={disabled}
            name="wait_reason"
            fullWidth
            label="Odotuksen syy"
            value={state.fields.wait_reason.value}
            error={state.fields.wait_reason.hasError}
            helperText={state.fields.wait_reason.feedback}
            onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
            InputProps={{
              disableUnderline: disabled,
            }}
          />
        </FormContainer>
      </CardContent>
    </Card>
  );
};

export const Sizes: React.FC<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
}> = ({ state, setState, disabled }) => {
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" gutterBottom>
          Tilatiedot
        </Typography>
        <FormContainer>
          <FormControl error={state.fields.weight_kg.hasError}>
            <InputLabel>Paino</InputLabel>
            <Input
              disableUnderline={disabled}
              disabled={disabled}
              name="weight_kg"
              type="number"
              startAdornment={<InputAdornment position="start">kg</InputAdornment>}
              value={state.fields.weight_kg.value}
              //disable changing value with mouse scroll
              onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
              onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
            />
            {state.fields.weight_kg.hasError ? (
              <FormHelperText>{state.fields.weight_kg.feedback}</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl error={state.fields.volume_m3.hasError}>
            <InputLabel>Tilavuus</InputLabel>
            <Input
              disableUnderline={disabled}
              disabled={disabled}
              name="volume_m3"
              type="number"
              startAdornment={<InputAdornment position="start">m3</InputAdornment>}
              value={state.fields.volume_m3.value}
              //disable changing value with mouse scroll
              onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
              onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
            />
            {state.fields.volume_m3.hasError ? (
              <FormHelperText>{state.fields.volume_m3.feedback}</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl error={state.fields.length_ldm.hasError}>
            <InputLabel>Lavametrit</InputLabel>
            <Input
              disableUnderline={disabled}
              disabled={disabled}
              name="length_ldm"
              type="number"
              startAdornment={<InputAdornment position="start">lvm</InputAdornment>}
              value={state.fields.length_ldm.value}
              //disable changing value with mouse scroll
              onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
              onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
            />
            {state.fields.length_ldm.hasError ? (
              <FormHelperText>{state.fields.length_ldm.feedback}</FormHelperText>
            ) : null}
          </FormControl>
        </FormContainer>
      </CardContent>
    </Card>
  );
};

export const Notes: React.FC<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
}> = ({ state, setState, disabled }) => {
  if (disabled && !state.fields.note.value) {
    return;
  }
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" gutterBottom>
          Lisätietoja
        </Typography>
        <TextField
          name="note"
          disabled={disabled}
          fullWidth
          multiline={true}
          value={state.fields.note.value}
          onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
          InputProps={{
            disableUnderline: disabled,
          }}
        />
      </CardContent>
    </Card>
  );
};

export const OtherContractNumber: React.FC<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
}> = ({ state, setState, disabled }) => {
  if (disabled && !state.fields.other_contract_number.value) {
    return;
  }
  return (
    <Card>
      <CardContent>
        <Typography fontWeight="bold" gutterBottom>
          Toimitusmääräys
        </Typography>
        <TextField
          name="other_contract_number"
          disabled={disabled}
          fullWidth
          multiline={true}
          value={state.fields.other_contract_number.value}
          onChange={(e) => setValueAndValidate(state, setState, e.target.value, e.target.name)}
          InputProps={{
            disableUnderline: disabled,
          }}
        />
      </CardContent>
    </Card>
  );
};

export const States: React.FC<{
  state: State;
  setState: Dispatch<SetStateAction<State>>;
  disabled: boolean;
}> = ({ state, setState, disabled }) => {
  if (
    disabled &&
    !(
      state.fields.arrived_to_pickup_location_at.value ||
      state.fields.picked_up_at.value ||
      state.fields.arrived_to_delivery_location_at.value ||
      state.fields.delivered_at.value
    )
  ) {
    return;
  }
  return (
    <Card>
      <CardContent sx={{ display: 'grid' }}>
        <Typography fontWeight="bold" gutterBottom>
          Tapahtumat
        </Typography>
        {!disabled || state.fields.arrived_to_pickup_location_at.value ? (
          <MobileDateTimePicker
            disabled={disabled}
            value={state.fields.arrived_to_pickup_location_at.value}
            openTo="hours"
            slotProps={{
              textField: {
                label: 'Noutokohteessa',
                error: state.fields.arrived_to_pickup_location_at.hasError,
                helperText: state.fields.arrived_to_pickup_location_at.feedback,
                InputProps: {
                  disableUnderline: disabled,
                },
              },
            }}
            onChange={(datetime) =>
              setValueAndValidate(state, setState, getJSDateOrNull(datetime), 'arrived_to_pickup_location_at')
            }
          />
        ) : null}
        {!disabled || state.fields.picked_up_at.value ? (
          <MobileDateTimePicker
            disabled={disabled}
            value={state.fields.picked_up_at.value}
            openTo="hours"
            slotProps={{
              textField: {
                label: 'Noudettu',
                error: state.fields.picked_up_at.hasError,
                helperText: state.fields.picked_up_at.feedback,
                InputProps: {
                  disableUnderline: disabled,
                },
              },
            }}
            onChange={(datetime) => setValueAndValidate(state, setState, getJSDateOrNull(datetime), 'picked_up_at')}
          />
        ) : null}
        {!disabled || state.fields.arrived_to_delivery_location_at.value ? (
          <MobileDateTimePicker
            disabled={disabled}
            value={state.fields.arrived_to_delivery_location_at.value}
            openTo="hours"
            slotProps={{
              textField: {
                label: 'Toimituskohteessa',
                error: state.fields.arrived_to_delivery_location_at.hasError,
                helperText: state.fields.arrived_to_delivery_location_at.feedback,
                InputProps: {
                  disableUnderline: disabled,
                },
              },
            }}
            onChange={(datetime) =>
              setValueAndValidate(state, setState, getJSDateOrNull(datetime), 'arrived_to_delivery_location_at')
            }
          />
        ) : null}
        {!disabled || state.fields.delivered_at.value ? (
          <MobileDateTimePicker
            disabled={disabled}
            value={state.fields.delivered_at.value}
            openTo="hours"
            slotProps={{
              textField: {
                label: 'Toimitettu',
                error: state.fields.delivered_at.hasError,
                helperText: state.fields.delivered_at.feedback,
                InputProps: {
                  disableUnderline: disabled,
                },
              },
            }}
            onChange={(datetime) => setValueAndValidate(state, setState, getJSDateOrNull(datetime), 'delivered_at')}
          />
        ) : null}
      </CardContent>
    </Card>
  );
};
