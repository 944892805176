/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A meta resource describing properties of a driver User.
 * @export
 * @interface OrganizationDriver1
 */
export interface OrganizationDriver1 {
  /**
   *
   * @type {number}
   * @memberof OrganizationDriver1
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationDriver1
   */
  first_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationDriver1
   */
  last_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationDriver1
   */
  company_phone_number: string | null;
}

export function OrganizationDriver1FromJSON(json: any): OrganizationDriver1 {
  return OrganizationDriver1FromJSONTyped(json, false);
}

export function OrganizationDriver1FromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDriver1 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    company_phone_number: json['company_phone_number'],
  };
}

export function OrganizationDriver1ToJSON(value?: OrganizationDriver1 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first_name: value.first_name,
    last_name: value.last_name,
    company_phone_number: value.company_phone_number,
  };
}
