import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigationType } from 'react-router-dom';
import {
  api,
  getAllPages,
  Organization,
  SearchOrganizationShipmentsPostBody,
  SearchShipmentsPostBody,
  SearchShipmentsPostBodyStateEnum,
  Shipment,
} from '../../api';
import { ReactTable } from '../../components/ReactTable';
import Notification, { NotificationType, SnackbarPropsWithSeverity } from '../../components/Notification';
import { Button, Link } from '@mui/material';
import { DateColumnFilter, SelectColumnFilter } from '../../components/TableFilters';
import { CellValue, ColumnInstance, Row, TableRowProps } from 'react-table';
import Main from '../../components/Main';
import { formatDate } from '../../formatters';
import { AddressLink } from '../../components/map';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../../components/Loading';
import { DateTime } from 'luxon';
import { ShipmentRowSubRowAsync } from '../../components/ShipmentRowSubRow';
import {
  FormInput,
  FormKeyboardDatePicker,
  FormLast30DaysCheckbox,
  FormSelect,
} from './components/searchShipmentsInputs';
import { saveShipmentSet } from './components/ShipmentSetHistory';
import {
  StyledFormContainer,
  StyledFormContentContainer,
  StyledFormContentContainerMiddle,
  StyledFormFieldSet,
} from '../../components/StyledComponents/StyledFormComponents';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessCoordination } from '../../utils';

export interface Fields {
  delivery_name: string;
  delivery_address: string;
  delivery_phone_number: string;
  pickup_name: string;
  pickup_address: string;
  reference_number: string;
  id: string;
  job_number: string;
  delivery_date: DateTime | null;
  last30DaysAndOnwards: boolean;
  state: string;
}

interface EnrichedShipment extends Shipment {
  organizationName?: string;
}

interface SearchShipmentsMemory {
  delivery_name: string;
  delivery_address: string;
  delivery_phone_number: string;
  pickup_name: string;
  pickup_address: string;
  reference_number: string;
  id: string;
  job_number: string;
  delivery_date: string;
  last30DaysAndOnwards: boolean;
  state: string;
}

const SearchShipments: FunctionComponent = () => {
  const navigationType = useNavigationType();
  const memory: SearchShipmentsMemory =
    navigationType === 'POP' ? JSON.parse(sessionStorage.getItem('search_shipments_navigate') || '{}') : '';
  const formMethods = useForm<Fields>({
    defaultValues: {
      delivery_name: memory.delivery_name ?? '',
      delivery_address: memory.delivery_address ?? '',
      delivery_phone_number: memory.delivery_phone_number ?? '',
      pickup_name: memory.pickup_name ?? '',
      pickup_address: memory.pickup_address ?? '',
      reference_number: memory.reference_number ?? '',
      id: memory.id ?? '',
      job_number: memory.job_number ?? '',
      delivery_date: memory.delivery_date ? DateTime.fromISO(memory.delivery_date) : null,
      last30DaysAndOnwards: memory.last30DaysAndOnwards ?? true,
      state: memory.state ?? '',
    },
  });
  const { handleSubmit } = formMethods;
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState<NotificationType>({ message: null });
  const snackbarProps: SnackbarPropsWithSeverity = {
    onClose: (): void => setNotification({ message: null, severity: 'success' }),
    open: notification.message !== null,
    message: notification.message,
    key: notification.message,
    severity: notification.severity,
  };
  const [shipments, setShipments] = useState<EnrichedShipment[]>([]);
  const [shipmentSetId, setShipmentSetId] = useState<string>('');
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const currentUser = useCurrentUser();
  const hasCoordinationAccess = canAccessCoordination(currentUser);
  const [error, setError] = React.useState(false);

  const load = async (abortController?: AbortController) => {
    try {
      setIsLoading(true);
      if (hasCoordinationAccess) {
        setOrganizations((await api.organizations.getOrganizations({})).data);
      }
      if (
        navigationType === 'POP' &&
        (memory.delivery_name ||
          memory.delivery_address ||
          memory.delivery_phone_number ||
          memory.delivery_date ||
          memory.id ||
          memory.job_number ||
          memory.pickup_name ||
          memory.pickup_address ||
          memory.reference_number ||
          memory.state)
      ) {
        searchShipments(formMethods.getValues(), abortController);
      } else {
        sessionStorage.clear();
      }
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Lataus epäonnistui', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const abortController = new AbortController();
      load(abortController);
      return () => abortController.abort();
    }
  }, [currentUser]);

  const getSearchShipmentsPostBody = (fields: Fields): SearchShipmentsPostBody => {
    return {
      agreed_delivery_window_date_range_starts_at: fields.delivery_date
        ? fields.delivery_date.startOf('day').toJSDate()
        : fields.last30DaysAndOnwards
          ? DateTime.local().startOf('day').minus({ days: 30 }).toJSDate()
          : undefined,
      agreed_delivery_window_date_range_ends_at: fields.delivery_date
        ? fields.delivery_date.endOf('day').toJSDate()
        : undefined,
      delivery_name: fields.delivery_name || undefined,
      delivery_address: fields.delivery_address || undefined,
      delivery_phone_number: fields.delivery_phone_number || undefined,
      pickup_name: fields.pickup_name || undefined,
      pickup_address: fields.pickup_address || undefined,
      job_number: fields.job_number || undefined,
      reference_number: fields.reference_number || undefined,
      id: fields.id ? parseInt(fields.id) : undefined,
      state: (fields.state as SearchShipmentsPostBodyStateEnum) || undefined,
    };
  };

  const setSearchShipmentsnavigate = (fields: Fields) => {
    sessionStorage.setItem(
      'search_shipments_navigate',
      JSON.stringify({
        delivery_name: fields.delivery_name,
        delivery_address: fields.delivery_address,
        delivery_phone_number: fields.delivery_phone_number,
        pickup_name: fields.pickup_name,
        pickup_address: fields.pickup_address,
        reference_number: fields.reference_number,
        id: fields.id,
        job_number: fields.job_number,
        delivery_date: fields.delivery_date,
        last30DaysAndOnwards: fields.last30DaysAndOnwards,
        state: fields.state,
      }),
    );
  };

  const searchShipmentsHandler = async (fields: Fields) => searchShipments(fields, undefined);

  const searchShipments = async (fields: Fields, abortController: AbortController | undefined) => {
    try {
      setIsLoading(true);
      if (!currentUser) {
        return;
      }
      const shipments = hasCoordinationAccess
        ? await getAllPages(
            api.search.searchShipments.bind(api.search),
            {
              searchShipmentsPostBody: getSearchShipmentsPostBody(fields),
            },
            abortController,
          )
        : await getAllPages(
            api.search.searchOrganizationShipments.bind(api.search),
            {
              searchOrganizationShipmentsPostBody: getSearchShipmentsPostBody(
                fields,
              ) as SearchOrganizationShipmentsPostBody,
              organizationId: currentUser.organization_id,
            },
            abortController,
          );
      const shipmentsWithOrganizatonName = shipments.map((shipment) => {
        const organization = organizations.find((org) => org.id === shipment.organization_id);
        return {
          ...shipment,
          organizationName: organization?.name,
        };
      });
      setShipments(shipmentsWithOrganizatonName);
      setShipmentSetId(saveShipmentSet(shipmentsWithOrganizatonName));
      setSearchShipmentsnavigate(fields);
    } catch (err) {
      console.error(err);
      setNotification({ message: 'Virhe haettaessa toimituksia', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }: { row: Row }) => (
          <Link
            component={RouterLink}
            to={{ pathname: `/shipments/${row.values.id}`, search: `?shipmentSet=${shipmentSetId}` }}
          >
            {row.values.id}
          </Link>
        ),
      },
      {
        id: 'reference_number',
        Header: 'Asiakasviite',
        accessor: 'reference_number',
      },
      {
        id: 'organization',
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Lähettäjä',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'pickup_name',
          },
          {
            Header: 'Osoite',
            accessor: 'pickup_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="pickup-address-link"
                  title={shipment.cell.row.original.pickup_address}
                  address={shipment.cell.row.original.pickup_address}
                  postalCode={shipment.cell.row.original.pickup_postal_code}
                  city={shipment.cell.row.original.pickup_city}
                />
              );
            },
          },
          {
            Header: 'Kaupunki',
            accessor: 'pickup_city',
          },
        ],
      },
      {
        Header: 'Vastaanottaja',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'delivery_name',
          },
          {
            Header: 'Osoite',
            accessor: 'delivery_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="delivery-address-link"
                  title={shipment.cell.row.original.delivery_address}
                  address={shipment.cell.row.original.delivery_address}
                  postalCode={shipment.cell.row.original.delivery_postal_code}
                  city={shipment.cell.row.original.delivery_city}
                />
              );
            },
          },
          {
            Header: 'Kaupunki',
            accessor: 'delivery_city',
          },
        ],
      },
      {
        Header: 'Tila',
        accessor: 'state',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Sovittu toimituspäivä',
        accessor: 'agreed_delivery_window_starts_at',
        Cell: (shipment: CellValue) =>
          typeof shipment.value === 'string'
            ? formatDate(DateTime.fromISO(shipment.value))
            : shipment.value
              ? formatDate(shipment.value)
              : '',
        Filter: DateColumnFilter,
        sortType: 'datetime',
      },
      {
        Header: 'Puhelinnumero',
        accessor: 'delivery_phone_number',
      },
      {
        Header: 'Lisätietoja',
        accessor: 'note',
      },
    ],
    [hasCoordinationAccess, shipmentSetId],
  );
  const data = React.useMemo(() => shipments, [shipments]);
  const renderRowSubComponent = React.useCallback(
    (row: Row, rowProps: TableRowProps, visibleColumns: ColumnInstance[]) => (
      <ShipmentRowSubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
    ),
    [],
  );
  return (
    <Main>
      <Loading isLoading={isLoading} />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(searchShipmentsHandler)} autoComplete="off">
          <StyledFormFieldSet>
            <legend>Etsi toimituksia</legend>
            <StyledFormContainer>
              <StyledFormContentContainer>
                <FormInput type="number" disabled={isLoading} label="Toimitusnumero" name="id" />
                <FormInput disabled={isLoading} label="Asiakasviite" name="reference_number" />
                <FormInput disabled={isLoading} label="Työnumero" name="job_number" />
                <FormSelect disabled={isLoading} label="Tila" name="state" />
              </StyledFormContentContainer>
              <StyledFormContentContainerMiddle>
                <FormInput disabled={isLoading} label="Noutonimi" name="pickup_name" />
                <FormInput disabled={isLoading} label="Nouto-osoite" name="pickup_address" />
              </StyledFormContentContainerMiddle>
              <StyledFormContentContainer>
                <FormInput disabled={isLoading} label="Toimitusnimi" name="delivery_name" />
                <FormInput disabled={isLoading} label="Toimitusosoite" name="delivery_address" />
                <FormInput disabled={isLoading} label="Puhelinnumero" name="delivery_phone_number" />
                <FormKeyboardDatePicker
                  disabled={isLoading}
                  setError={setError}
                  label="Toimituspäivä"
                  name="delivery_date"
                />
              </StyledFormContentContainer>
            </StyledFormContainer>
            <StyledFormContentContainer>
              <FormLast30DaysCheckbox
                disabled={isLoading}
                label="Näytä hakutulokset vain 30 päivää vanhoista toimituksista eteenpäin"
                name="last30DaysAndOnwards"
                formMethods={formMethods}
              />
              <Button type="submit" className="search-button" disabled={isLoading || error}>
                Etsi
              </Button>
            </StyledFormContentContainer>
          </StyledFormFieldSet>
        </form>
      </FormProvider>
      <ReactTable
        columns={columns}
        data={data}
        header="Toimitukset"
        isLoading={isLoading}
        emptyText="Ei näytettäviä toimituksia."
        renderRowSubComponent={hasCoordinationAccess ? renderRowSubComponent : undefined}
        autoResetSettings={true}
        autoResetPage={true}
        initialState={{
          sortBy: [
            {
              id: 'id',
              desc: true,
            },
          ],
          hiddenColumns: [hasCoordinationAccess ? undefined : 'organization'],
        }}
      />
      <Notification {...snackbarProps} />
    </Main>
  );
};

export default SearchShipments;
