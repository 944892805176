/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  AdditionalService,
  AdditionalServiceFromJSON,
  AdditionalServiceFromJSONTyped,
  AdditionalServiceToJSON,
} from './AdditionalService';
import { Car, CarFromJSON, CarFromJSONTyped, CarToJSON } from './Car';
import { Load, LoadFromJSON, LoadFromJSONTyped, LoadToJSON } from './Load';
import { Office, OfficeFromJSON, OfficeFromJSONTyped, OfficeToJSON } from './Office';
import { Organization, OrganizationFromJSON, OrganizationFromJSONTyped, OrganizationToJSON } from './Organization';
import {
  OrganizationDriver1,
  OrganizationDriver1FromJSON,
  OrganizationDriver1FromJSONTyped,
  OrganizationDriver1ToJSON,
} from './OrganizationDriver1';
import { Photo, PhotoFromJSON, PhotoFromJSONTyped, PhotoToJSON } from './Photo';
import {
  ShipmentAdditionalService,
  ShipmentAdditionalServiceFromJSON,
  ShipmentAdditionalServiceFromJSONTyped,
  ShipmentAdditionalServiceToJSON,
} from './ShipmentAdditionalService';
import { ShipmentRow, ShipmentRowFromJSON, ShipmentRowFromJSONTyped, ShipmentRowToJSON } from './ShipmentRow';

/**
 *
 * @export
 * @interface ShipmentRelated
 */
export interface ShipmentRelated {
  /**
   *
   * @type {Array<ShipmentRow>}
   * @memberof ShipmentRelated
   */
  rows?: Array<ShipmentRow> | null;
  /**
   *
   * @type {Array<Photo>}
   * @memberof ShipmentRelated
   */
  photos?: Array<Photo> | null;
  /**
   *
   * @type {Load}
   * @memberof ShipmentRelated
   */
  load?: Load;
  /**
   *
   * @type {OrganizationDriver1}
   * @memberof ShipmentRelated
   */
  driver?: OrganizationDriver1 | null;
  /**
   *
   * @type {Car}
   * @memberof ShipmentRelated
   */
  car?: Car;
  /**
   *
   * @type {Organization}
   * @memberof ShipmentRelated
   */
  organization?: Organization;
  /**
   *
   * @type {Array<AdditionalService>}
   * @memberof ShipmentRelated
   */
  additional_services?: Array<AdditionalService> | null;
  /**
   *
   * @type {Array<Office>}
   * @memberof ShipmentRelated
   */
  offices?: Array<Office> | null;
  /**
   *
   * @type {Array<ShipmentAdditionalService>}
   * @memberof ShipmentRelated
   */
  shipment_additional_services?: Array<ShipmentAdditionalService> | null;
}

export function ShipmentRelatedFromJSON(json: any): ShipmentRelated {
  return ShipmentRelatedFromJSONTyped(json, false);
}

export function ShipmentRelatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentRelated {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rows: !exists(json, 'rows')
      ? undefined
      : json['rows'] === null
        ? null
        : (json['rows'] as Array<any>).map(ShipmentRowFromJSON),
    photos: !exists(json, 'photos')
      ? undefined
      : json['photos'] === null
        ? null
        : (json['photos'] as Array<any>).map(PhotoFromJSON),
    load: !exists(json, 'load') ? undefined : LoadFromJSON(json['load']),
    driver: !exists(json, 'driver') ? undefined : OrganizationDriver1FromJSON(json['driver']),
    car: !exists(json, 'car') ? undefined : CarFromJSON(json['car']),
    organization: !exists(json, 'organization') ? undefined : OrganizationFromJSON(json['organization']),
    additional_services: !exists(json, 'additional_services')
      ? undefined
      : json['additional_services'] === null
        ? null
        : (json['additional_services'] as Array<any>).map(AdditionalServiceFromJSON),
    offices: !exists(json, 'offices')
      ? undefined
      : json['offices'] === null
        ? null
        : (json['offices'] as Array<any>).map(OfficeFromJSON),
    shipment_additional_services: !exists(json, 'shipment_additional_services')
      ? undefined
      : json['shipment_additional_services'] === null
        ? null
        : (json['shipment_additional_services'] as Array<any>).map(ShipmentAdditionalServiceFromJSON),
  };
}

export function ShipmentRelatedToJSON(value?: ShipmentRelated | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    rows:
      value.rows === undefined
        ? undefined
        : value.rows === null
          ? null
          : (value.rows as Array<any>).map(ShipmentRowToJSON),
    photos:
      value.photos === undefined
        ? undefined
        : value.photos === null
          ? null
          : (value.photos as Array<any>).map(PhotoToJSON),
    load: LoadToJSON(value.load),
    driver: OrganizationDriver1ToJSON(value.driver),
    car: CarToJSON(value.car),
    organization: OrganizationToJSON(value.organization),
    additional_services:
      value.additional_services === undefined
        ? undefined
        : value.additional_services === null
          ? null
          : (value.additional_services as Array<any>).map(AdditionalServiceToJSON),
    offices:
      value.offices === undefined
        ? undefined
        : value.offices === null
          ? null
          : (value.offices as Array<any>).map(OfficeToJSON),
    shipment_additional_services:
      value.shipment_additional_services === undefined
        ? undefined
        : value.shipment_additional_services === null
          ? null
          : (value.shipment_additional_services as Array<any>).map(ShipmentAdditionalServiceToJSON),
  };
}
