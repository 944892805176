import { formatTime, formatDate } from '../formatters';
import { CellValue, Row, Accessor, TableRowProps, ColumnInstance, Column } from 'react-table';
import { DateTime } from 'luxon';
import { SelectColumnFilter, INPUT_HEIGHT, NoFilter, DateColumnFilter } from './TableFilters';
import { Shipment } from '../api';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { OutlinedInput, Link, IconButton, styled } from '@mui/material';
import { ReactTable } from './ReactTable';
import { ArrowDownward, ArrowUpward, DeleteOutline } from '@mui/icons-material';
import { ShipmentRowSubRowAsync } from './ShipmentRowSubRow';
import { AddressLink } from './map';
import theme from '../theme';
import { getStateAndTimeFromShipment, orderShipments } from '../utils';
import { DeliveryWindow } from './DeliveryWindow';

const DeleteIcon = styled(DeleteOutline)(({ theme }) => ({
  color: theme.palette.error.main,
}));

interface LoadShipmentsTableProps {
  shipments: Shipment[];
  isLoading: boolean;
  onShipmentUpdate: (shipmentId: Shipment['id'], shipmentUpdates: Partial<Shipment>, oldOrderNumber: number) => void;
  onShipmentRemove?: (shipmentId: Shipment['id']) => void;
  focusedShipmentId?: Shipment['id'];
}

export const LoadShipmentsTable: React.FC<LoadShipmentsTableProps> = ({
  shipments,
  isLoading,
  onShipmentUpdate,
  onShipmentRemove,
  focusedShipmentId,
}) => {
  const renderRowSubComponent = React.useCallback(
    (row: Row, rowProps: TableRowProps, visibleColumns: ColumnInstance[]) => (
      <>
        <ShipmentRowSubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
      </>
    ),
    [],
  );
  const columns = React.useMemo(
    (): Column<any>[] => [
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }: { row: Row }) => (
          <Link component={RouterLink} to={{ pathname: `/shipments/${row.values.id}` }}>
            {row.values.id}
          </Link>
        ),
      },
      {
        id: 'reference_number',
        Header: 'Asiakasviite',
        accessor: 'reference_number',
      },
      {
        id: 'order_in_load',
        Header: 'Järjestys',
        accessor: 'order_in_load',
        Cell: ({ value, row, updateRow, isLoading, column }) => {
          const currentValue = Number(row.values.order_in_load);
          const minInputValue = Math.min(1, ...column.filteredRows.map((shipment) => shipment.values.order_in_load));
          const maxInputValue = Math.max(
            column.filteredRows.length,
            ...column.filteredRows.map((shipment) => shipment.values.order_in_load),
          );
          const selectCurrentInput = (e: any) => {
            e.target.select();
            e.stopPropagation();
          };
          return (
            <>
              <OutlinedInput
                disabled={isLoading}
                type="number"
                value={value ?? ''}
                inputProps={{ min: minInputValue, max: maxInputValue }}
                style={{
                  height: INPUT_HEIGHT,
                  width: '4rem',
                }}
                onClick={selectCurrentInput}
                onFocus={selectCurrentInput}
                autoFocus={(row.id as unknown as number) === focusedShipmentId}
                onKeyDown={(e) => {
                  if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                    e.preventDefault();
                    // The parameter should be saved into a variable if all shipments are needed later for something else.
                    orderShipments(column.preFilteredRows.map((row) => row.original as Shipment));
                    let inputValue = parseInt((e.target as HTMLTextAreaElement).value);
                    if (!inputValue) inputValue = 0;
                    updateRow(
                      row.values.id,
                      {
                        order_in_load:
                          e.key === 'ArrowDown' && inputValue < maxInputValue
                            ? inputValue + 1
                            : e.key === 'ArrowUp' && inputValue > minInputValue
                              ? inputValue - 1
                              : inputValue,
                      },
                      inputValue,
                    );
                  }
                }}
                onChange={(event) => {
                  let inputValue = Number(event.target.value);
                  if (!isNaN(inputValue) && inputValue < minInputValue) inputValue = minInputValue;
                  if (!isNaN(inputValue) && inputValue > maxInputValue) inputValue = maxInputValue;
                  if (inputValue > currentValue) {
                    for (let i = currentValue; i <= inputValue; i++) {
                      updateRow(
                        row.values.id,
                        {
                          order_in_load: currentValue === 0 ? inputValue : i,
                        },
                        currentValue === 0 ? i : i - 1,
                      );
                    }
                  }
                  if (inputValue < currentValue) {
                    for (let i = currentValue; i >= inputValue; i--) {
                      updateRow(
                        row.values.id,
                        {
                          order_in_load: i,
                        },
                        i + 1,
                      );
                    }
                  }
                }}
              />
              <IconButton
                size="small"
                className="order-in-load-increase-button"
                disabled={currentValue <= minInputValue}
                onClick={(e) => {
                  updateRow(
                    row.values.id,
                    {
                      order_in_load: currentValue - 1,
                    },
                    currentValue,
                  );
                  e.stopPropagation();
                }}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                size="small"
                className="order-in-load-decrease-button"
                disabled={currentValue >= maxInputValue}
                onClick={(e) => {
                  updateRow(
                    row.values.id,
                    {
                      order_in_load: currentValue + 1,
                    },
                    currentValue,
                  );
                  e.stopPropagation();
                }}
              >
                <ArrowDownward />
              </IconButton>
            </>
          );
        },
      },
      {
        Header: 'Noutopaikka',
        accessor: ((shipment: Shipment) =>
          `${shipment.pickup_address ?? ''}, ${shipment.pickup_postal_code ?? ''} ${
            shipment.pickup_city ?? ''
          }`) as Accessor<any>,
        Cell: ({ row, value }: { row: Row<Shipment>; value: CellValue }) => {
          return (
            <AddressLink
              className="pickup-address-link"
              title={value}
              address={row.original.pickup_address}
              postalCode={row.original.pickup_postal_code}
              city={row.original.pickup_city}
            />
          );
        },
      },
      {
        Header: 'Toimituspaikka',
        accessor: ((shipment: Shipment) =>
          `${shipment.delivery_address ?? ''}, ${shipment.delivery_postal_code ?? ''} ${
            shipment.delivery_city ?? ''
          }`) as Accessor<any>,
        Cell: ({ row, value }: { row: Row<Shipment>; value: CellValue }) => {
          return (
            <AddressLink
              className="delivery-address-link"
              title={value}
              address={row.original.delivery_address}
              postalCode={row.original.delivery_postal_code}
              city={row.original.delivery_city}
            />
          );
        },
      },
      {
        Header: 'Tila',
        accessor: 'state',
        Filter: SelectColumnFilter,
        sortType: (row1: Row<Shipment>, row2: Row<Shipment>) => {
          return new Intl.Collator('fi', { numeric: true, caseFirst: 'false' }).compare(
            getStateAndTimeFromShipment(row1.original, true),
            getStateAndTimeFromShipment(row2.original, true),
          );
        },
        Cell: ({ row }: { row: Row<Shipment> }) => getStateAndTimeFromShipment(row.original),
      },
      {
        Header: 'Sovittu toimitusaika',
        accessor: 'agreed_delivery_window_starts_at',
        Cell: (shipment: CellValue) => {
          return (
            <DeliveryWindow
              startsAt={shipment.cell.row.original.agreed_delivery_window_starts_at}
              endsAt={shipment.cell.row.original.agreed_delivery_window_ends_at}
            />
          );
        },
        Filter: DateColumnFilter,
        sortType: 'datetime',
      },
      {
        Header: 'Arvioitu toimitusaika',
        id: 'delivery_window_starts_at',
        accessor: (date: CellValue) => {
          if (date.delivery_window_starts_at) {
            const startDateTime = DateTime.fromJSDate(date.delivery_window_starts_at);
            const endDateTime = DateTime.fromJSDate(date.delivery_window_ends_at);
            return (
              `${formatDate(startDateTime)} ${formatTime(startDateTime)}` +
              (endDateTime.isValid ? ` - ${formatTime(endDateTime)}` : '')
            );
          }
        },
      },
      {
        Header: 'Lisätietoja',
        id: 'note',
        accessor: 'note',
      },
      ...(onShipmentRemove
        ? [
            {
              Header: '',
              id: 'actions',
              accessor: '',
              Cell: ({ row }: { row: Row<Shipment> }) => {
                return (
                  <IconButton
                    className="remove-shipment-button"
                    aria-label="delete"
                    color="secondary"
                    onClick={() => onShipmentRemove(row.original.id)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                );
              },
              Filter: NoFilter,
            },
          ]
        : []),
    ],
    [focusedShipmentId],
  );
  return (
    <ReactTable
      columns={columns}
      data={shipments}
      header="Toimitukset"
      isLoading={isLoading}
      emptyText="Ei näytettäviä toimituksia."
      initialState={{
        sortBy: [
          {
            id: 'order_in_load',
            desc: false,
          },
        ],
      }}
      updateRow={onShipmentUpdate}
      renderRowSubComponent={renderRowSubComponent}
      rowSubComponentAlwaysVisible={false}
      getRowProps={(row) => {
        return {
          style: {
            ...(row.id === focusedShipmentId && {
              border: `2px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.table.light,
            }),
          },
          key: `shipment-${row.id}`,
        } as TableRowProps;
      }}
    />
  );
};
