import React, { useState } from 'react';
import { formatFloat, formatShipmentRowState } from '../../../formatters';
import {
  MaterialTableHeader,
  materialTableComponents,
  materialTableIcons,
  materialTableLocalization,
  materialTableOptions,
} from '../../../components/MaterialTable';
import { ShipmentRowWithTableData } from '../shipment.state';
import {
  parcelId,
  quantity,
  quantityForCoordinators,
  serialNumber,
  shipmentRowName,
  volumeM3,
  weightKg,
  weightKgForCoordinators,
} from '../../../validation';
import MaterialTable, { Column, EditComponentProps } from '@material-table/core';
import { FormControl, FormHelperText, IconButton, Input, TextField, Tooltip } from '@mui/material';
import { ShipmentRowProductsModal } from '../../../components/ShipmentRowProductsModal';
import { ShipmentRow } from '../../../api';
import { ListAlt } from '@mui/icons-material';
import { Delete } from '../../../components/icons';
import { User } from '../../../api';
import { cloneDeep, isEqual } from 'lodash';
import _ from 'lodash';

const validators = {
  serial_number: serialNumber,
  parcel_id: parcelId,
  name: shipmentRowName,
  quantity: quantity,
  weight_per_piece_kg: weightKg,
  volume_m3: volumeM3,
} as const;

const coordinatorValidators = {
  serial_number: serialNumber,
  parcel_id: parcelId,
  name: shipmentRowName,
  quantity: quantityForCoordinators,
  weight_per_piece_kg: weightKgForCoordinators,
  volume_m3: volumeM3,
} as const;

const createValidator = (
  fieldName: keyof typeof validators,
  originalRows: ShipmentRowWithTableData[],
  user?: User,
): ((row: ShipmentRowWithTableData) => string | boolean) => {
  return (row: ShipmentRowWithTableData): string | true => {
    if (row.tableData?.editing !== 'delete') {
      const originalRow = originalRows.find((r) => r.id === row.id);
      // Because of some shipments come from integrations and php, we do not want to validate old shipment fields that are already stored in database.
      if (
        !row.tableData ||
        (originalRow && row[fieldName] !== originalRow[fieldName as keyof ShipmentRowWithTableData])
      ) {
        if ((user?.organization_id === HOST_ORGANIZATION && user.is_coordinator === true) || user?.is_superuser) {
          const result = coordinatorValidators[fieldName].validate(row[fieldName]);
          if (result.error !== undefined) {
            return result.error.message;
          }
        } else {
          const result = validators[fieldName].validate(row[fieldName]);
          if (result.error !== undefined) {
            return result.error.message;
          }
        }
      }
    }
    return true;
  };
};

export interface WithIsNew {
  isNew: boolean;
}

function getNewShipmentRow(newRow: ShipmentRowWithTableData): ShipmentRowWithTableData & WithIsNew {
  return {
    ...newRow,
    isNew: true,
    total_weight_kg: (newRow.quantity ?? 0) * (newRow.weight_per_piece_kg ?? 0),
    longest_side_length_mm: newRow.longest_side_length_mm ?? null,
  };
}

function getUpdatedShipmentRow(newRow: ShipmentRowWithTableData): ShipmentRowWithTableData {
  return {
    ...newRow,
    total_weight_kg: (newRow.quantity ?? 0) * (newRow.weight_per_piece_kg ?? 0),
    longest_side_length_mm: newRow.longest_side_length_mm ?? null,
  };
}

function getEditedRows(
  changes: {
    [key: number]: { newData: ShipmentRowWithTableData; oldData: ShipmentRowWithTableData };
  },
  copyOfRows: ShipmentRowWithTableData[],
) {
  const keys = Object.keys(changes);
  for (let i = 0; i < keys.length; i++) {
    const rowId = Number(keys[i]);
    if (changes[rowId] && changes[rowId].newData) {
      const targetRow = copyOfRows.find((row: ShipmentRowWithTableData) => row.id === rowId);
      if (targetRow) {
        const newTargetRowIndex = copyOfRows.indexOf(targetRow);
        copyOfRows[newTargetRowIndex] = changes[rowId].newData;
      }
    }
  }
  return copyOfRows;
}

export interface ShipmentRowTableProps {
  user?: User;
  rows: ShipmentRowWithTableData[];
  onRowsUpdate?: (rows: ShipmentRowWithTableData[]) => void;
}

const editableTextField = (props: EditComponentProps<ShipmentRowWithTableData>): JSX.Element => {
  return (
    <TextField
      value={props.value || ''}
      onChange={(e) => {
        props.onChange(e.target.value || '');
      }}
      error={props.error}
      helperText={props.error ? (props as any).helperText : null}
      InputProps={{
        style: { fontSize: '0.875rem', lineHeight: '150%' },
      }}
    />
  );
};

const editableNumberInput = (props: EditComponentProps<ShipmentRowWithTableData>): JSX.Element => {
  return (
    <FormControl error={props.error}>
      <Input
        type="number"
        value={props.value || ''}
        onChange={(e) => {
          props.onChange(Number(e.target.value) || null);
        }}
        style={{ fontSize: '0.875rem', lineHeight: '150%' }}
      />
      {props.error ? <FormHelperText>{(props as any).helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export const ShipmentRowTable: React.FC<ShipmentRowTableProps> = ({ user, rows, onRowsUpdate }) => {
  const originalRows = cloneDeep(rows);
  const isEditable = onRowsUpdate !== undefined;
  const [modalShipmentRow, setModalShipmentRow] = useState<ShipmentRow | undefined>(undefined);
  const isHostOrganizationCoordinatorOrSuperuser =
    (user?.organization_id === HOST_ORGANIZATION && user?.is_coordinator) || user?.is_superuser;
  const onRowAdd = isEditable
    ? async function onRowAddHandler(newData: ShipmentRowWithTableData) {
        newData.id = Math.random() * 100; //Temp id for material
        onRowsUpdate([...rows, getNewShipmentRow(newData)]);
      }
    : undefined;
  const onBulkUpdate = isEditable
    ? async function bulkUpdateHandler(changes: {
        [key: number]: { newData: ShipmentRowWithTableData; oldData: ShipmentRowWithTableData };
      }) {
        const copyData = getEditedRows(changes, [...rows]);
        onRowsUpdate([...copyData.map(getUpdatedShipmentRow)]);
      }
    : undefined;
  const onRowUpdate = isEditable
    ? async function updateHandler(newData: ShipmentRowWithTableData, oldData?: ShipmentRowWithTableData) {
        const dataUpdate = [...rows];
        const target = dataUpdate.find((el) => el.id === oldData?.id);
        if (!target) {
          return;
        }
        const index = dataUpdate.indexOf(target);
        dataUpdate[index] = getUpdatedShipmentRow(newData);
        onRowsUpdate(dataUpdate);
      }
    : undefined;
  const onRowDelete = isEditable
    ? async function deleteHandler(oldData: ShipmentRowWithTableData) {
        const dataDelete = rows.filter((el) => el.id !== oldData.id);
        onRowsUpdate(dataDelete);
      }
    : undefined;
  function bulkDeleteShipmentRows(shipmentRowsToDelete: ShipmentRowWithTableData | ShipmentRowWithTableData[]) {
    if (Array.isArray(shipmentRowsToDelete)) {
      const newData = shipmentRowsToDelete.map((row) => {
        const { tableData, ...newObj } = row;
        return newObj;
      });
      rows = rows.filter((item) => !_.find(newData, item));
    } else {
      if (shipmentRowsToDelete.tableData) {
        const { tableData, ...newObj } = shipmentRowsToDelete;
        rows = rows.filter((item) => !isEqual(newObj, item));
      }
    }
    onRowsUpdate && onRowsUpdate([...rows]);
  }
  const allColumns: Column<ShipmentRowWithTableData>[] = [
    {
      field: 'serial_number',
      title: 'Sarjanumero *',
      type: 'string',
      editComponent: editableTextField,
      validate: createValidator('serial_number', originalRows, user),
    },
    {
      field: 'parcel_id',
      title: 'Tunnus *',
      type: 'string',
      editComponent: editableTextField,
      validate: createValidator('parcel_id', originalRows, user),
    },
    {
      field: 'name',
      title: 'Nimi *',
      type: 'string',
      editComponent: editableTextField,
      validate: createValidator('name', originalRows, user),
    },
    {
      field: 'quantity',
      title: 'Kpl *',
      type: 'numeric',
      editComponent: editableNumberInput,
      validate: createValidator('quantity', originalRows, user),
    },
    {
      field: 'weight_per_piece_kg',
      title: 'À paino (kg) *',
      type: 'numeric',
      editComponent: editableNumberInput,
      validate: createValidator('weight_per_piece_kg', originalRows, user),
    },
    {
      field: 'total_weight_kg',
      title: 'Paino yhteensä (kg)',
      type: 'numeric',
      editable: 'never',
      render: (row: any) => formatFloat(row.total_weight_kg ?? 0),
    },
    {
      field: 'volume_m3',
      title: 'Tilavuus (m³)',
      type: 'numeric',
      render: (row: any) => formatFloat(row.volume_m3 ?? 0),
      editComponent: editableNumberInput,
      validate: createValidator('volume_m3', originalRows),
    },
    {
      field: 'state',
      title: 'Tila',
      type: 'string',
      editable: isHostOrganizationCoordinatorOrSuperuser ? 'always' : 'never',
      lookup: {
        ready_for_pickup: 'Noudettavissa',
        picked_up: 'Noudettu',
        delivered: 'Toimitettu',
        lost: 'Hukassa',
        failed: 'Epäonnistunut',
      },
      render: (row) => formatShipmentRowState(row.state ?? ''),
      hidden: !Boolean(rows.find((row) => row.shipment_id)) || !isHostOrganizationCoordinatorOrSuperuser,
    },
    {
      title: '',
      field: 'show_packing_list',
      editable: 'never',
      cellStyle: { padding: 0, textAlign: 'right' },
      width: 'fit-content',
      render: (rowData) =>
        rowData.shipment_id ? (
          <Tooltip
            className="show-shipment-row-products-button-tooltip"
            placement="bottom"
            title={'Näytä lähetysluettelo'}
            aria-label={'Näytä lähetysluettelo'}
          >
            <span>
              <IconButton
                disabled={rows.some((row) => row.tableData?.editing === 'update')}
                className="show-shipment-row-products-button"
                onClick={() => setModalShipmentRow(rowData)}
                size="large"
              >
                <ListAlt />
              </IconButton>
            </span>
          </Tooltip>
        ) : null,
    },
  ];
  return (
    <>
      <MaterialTable
        icons={materialTableIcons}
        localization={
          {
            ...materialTableLocalization,
            body: {
              emptyDataSourceMessage: 'Ei tuotteita',
              addTooltip: 'Lisää tuote',
              deleteTooltip: 'Poista tuote',
              editTooltip: 'Muokkaa tuotetta',
              bulkEditTooltip: 'Muokkaa tuotteita',
              bulkEditApprove: 'Tallenna',
              bulkEditCancel: 'Peruuta',
              editRow: {
                deleteText: 'Haluatko varmasti poistaa tuotteen?',
                cancelTooltip: 'Peruuta',
                saveTooltip: 'Tallenna',
              },
            },
          } as any //TODO: Bulk edit localizations are missing from material-table typings. Remove as any type cast when they are added.
        }
        columns={allColumns}
        data={Array.from(rows)}
        title={<MaterialTableHeader title={'Tuotteet'} />}
        options={{
          ...materialTableOptions,
          actionsColumnIndex: -1,
          actionsCellStyle: {
            width: 'fit-content',
          },
          selection: isHostOrganizationCoordinatorOrSuperuser,
          pageSize: 20,
          emptyRowsWhenPaging: false,
        }}
        components={materialTableComponents}
        actions={[
          {
            tooltip: 'Poista kaikki valitut tuotteet',
            icon: () => <Delete className="delete-selected-rows" />,
            onClick: (_event, rows) => bulkDeleteShipmentRows(rows),
            hidden: !isHostOrganizationCoordinatorOrSuperuser,
          },
        ]}
        editable={{
          isEditable: () => isEditable,
          isEditHidden: () => !isEditable,
          isDeletable: () => isEditable,
          isDeleteHidden: () => !isEditable,
          onRowAdd: onRowAdd,
          onBulkUpdate: onBulkUpdate,
          onRowUpdate: onRowUpdate,
          onRowDelete: onRowDelete,
        }}
      />
      {modalShipmentRow && (
        <ShipmentRowProductsModal
          shipmentRow={modalShipmentRow}
          open={modalShipmentRow !== undefined}
          onClose={() => setModalShipmentRow(undefined)}
        />
      )}
    </>
  );
};
