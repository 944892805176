import React from 'react';
import { Chip, IconButton, ListItem, PopoverActions, Stack, TextField, Tooltip } from '@mui/material';
import { Cancel, Edit } from '@mui/icons-material';
import { MRT_Column, MRT_RowData } from 'material-react-table';
import { ColumnLayout, getAllPages, api } from '../../api';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { columnLayoutName } from '../../validation';
import { FieldSet } from '../StyledComponents/FieldSet';
import { FullWidthButton } from '../StyledComponents/FullWidthButton';

interface ColumnLayoutsProps<TData extends MRT_RowData> {
  columns: MRT_Column<TData>[];
  viewId?: string;
  isEditing: boolean;
  popoverActions: React.MutableRefObject<PopoverActions | null>;
}

type EditColumnLayout = {
  name: string;
  edit: boolean;
  columnLayout: ColumnLayout | undefined;
};

const emptyColumnLayout = {
  name: '',
  edit: false,
  columnLayout: undefined,
};

export const ColumnLayouts = <TData extends MRT_RowData>({
  columns,
  viewId,
  isEditing,
  popoverActions,
}: ColumnLayoutsProps<TData>) => {
  const currentUser = useCurrentUser();
  const [columnLayouts, setColumnLayouts] = React.useState<ColumnLayout[]>([]);
  const [edit, setEdit] = React.useState<EditColumnLayout>(emptyColumnLayout);
  const valid = columnLayoutName.validate(edit.name).error?.message;

  const load = async () => {
    if (!viewId || !currentUser) {
      return;
    }
    try {
      const columnLayouts = await getAllPages(api.columnLayouts.getColumnLayouts.bind(api.columnLayouts), {
        organizationId: currentUser.organization_id,
        viewId: viewId,
      });
      setColumnLayouts(columnLayouts);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    //conditional mui menu position helper
    if (popoverActions.current) {
      popoverActions.current.updatePosition();
    }
  }, [columnLayouts]);

  return (
    <div
      //disable menu keyboard navigation
      onKeyDown={(e) => e.stopPropagation()}
      style={{ margin: '0.5rem', width: '100%' }}
    >
      {isEditing && (
        <ListItem sx={{ padding: 0 }}>
          <FieldSet sx={{ width: '100%' }}>
            <legend>{edit.edit ? 'Muokkaa' : 'Lisää uusi'}</legend>
            <Stack>
              <TextField
                variant="outlined"
                label="Nimi"
                error={Boolean(valid)}
                helperText={valid}
                value={edit.name}
                onChange={(e) => setEdit({ ...edit, name: e.target.value })}
              />
              <FullWidthButton
                variant="contained"
                disabled={Boolean(valid)}
                onClick={async () => {
                  if (!viewId || !currentUser) {
                    return;
                  }
                  try {
                    const postPatchBody = {
                      name: edit.name,
                      columns: columns.filter((x) => x.getIsVisible()).map((x) => x.id),
                    };
                    const requestBody = {
                      organizationId: currentUser.organization_id,
                      viewId: viewId,
                    };
                    if (edit.edit && edit.columnLayout) {
                      await api.columnLayouts.putColumnLayout({
                        ...requestBody,
                        columnLayoutId: edit.columnLayout.id,
                        columnLayoutPutBody: postPatchBody,
                      });
                    } else {
                      await api.columnLayouts.createColumnLayout({
                        ...requestBody,
                        columnLayoutPostBody: postPatchBody,
                      });
                    }
                    await load();
                    setEdit(emptyColumnLayout);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {`${edit.edit ? `Tallenna ${edit.name}` : 'Lisää uusi'} valituilla sarakkeilla`}
              </FullWidthButton>
            </Stack>
          </FieldSet>
        </ListItem>
      )}
      <Stack direction={isEditing ? 'column' : 'row'}>
        {columnLayouts.map((columnLayout) => {
          return (
            <ListItem
              key={columnLayout.id}
              style={{
                margin: 0,
                marginRight: '0.25rem',
                padding: 0,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Chip
                sx={{ margin: '0.5rem' }}
                label={columnLayout.name}
                onClick={() => {
                  for (const column of columns) {
                    column.toggleVisibility(columnLayout.columns.includes(column.id));
                    // if (columnLayout.columns.includes(column.id)) {
                    //   column.toggleVisibility(true);
                    // } else {
                    //   column.toggleVisibility(false);
                    // }
                  }
                }}
              />
              {isEditing && (
                <div>
                  <Tooltip title={edit.columnLayout?.id === columnLayout.id ? 'Peruuta' : 'Muokkaa'}>
                    <IconButton
                      disabled={edit.columnLayout && edit.columnLayout?.id !== columnLayout.id}
                      onClick={() => {
                        if (edit.edit) {
                          setEdit(emptyColumnLayout);
                        } else {
                          setEdit({ name: columnLayout.name, edit: true, columnLayout: columnLayout });
                          for (const column of columns) {
                            column.toggleVisibility(columnLayout.columns.includes(column.id));
                          }
                        }
                      }}
                    >
                      {edit.columnLayout?.id === columnLayout.id && edit.edit ? <Cancel /> : <Edit />}
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </ListItem>
          );
        })}
      </Stack>
    </div>
  );
};
