import React, {
  BaseSyntheticEvent,
  MutableRefObject,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Cell,
  Column,
  ColumnInstance,
  FilterValue,
  HeaderGroup,
  Row,
  TableCommonProps,
  TableRowProps,
  TableState,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { utils as xlsxUtils, writeFile as writeXlsxFile } from 'xlsx';
import {
  Box,
  Button,
  Hidden,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { GetApp } from '@mui/icons-material';
import { DefaultColumnFilter, GlobalFilter } from './TableFilters';
import { Loading } from './Loading';
import { Pagination } from './Pagination';
import { ColumnAndFilterSelector } from './ColumnAndFilterSelector';
import { ExtraScrollbar, onExtraScrollbarScroll, onTableScroll } from './ExtraScrollbar';
import theme from '../theme';

const ToolbarActions = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
});

const StyledToolbar = styled('div')({
  padding: 0,
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'space-between',
  position: 'sticky',
  left: 0,
});

const StyledHeader = styled(Typography)({
  margin: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-start',
});

const GlobalFilterContainer = styled(Box)({
  alignItems: 'center',
  margin: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledTable = styled(Table)({
  border: '1px solid',
  borderColor: theme.palette.secondary.light,
});

const StatusTableCell = styled(TableCell)({
  textAlign: 'center',
  padding: theme.spacing(10),
});

const ExportButton = styled(Button)({
  height: '2.5rem',
});

const HeaderTableCell = styled(TableCell)({
  padding: '6px',
});

const HeaderRow = styled('div')({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
});

const FooterTableCell = styled(TableCell)({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
});

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    background: theme.palette.primary.light,
  },
});

// react-table requires this internally, so we don't have really a choice
// eslint-disable-next-line @typescript-eslint/ban-types
export function ReactTable<RowType extends object>({
  columns,
  data,
  header,
  isLoading,
  dateRangePicker,
  initialState,
  selectedRows,
  setSelectedRows,
  emptyText = 'Ei näytettäviä kohteita.',
  renderRowSubComponent,
  disableSorting = false,
  rowSubComponentAlwaysVisible = false,
  autoResetSettings = false,
  autoResetPage = true,
  getRowProps,
  onStateChange,
  columnSelector = false,
  exportName,
  tableRef,
  getCellProps,
  viewId,
  ...rest
}: {
  columns: Column<any>[];
  data: RowType[];
  header: string;
  isLoading: boolean;
  dateRangePicker?: ReactElement;
  initialState?: Record<string, any>; // react-table types are a bit of a mess: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-table/Readme.md
  selectedRows?: Record<string, boolean>;
  setSelectedRows?: (rows: Record<string, boolean>) => void;
  emptyText?: string;
  renderRowSubComponent?: (row: Row, rowProps: TableRowProps, visibleColumns: ColumnInstance[]) => JSX.Element;
  disableSorting?: boolean;
  updateRow?: (...args: any[]) => void;
  rowSubComponentAlwaysVisible?: boolean;
  autoResetSettings?: boolean;
  autoResetPage?: boolean;
  getRowProps?: (row: any) => TableRowProps;
  onStateChange?: (state: TableState) => void;
  columnSelector?: boolean;
  exportName?: string;
  getRowId?: (row: any) => string;
  tableRef?: Ref<unknown> | undefined;
  getCellProps?: (row: any) => TableCommonProps;
  viewId?: string;
}): ReactElement {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows: Array<any>, id: number, filterValue: FilterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const {
    getTableProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    visibleColumns,
    allColumns,
    state,
    rows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      state: {
        selectedRowIds: selectedRows,
      },
      initialState: {
        pageSize: 100,
        ...initialState,
      },
      sortTypes: {
        alphanumeric: (row1: Row, row2: Row, columnName: string) => {
          return new Intl.Collator('fi', { numeric: true, caseFirst: 'false' }).compare(
            row1.values[columnName],
            row2.values[columnName],
          );
        },
        datetime: (row1: Row, row2: Row, columnName: string) => {
          const minJSDateInMilliseconds = -8640000000000000;
          const row1Value = row1.values[columnName]
            ? new Date(row1.values[columnName]).getTime()
            : minJSDateInMilliseconds;
          const row2Value = row2.values[columnName]
            ? new Date(row2.values[columnName]).getTime()
            : minJSDateInMilliseconds;
          return row1Value - row2Value;
        },
      },
      getRowId: (row) => (row as any).id,
      isLoading,
      autoResetPage: autoResetPage,
      autoResetExpanded: autoResetSettings,
      autoResetGroupBy: autoResetSettings,
      autoResetSelectedRows: autoResetSettings,
      autoResetSortBy: autoResetSettings,
      autoResetFilters: autoResetSettings,
      autoResetRowState: autoResetSettings,
      autoResetHiddenColumns: autoResetSettings,
      ...rest,
    },
    useFilters,
    useGlobalFilter,
    ...(disableSorting ? [] : [useSortBy]),
    useExpanded,
    usePagination,
    useRowSelect,
  );
  if (onStateChange) {
    useEffect(() => {
      onStateChange(state);
    }, [state.filters, state.sortBy, state.hiddenColumns]);
  }

  if (setSelectedRows) {
    useEffect(() => {
      setSelectedRows(state.selectedRowIds);
    }, [setSelectedRows, state.selectedRowIds]);
  }

  const resetFilters = useCallback(() => {
    setAllFilters([]);
    setGlobalFilter([]);
  }, [setAllFilters, setGlobalFilter]);

  useImperativeHandle(tableRef, () => ({
    rows: rows,
    deselectAllRows: () => toggleAllRowsSelected(false),
    selectAllRows: () => toggleAllRowsSelected(true),
  }));

  const exportAsXlsx = () => {
    const headers = visibleColumns.map((column) => column.Header) as string[];
    const rowCellValues = rows.map((row) => {
      // Without this react-table throws an error because it thinks we are trying to render the row
      // I don't think this is really needed, but we can't really bypass react-table's check
      prepareRow(row);
      return row.cells.map((cell) => cell.value);
    });
    const footers = (footerGroups[0] ?? []).headers.map((footerHeader) => {
      let footerValue = '';
      if (typeof footerHeader.Footer === 'function') {
        footerValue = (footerHeader.Footer as (info: any) => string)({ rows });
      } else if (typeof footerHeader.Footer === 'string') {
        footerValue = footerHeader.Footer;
      }
      const parsedFooterValue = parseFloat(footerValue);
      return Number.isNaN(parsedFooterValue) ? footerValue : parsedFooterValue;
    });
    const worksheet = xlsxUtils.aoa_to_sheet([headers, ...rowCellValues, footers]);
    const workbook = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    /* Generate and download XLSX file */
    writeXlsxFile(workbook, `${exportName?.toLowerCase()}.xlsx`);
  };
  const footerHeaders = React.useMemo(
    () =>
      ((footerGroups[0] && footerGroups[0].headers) ?? []).reduce((memo: HeaderGroup[], header) => {
        if (header.headers) {
          for (const subHeaderGroup of header.headers) {
            memo.push(subHeaderGroup);
          }
        } else {
          memo.push(header);
        }

        return memo;
      }, []),
    [footerGroups],
  );
  const hasFooter = React.useMemo(
    () =>
      footerHeaders.some((header) => {
        return (
          typeof header.Footer === 'string' ||
          (typeof header.Footer === 'function' && header.Footer.name !== 'emptyRenderer')
        );
      }),
    [footerGroups],
  );

  const tableScrollRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const scrollbarRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const syncingTableScroll: MutableRefObject<boolean> = useRef(false);
  const syncingExtraScrollbar: MutableRefObject<boolean> = useRef(false);

  return (
    <>
      <Loading isLoading={isLoading}></Loading>
      <TableContainer
        onScroll={(e) => onTableScroll(e, scrollbarRef, syncingTableScroll, syncingExtraScrollbar)}
        ref={tableScrollRef}
      >
        <StyledToolbar>
          <StyledHeader variant="h2">{header}</StyledHeader>
          <ToolbarActions>
            {dateRangePicker ?? null}
            <Hidden smDown>
              <GlobalFilterContainer>
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </GlobalFilterContainer>
            </Hidden>
            {exportName && (
              <ExportButton
                sx={{ borderColor: '#C4C4C4', color: theme.palette.common.black }}
                variant="outlined"
                className="download-table-button"
                startIcon={<GetApp />}
                onClick={exportAsXlsx}
              >
                Lataa
              </ExportButton>
            )}
            {columnSelector ? (
              <ColumnAndFilterSelector
                viewId={viewId}
                columns={allColumns}
                hiddenColumns={state.hiddenColumns ?? []}
                resetFilters={resetFilters}
              />
            ) : null}
          </ToolbarActions>
        </StyledToolbar>
        <ExtraScrollbar
          onExtraScrollbarScroll={(e: BaseSyntheticEvent) =>
            onExtraScrollbarScroll(e, tableScrollRef, syncingTableScroll, syncingExtraScrollbar)
          }
          scrollbarRef={scrollbarRef}
        />
        <StyledTable className="react-table" {...getTableProps()} size="small">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderTableCell {...column.getHeaderProps()}>
                    <HeaderRow {...(column.getSortByToggleProps && column.getSortByToggleProps({ title: '' }))}>
                      {column.render('Header')}
                      {!disableSorting && !column.headers && column.id !== 'selection' ? (
                        <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'} />
                      ) : null}
                    </HeaderRow>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </HeaderTableCell>
                ))}
              </TableRow>
            ))}
            {!page.length ? (
              <TableRow>
                <StatusTableCell colSpan={100}>
                  <Typography>{emptyText}</Typography>
                </StatusTableCell>
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {page.map((row: any) => {
              prepareRow(row);
              const rowProps = row.getRowProps(getRowProps ? getRowProps(row) : undefined);
              return (
                <React.Fragment key={rowProps.key}>
                  <StyledTableRow
                    className={rowProps.className ?? ''}
                    {...rowProps}
                    onClick={() => row.toggleRowExpanded()}
                  >
                    {row.cells.map((cell: Cell) => {
                      return (
                        <TableCell {...cell.getCellProps(getCellProps && getCellProps(cell))}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                  {(row.isExpanded || rowSubComponentAlwaysVisible) &&
                    renderRowSubComponent !== undefined &&
                    renderRowSubComponent(row, rowProps, visibleColumns)}
                </React.Fragment>
              );
            })}
          </TableBody>
          {hasFooter && (
            <tfoot>
              <TableRow>
                {footerHeaders.map((footerHeader) => {
                  // HACK: Following prevents this from rendering extra table rows.
                  // react-table does not provide us a neat way to check if we have defined
                  // footers by ourselves. Placeholder footers use this emptyRenderer function
                  if (footerHeader.Footer) {
                    // Without this column.render('Footer') crashes!
                    return (
                      <FooterTableCell {...footerHeader.getFooterProps()}>
                        {footerHeader.render('Footer')}
                      </FooterTableCell>
                    );
                  }
                  return null;
                })}
              </TableRow>
            </tfoot>
          )}
        </StyledTable>
        <Pagination
          pageSize={state.pageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={state.pageIndex}
          pageCount={pageCount}
          pageOptions={pageOptions}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
        />
      </TableContainer>
    </>
  );
}
