import { Shipment, User } from '../../../api';
import { Collapse, FormHelperText, Paper, styled, Tooltip, Typography } from '@mui/material';
import {
  Timeline,
  TimelineOppositeContent,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@mui/lab';
import React, { Dispatch, useState } from 'react';
import { DateTime } from 'luxon';
import { dateFormat, dateTimeFormat } from '../../../formatters';
import {
  Action,
  Field,
  FieldName,
  State,
  updateFieldValue,
  areTimelineEventsInCorrectOrder,
  dateExistsAndIsntLegacyZeroTime,
} from '../shipment.state';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import theme from '../../../theme';
import { getJSDateOrNull, StandardDatePicker } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { StandardDateTimePicker } from '../../../components/DateAndTimePickers/StandardDateTimePicker';
import { FullWidthButton } from '../../../components/StyledComponents/FullWidthButton';
import { canAccessCoordination } from '../../../utils';

const StyledTimelineItem = styled(TimelineItem)({
  '&:before': {
    content: 'unset',
  },
  '&:last-child .MuiTimelineConnector-root': {
    display: 'none',
  },
});

const StyledTimelineContent = styled(TimelineContent)({
  paddingTop: '0.2rem',
});

const StyledTimelineOppositeContent = styled(TimelineOppositeContent)({
  paddingTop: '0.2rem',
  flex: 'unset',
});

const StyledPaper = styled(Paper)({
  boxShadow: 'none',
});

const StyledDateField = styled(Typography)({
  minWidth: '8rem',
});

interface ShipmentTimelineProps {
  state: State;
  disabled?: boolean;
  currentUser?: User;
  shipment: Shipment;
  dispatch: Dispatch<Action>;
}

interface DateAndTimeTimelineComponentProps {
  fieldName: FieldName;
  field: Field<any>;
  isCoordinator: boolean;
  dispatch: Dispatch<Action>;
  shipmentField: Date | null;
  title: string;
  dateAndTime: boolean;
  unloadedWithCustomerPermission?: boolean;
  recipient?: string | null;
}

const TimelineComponent: React.FC<DateAndTimeTimelineComponentProps> = ({
  fieldName,
  field,
  isCoordinator,
  dispatch,
  shipmentField,
  title,
  dateAndTime,
  recipient,
  unloadedWithCustomerPermission,
}) => {
  const className = fieldName + '_field';
  return (
    <StyledTimelineItem>
      <StyledTimelineOppositeContent>
        {isCoordinator ? (
          dateAndTime ? (
            <StandardDateTimePicker
              value={field.value}
              slotProps={{
                textField: {
                  id: fieldName,
                  name: fieldName,
                  error: field.hasError,
                  helperText: field.feedback,
                },
              }}
              onChange={(date) => {
                updateFieldValue(fieldName, getJSDateOrNull(date), dispatch);
              }}
            />
          ) : (
            <StandardDatePicker
              value={field.value}
              slotProps={{
                textField: {
                  id: fieldName,
                  name: fieldName,
                  error: field.hasError,
                  helperText: field.feedback,
                },
              }}
              onChange={(date) => {
                updateFieldValue(fieldName, getJSDateOrNull(date), dispatch);
              }}
            />
          )
        ) : (
          <StyledDateField className={className} color="textSecondary">
            {shipmentField
              ? DateTime.fromJSDate(shipmentField).toFormat(dateAndTime ? dateTimeFormat : dateFormat)
              : null}
          </StyledDateField>
        )}
      </StyledTimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <StyledTimelineContent>
        {recipient ? (
          <StyledPaper>
            <div>{title}</div>
            <div className="recipient_field">
              {unloadedWithCustomerPermission ? <b>PAL</b> : null}
              <p>Vastaanottaja: {recipient || '-'}</p>
            </div>
          </StyledPaper>
        ) : (
          title
        )}
      </StyledTimelineContent>
    </StyledTimelineItem>
  );
};

export const ShipmentTimeline: React.FC<ShipmentTimelineProps> = ({ shipment, state, dispatch, currentUser }) => {
  const isCoordinator = canAccessCoordination(currentUser);
  const [showAllTimeLineEvents, setShowAllTimeLineEvents] = useState(false);

  return (
    <>
      <Timeline style={{ justifyContent: 'flex-start' }} className="shipment-timeline">
        <Collapse in={dateExistsAndIsntLegacyZeroTime(shipment.ordered_at) || showAllTimeLineEvents}>
          <TimelineComponent
            fieldName="ordered_at"
            field={state.fields.ordered_at}
            isCoordinator={isCoordinator}
            dispatch={dispatch}
            shipmentField={shipment.ordered_at}
            title="Tilattu"
            dateAndTime={true}
          />
        </Collapse>
        <Collapse in={dateExistsAndIsntLegacyZeroTime(shipment.arrived_to_pickup_location_at) || showAllTimeLineEvents}>
          <TimelineComponent
            fieldName="arrived_to_pickup_location_at"
            field={state.fields.arrived_to_pickup_location_at}
            isCoordinator={isCoordinator}
            dispatch={dispatch}
            shipmentField={shipment.arrived_to_pickup_location_at}
            title="Noutokohteessa"
            dateAndTime={true}
          />
        </Collapse>
        <Collapse in={dateExistsAndIsntLegacyZeroTime(shipment.picked_up_at) || showAllTimeLineEvents}>
          <TimelineComponent
            fieldName="picked_up_at"
            field={state.fields.picked_up_at}
            isCoordinator={isCoordinator}
            dispatch={dispatch}
            shipmentField={shipment.picked_up_at}
            title="Noudettu"
            dateAndTime={true}
          />
        </Collapse>
        <Collapse
          in={dateExistsAndIsntLegacyZeroTime(shipment.arrived_to_delivery_location_at) || showAllTimeLineEvents}
        >
          <TimelineComponent
            fieldName="arrived_to_delivery_location_at"
            field={state.fields.arrived_to_delivery_location_at}
            isCoordinator={isCoordinator}
            dispatch={dispatch}
            shipmentField={shipment.arrived_to_delivery_location_at}
            title="Toimituskohteessa"
            dateAndTime={true}
          />
        </Collapse>
        <Collapse in={dateExistsAndIsntLegacyZeroTime(shipment.delivered_at) || showAllTimeLineEvents}>
          <TimelineComponent
            fieldName="delivered_at"
            field={state.fields.delivered_at}
            isCoordinator={isCoordinator}
            dispatch={dispatch}
            shipmentField={shipment.delivered_at}
            title="Toimitettu"
            unloadedWithCustomerPermission={shipment.unloaded_with_customer_permission}
            recipient={shipment.recipient}
            dateAndTime={true}
          />
        </Collapse>
        <Collapse in={dateExistsAndIsntLegacyZeroTime(shipment.billed_at) || showAllTimeLineEvents}>
          <TimelineComponent
            fieldName="billed_at"
            field={state.fields.billed_at}
            isCoordinator={isCoordinator}
            dispatch={dispatch}
            shipmentField={shipment.billed_at}
            title="Laskutettu"
            dateAndTime={false}
          />
        </Collapse>
      </Timeline>
      {isCoordinator && !areTimelineEventsInCorrectOrder(state.fields) ? (
        <FormHelperText id="timeline-event-errors" style={{ color: theme.palette.error.main }}>
          Toimitustapahtumien päivämäärien pitää olla oikeassa järjestyksessä.
        </FormHelperText>
      ) : null}
      {isCoordinator ? (
        <Tooltip
          title={showAllTimeLineEvents ? 'Piilota tyhjät tapahtumat' : 'Näytä myös tyhjät tapahtumat'}
          aria-label={'show-all-timeline-events'}
        >
          <FullWidthButton
            variant="text"
            id="show-all-timeline-events"
            onClick={() => setShowAllTimeLineEvents(!showAllTimeLineEvents)}
          >
            {showAllTimeLineEvents ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </FullWidthButton>
        </Tooltip>
      ) : null}
    </>
  );
};
