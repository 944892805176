import React, { useState, HTMLInputTypeAttribute, BaseSyntheticEvent } from 'react';
import { Button, styled, TextField, Tooltip } from '@mui/material';
import { CellValue, ColumnInstance, Row } from 'react-table';
import { validateField, ValidationResult } from './validation';
import theme from '../../theme';
import { SaveButton } from '../SaveButton';
import { debounce } from 'lodash';

type FieldValue = string | number | boolean | null;

type EditableItemValueAndError = {
  value: FieldValue;
  error: boolean;
};

export type EditableItem = {
  [key: string]: EditableItemValueAndError;
};

const ActionContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row',
});

interface EditMenuProps {
  isLoading: boolean;
  dataLength: number;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  saveData: () => Promise<void>;
  canSave: boolean;
  setCanSave: (canSave: boolean) => void;
  editedData: EditableItem[];
  setEditedData: (editedData: EditableItem[]) => void;
}

export const EditMenu: React.FC<EditMenuProps> = ({
  isLoading,
  dataLength,
  isEditing,
  setIsEditing,
  saveData,
  canSave,
  setCanSave,
  editedData,
  setEditedData,
}) => {
  return (
    <ActionContainer>
      <Tooltip
        title={isLoading || dataLength === 0 ? 'Taulukko ei sisällä muokattavia rivejä' : ''}
        aria-label={'edit-items-button'}
      >
        <span>
          <Button
            disabled={isLoading || dataLength === 0}
            style={{
              backgroundColor: isEditing && !isLoading && dataLength !== 0 ? theme.palette.error.main : undefined,
            }}
            className="edit-data-button"
            variant="contained"
            color="primary"
            onClick={() => {
              setIsEditing(!isEditing);
              if (!isEditing) {
                setEditedData([]);
                setCanSave(false);
              }
            }}
          >
            {isEditing ? 'Peruuta' : 'Muokkaa'}
          </Button>
        </span>
      </Tooltip>
      <SaveButton
        style={{ display: isEditing ? undefined : 'none' }}
        className="save-data-button"
        disabled={!canSave || isLoading}
        tooltip={
          editedData.length === 0
            ? 'Ei tehtyjä muutoksia taulukon riveihin'
            : 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä'
        }
        onClick={() => {
          saveData();
        }}
      >
        Tallenna
      </SaveButton>
    </ActionContainer>
  );
};

const checkErrorsAndSetCanSave = (editedData: EditableItem[], setCanSave: (canSave: boolean) => void) => {
  const foundError = editedData.some((field) => Object.values(field).some((item) => item.error));
  if (!foundError && editedData.length !== 0) {
    setCanSave(true);
  } else {
    setCanSave(false);
  }
};

const updateCellDataAndErrors = (
  id: number,
  key: string,
  value: FieldValue,
  error: ValidationResult,
  editedData: EditableItem[],
  setEditedData: (editedData: EditableItem[]) => void,
  setCanSave: (canSave: boolean) => void,
) => {
  const editedItemIndex = editedData.findIndex((item) => item.id.value === id);
  if (editedItemIndex === -1) {
    if (key === 'price') {
      editedData.push({
        id: { value: id, error: false },
        [key]: { value: value, error: error.hasError },
        has_contract_price: { value: true, error: false },
      });
    } else {
      editedData.push({
        id: { value: id, error: false },
        [key]: { value: value, error: error.hasError },
      });
    }
    debouncedSetEditedDataAndSetCanSave(editedData, setEditedData, setCanSave);
  } else {
    const newEditedData = [...editedData];
    if (key === 'price') {
      newEditedData[editedItemIndex].has_contract_price = { value: true, error: false };
    }
    newEditedData[editedItemIndex][key] = { value: value, error: error.hasError };
    debouncedSetEditedDataAndSetCanSave(newEditedData, setEditedData, setCanSave);
  }
};

const debouncedSetEditedDataAndSetCanSave = debounce(
  (
    editedData: EditableItem[],
    setEditedData: (editedData: EditableItem[]) => void,
    setCanSave: (canSave: boolean) => void,
  ) => {
    setEditedData(editedData);
    checkErrorsAndSetCanSave(editedData, setCanSave);
  },
  500,
);

export const EditableCell = ({
  value: initialValue,
  row: { original },
  column: { id },
  type,
  required,
  editedData,
  setEditedData,
  setCanSave,
}: {
  value: CellValue;
  row: Row<any>;
  column: ColumnInstance;
  type: HTMLInputTypeAttribute | undefined;
  required: boolean;
  editedData: EditableItem[];
  setEditedData: (editedData: EditableItem[]) => void;
  setCanSave: (canSave: boolean) => void;
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<ValidationResult>({ hasError: false, feedback: undefined });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentError = validateField(id, e.target.value, required);
    let currentValue;
    if (type === 'number') {
      currentValue = e.target.value ? Number(e.target.value) : null;
    } else {
      currentValue = e.target.value;
    }
    setValue(currentValue);
    setError(currentError);
    updateCellDataAndErrors(original.id, id, currentValue, currentError, editedData, setEditedData, setCanSave);
  };

  return (
    <TextField
      inputProps={{
        style: {
          backgroundColor: initialValue !== value ? theme.palette.secondary.light : undefined,
          color: initialValue !== value ? theme.palette.primary.main : undefined,
          minWidth: '4rem',
        },
      }}
      name={id}
      type={type}
      error={error.hasError}
      helperText={error.feedback ?? ''}
      //disable changing value with mouse scroll
      onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
      onChange={onChange}
      value={value ?? ''}
      FormHelperTextProps={{
        className: `${id}-helper-text`,
      }}
    />
  );
};
