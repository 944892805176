import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import {
  Action,
  convertToReturnShipment,
  copyShipment,
  getAgreedDeliveryWindow,
  getInitialState,
  getPickupWindow,
  reducer,
  ShipmentRowWithTableData,
  State,
  updateRows,
} from './shipment.state';
import {
  AdditionalService,
  api,
  Car,
  Driver,
  GetShipmentIncludeEnum,
  Load,
  Office,
  Organization,
  OrganizationCar,
  OrganizationDriver,
  OrganizationPhotosResponseBody,
  OrganizationPricingModelsResponseBody,
  OrganizationShipment,
  OrganizationShipmentPutBody,
  OrganizationShipmentPutBodyStateEnum,
  OrganizationShipmentResponseBody,
  OrganizationShipmentRowsResponseBody,
  OrganizationsResponseBody,
  Photo,
  PhotosResponseBody,
  PricingModelsResponseBody,
  Shipment,
  ShipmentAdditionalService,
  ShipmentPutBody,
  ShipmentPutBodyStateEnum,
  ShipmentResponseBody1,
  ShipmentRow,
  UpdateDistanceAndItsRelatedShipmentsPatchBody,
  User,
} from '../../api';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Alert, Box, Button, Chip, FormHelperText, Link, MenuItem, styled, Typography } from '@mui/material';
import { PhotoCameraOutlined } from '@mui/icons-material';
import { ShipmentRowTable, WithIsNew } from './components/ShipmentRowTable';
import { FieldSet } from '../../components/StyledComponents/FieldSet';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { ShipmentTimeline } from './components/ShipmentTimeline';
import { formatDateTime } from '../../formatters';
import {
  AdditionalRequirementFields,
  AdditionalServices,
  BillingOffice,
  BillingReferenceNumber,
  ChargeableWeightInput,
  CoordinationFieldSet,
  CustomerDistributionArea,
  CustomPricingCategory,
  DeliveryAddress,
  DeliveryCity,
  DeliveryDatePicker,
  DeliveryName,
  DeliveryOffice,
  DeliveryPhoneNumber,
  DeliveryPhoneNumberSecondary,
  DeliveryPostalCode,
  DeliveryTimePicker,
  JobNumber,
  LengthLdmInput,
  Notes,
  Orderer,
  OrdererPhoneNumber,
  OrganizationField,
  OtherContractNumber,
  PickupAddress,
  PickupCity,
  PickupName,
  PickupOffice,
  PickupPhoneNumber,
  PickupPostalCode,
  PickupTimePicker,
  PricingFieldSet,
  ReferenceNumber,
  RelatedShipmentsFieldSet,
  StateSelect,
  VolumeM3Input,
  WeightKgInput,
} from './components/inputs';
import { UpdateInfo } from './components/UpdateInfo';
import { AddressLink } from '../../components/map';
import { downloadWaybill } from '../../components/Waybill';
import { DateTime } from 'luxon';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { ShipmentAddFileDialog } from './components/ShipmentAddFileDialog';
import { UpdateHistory } from './components/UpdateHistory';
import { previousAndNextShipmentLinks } from './components/ShipmentSetHistory';
import {
  canAccessCoordination,
  canAccessCustomerCoordination,
  canAccessCustomerPricing,
  canAccessPricing,
  ifDateExistGetSameDateAtMidday,
  isHostOrganizationUser,
} from '../../utils';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { FieldSetContainer } from '../../components/StyledComponents/FieldSetContainer';
import { ButtonContainer } from '../../components/StyledComponents/ButtonContainer';
import { FullWidthButton } from '../../components/StyledComponents/FullWidthButton';
import { isPreciseDelivery } from '../../components/DeliveryWindow';
import { isNumber } from 'lodash';
import { useCurrentUser, useCurrentUserOrganization } from '../../hooks/useCurrentUser';
import theme from '../../theme';

export const load = async (
  userOrganizationId: OrganizationShipment['organization_id'],
  shipmentId: OrganizationShipment['id'],
  dispatch: Dispatch<Action>,
  currentUser: User,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let shipmentResponse: OrganizationShipmentResponseBody | ShipmentResponseBody1;
    let shipmentRowsResponse: OrganizationShipmentRowsResponseBody | undefined = undefined;
    let shipmentPhotosResponse: PhotosResponseBody | OrganizationPhotosResponseBody | undefined;
    let organizationsResponse: OrganizationsResponseBody | undefined;
    let pricingModelsResponse: PricingModelsResponseBody | OrganizationPricingModelsResponseBody | undefined;
    let load: Load | undefined = undefined;
    let driver: Driver | OrganizationDriver | undefined = undefined;
    let car: Car | OrganizationCar | undefined = undefined;
    let shipmentOrganization: Organization | undefined = undefined;
    let shipmentRows: ShipmentRow[] = [];
    let shipmentPhotos: Photo[] | undefined = undefined;
    let organizationOffices: Office[] = [];
    let organizationAdditionalServices: AdditionalService[] = [];
    let shipmentAdditionalServices: ShipmentAdditionalService[] = [];
    if (canAccessCoordination(currentUser)) {
      [shipmentResponse, organizationsResponse, pricingModelsResponse] = await Promise.all([
        api.shipments.getShipment({
          shipmentId,
          include: [
            GetShipmentIncludeEnum.Load,
            GetShipmentIncludeEnum.LoadDriver,
            GetShipmentIncludeEnum.LoadCar,
            GetShipmentIncludeEnum.Organization,
            GetShipmentIncludeEnum.Rows,
            GetShipmentIncludeEnum.Photos,
            GetShipmentIncludeEnum.ShipmentAdditionalServices,
            GetShipmentIncludeEnum.OrganizationAdditionalServices,
            GetShipmentIncludeEnum.OrganizationOffices,
          ],
        }),
        api.organizations.getOrganizations({}),
        api.pricing.getPricingModels(),
      ]);
      shipmentRows = shipmentResponse.related?.rows ?? [];
      shipmentOrganization = shipmentResponse.related?.organization;
      shipmentPhotos = shipmentResponse.related?.photos ?? [];
      organizationOffices = shipmentResponse.related?.offices ?? [];
      organizationAdditionalServices = shipmentResponse.related?.additional_services ?? [];
      shipmentAdditionalServices = shipmentResponse.related?.shipment_additional_services ?? [];
      if (shipmentResponse.data.load_id) {
        load = shipmentResponse.related?.load;
        driver = shipmentResponse.related?.driver ?? undefined;
        car = shipmentResponse.related?.car ?? undefined;
      }
    } else {
      [shipmentResponse, shipmentRowsResponse, shipmentPhotosResponse] = await Promise.all([
        api.organizationShipments.getOrganizationShipment({
          organizationId: userOrganizationId,
          shipmentId,
        }),
        api.organizationShipments.getOrganizationShipmentRows({
          organizationId: userOrganizationId,
          shipmentId,
        }),
        api.organizationShipments.getOrganizationShipmentPhotos({
          organizationId: userOrganizationId,
          shipmentId,
        }),
      ]);
      if (shipmentResponse.data.load_id && canAccessCustomerCoordination(currentUser)) {
        load = (
          await api.organizationLoads.getOrganizationLoad({
            organizationId: userOrganizationId,
            loadId: shipmentResponse.data.load_id,
          })
        ).data;
        if (load.drive_date) {
          const startOfDriveDate = DateTime.fromJSDate(load.drive_date).startOf('day').toJSDate();
          const endOfDriveDate = DateTime.fromJSDate(load.drive_date).endOf('day').toJSDate();
          if (load.driver_id) {
            driver = (
              await api.organizationDrivers.getOrganizationDrivers({
                organizationId: userOrganizationId,
                loadIds: [load.id],
                driveDateRangeStartsAt: startOfDriveDate,
                driveDateRangeEndsAt: endOfDriveDate,
              })
            ).data[0];
          }
          if (load.car_id) {
            car = (
              await api.organizationCars.getOrganizationCars({
                organizationId: userOrganizationId,
                loadIds: [load.id],
                driveDateRangeStartsAt: startOfDriveDate,
                driveDateRangeEndsAt: endOfDriveDate,
              })
            ).data[0];
          }
        }
      }
      shipmentOrganization = (
        await api.organizations.getOrganization({ organizationId: shipmentResponse.data.organization_id })
      ).data;
      const [organizationOfficesResponse, organizationAdditionalServicesResponse, shipmentAdditionalServicesResponse] =
        await Promise.all([
          api.organizationOffices.getOrganizationOffices({ organizationId: shipmentOrganization.id }),
          api.organizationAdditionalServices.getOrganizationAdditionalServices({
            organizationId: shipmentOrganization.id,
          }),
          api.organizationShipments.getOrganizationShipmentAdditionalServices({
            organizationId: shipmentOrganization.id,
            shipmentId: shipmentResponse.data.id,
          }),
        ]);
      organizationOffices = organizationOfficesResponse.data;
      organizationAdditionalServices = organizationAdditionalServicesResponse.data;
      shipmentAdditionalServices = shipmentAdditionalServicesResponse.data ?? [];
      pricingModelsResponse = await api.organizationPricing.getOrganizationPricingModels({
        organizationId: shipmentOrganization.id,
      });
    }
    const organization = shipmentOrganization as Organization;
    dispatch({
      type: 'INITIALIZE_SHIPMENT',
      payload: {
        shipment: shipmentResponse.data,
        shipmentPhotos: shipmentPhotos ?? shipmentPhotosResponse?.data ?? [],
        shipmentRows: shipmentRowsResponse?.data ?? shipmentRows,
        load,
        driver,
        car,
        currentUser: currentUser,
        organization: organization,
        organizationOffices,
        organizationAdditionalServices,
        shipmentAdditionalServices: shipmentAdditionalServices,
        organizations: organizationsResponse?.data ?? [organization],
        pricingModels: pricingModelsResponse?.data ?? [],
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    let message = 'Toimituksen lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Toimitusta ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const getRelatedShipments = async (
  shipmentId: OrganizationShipment['id'],
  shipmentOrganizationId: OrganizationShipment['organization_id'],
  shipmentReferenceNumber: OrganizationShipment['reference_number'],
  showAllRelatedShipments: boolean,
  dispatch: Dispatch<Action>,
): Promise<void> => {
  try {
    dispatch({
      type: 'SET_IS_RELATED_SHIPMENTS_LOADING',
      payload: {
        isRelatedShipmentsLoading: true,
      },
    });
    let relatedShipmentsResponse: Shipment[] | undefined = undefined;
    if (
      shipmentReferenceNumber &&
      !shipmentReferenceNumber.includes('SIS SIIRTO') &&
      !shipmentReferenceNumber.includes('KURIIRI')
    ) {
      const formattedReferenceNumber = shipmentReferenceNumber.match(/[^-/.]*/) ?? [];
      relatedShipmentsResponse = showAllRelatedShipments
        ? (
            await api.search.searchOrganizationShipments({
              searchOrganizationShipmentsPostBody: {
                reference_number: formattedReferenceNumber[0] ? formattedReferenceNumber[0] : shipmentReferenceNumber,
              },
              organizationId: shipmentOrganizationId,
            })
          ).data
        : (
            await api.search.searchOrganizationShipments({
              searchOrganizationShipmentsPostBody: {
                reference_number: formattedReferenceNumber[0] ? formattedReferenceNumber[0] : shipmentReferenceNumber,
                agreed_delivery_window_date_range_starts_at: DateTime.local()
                  .startOf('day')
                  .minus({ days: 30 })
                  .toJSDate(),
              },
              organizationId: shipmentOrganizationId,
            })
          ).data;
    }
    dispatch({
      type: 'SET_RELATED_SHIPMENTS',
      payload: {
        relatedShipments: relatedShipmentsResponse?.filter((shipment) => shipment.id !== shipmentId),
      },
    });
  } catch (err) {
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Saman viitenumeron toimitusten lataus epäonnistui' } });
    console.error(err);
  }
  dispatch({
    type: 'SET_IS_RELATED_SHIPMENTS_LOADING',
    payload: {
      isRelatedShipmentsLoading: false,
    },
  });
};

const EllipsisChip = styled(Chip)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
});

const PhotoLink = styled(Link)({
  maxWidth: '50%',
});

const PhotoTimestamp = styled(Typography)({});

const PhotoContainer = styled('div')({
  maxWidth: '80vw',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  position: 'relative',
  marginBottom: '0.2rem',
  justifyContent: 'space-between',
});

const LocationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
});

const HeaderRow = styled('div')({
  display: 'flex',
  flexBasis: '100%',
});

const PhotoChip: React.FC<{ photo: Photo; currentUser?: User }> = ({ photo, currentUser }) => {
  const getPhotoUrl = canAccessCoordination(currentUser)
    ? `/api/v1/shipments/${photo.shipment_id}/photos/${photo.id}`
    : `/api/v1/organizations/${currentUser?.organization_id}/shipments/${photo.shipment_id}/photos/${photo.id}`;
  return (
    <PhotoContainer className="photo_container">
      <PhotoLink href={getPhotoUrl} title={photo.description}>
        <EllipsisChip icon={<PhotoCameraOutlined />} label={photo.description} clickable />
      </PhotoLink>
      <PhotoTimestamp variant="body2">{formatDateTime(photo.timestamp)}</PhotoTimestamp>
    </PhotoContainer>
  );
};

const getOrganizationShipmentPutBodyFromState = (state: State): OrganizationShipmentPutBody => {
  const pickupWindow = getPickupWindow(state.fields);
  const agreedDeliveryWindow = getAgreedDeliveryWindow(state.fields);
  return {
    agreed_delivery_window_starts_at: agreedDeliveryWindow.startsAt.toJSDate(),
    agreed_delivery_window_ends_at: agreedDeliveryWindow.endsAt.toJSDate(),
    billing_office_organization_specific_id: state.fields.billing_office_organization_specific_id.value || '',
    delivery_office_organization_specific_id: state.fields.delivery_office_organization_specific_id.value || null,
    delivery_name: state.fields.delivery_name.value,
    delivery_address: state.fields.delivery_address.value,
    delivery_city: state.fields.delivery_city.value,
    delivery_phone_number: state.fields.delivery_phone_number.value || null,
    delivery_postal_code: state.fields.delivery_postal_code.value,
    length_ldm: state.fields.length_ldm.value || 0,
    note: state.fields.note.value,
    orderer: state.fields.orderer.value || null,
    orderer_phone_number: state.fields.orderer_phone_number.value || null,
    reference_number: state.fields.reference_number.value,
    job_number: state.fields.job_number.value,
    pickup_office_organization_specific_id: state.fields.pickup_office_organization_specific_id.value || null,
    pickup_name: state.fields.pickup_name.value,
    pickup_address: state.fields.pickup_address.value,
    pickup_city: state.fields.pickup_city.value,
    pickup_phone_number: state.fields.pickup_phone_number.value || null,
    pickup_postal_code: state.fields.pickup_postal_code.value,
    pickup_window_starts_at: pickupWindow?.startsAt.toJSDate() ?? null,
    pickup_window_ends_at: pickupWindow?.endsAt.toJSDate() ?? null,
    weight_kg: state.fields.weight_kg.value || 0,
    volume_m3: state.fields.volume_m3.value || 0,
    state: state.fields.state.value as unknown as OrganizationShipmentPutBodyStateEnum,
    pricing_model: state.fields.pricing_model.value || null,
    custom_pricing_category_id: state.fields.custom_pricing_category_id.value || null,
    other_contract_number: state.fields.other_contract_number.value || null,
    is_adr_delivery: state.fields.is_adr_delivery.value || false,
    delivery_phone_number_secondary: state.fields.delivery_phone_number_secondary.value || null,
    billing_reference_number: state.fields.billing_reference_number.value || null,
    requires_combination_vehicle: state.fields.requires_combination_vehicle.value || false,
    requires_hoist: state.fields.requires_hoist.value || false,
    is_express_delivery: state.fields.is_express_delivery.value || false,
    rows: state.rows.map((row) => {
      const { tableData, ...rest } = row;
      rest.serial_number = rest.serial_number ?? null;
      rest.parcel_id = rest.parcel_id ?? null;
      rest.quantity = rest.quantity ?? null;
      rest.weight_per_piece_kg = rest.weight_per_piece_kg ?? null;
      return rest;
    }) as OrganizationShipmentPutBody['rows'],
  };
};

const getShipmentPutBodyFromState = (state: State, currentUser?: User): ShipmentPutBody => {
  return {
    ...getOrganizationShipmentPutBodyFromState(state),
    hourly_work_hours: state.fields.hourly_work_hours.value || 0,
    hourly_work_reason: state.fields.hourly_work_reason.value,
    job_number: state.fields.job_number.value,
    load_id: state.fields.load_id.value || null,
    order_in_load: state.originalShipment?.order_in_load ?? null,
    organization_id: state.fields.organization_id.value,
    other_contract_number: state.fields.other_contract_number.value || null,
    recipient: state.originalShipment?.recipient ?? null,
    state: state.fields.state.value as unknown as ShipmentPutBodyStateEnum,
    wait_reason: state.fields.wait_reason.value,
    weight_kg: state.fields.weight_kg.value || 0,
    ordered_at: state.fields.ordered_at.value || null,
    arrived_to_pickup_location_at: state.fields.arrived_to_pickup_location_at.value || null,
    picked_up_at: state.fields.picked_up_at.value || null,
    arrived_to_delivery_location_at: state.fields.arrived_to_delivery_location_at.value || null,
    delivered_at: state.fields.delivered_at.value || null,
    billed_at: ifDateExistGetSameDateAtMidday(state.fields.billed_at.value),
    wait_hours: state.fields.wait_hours.value || 0,
    ...(canAccessPricing(currentUser) && {
      has_contract_price: state.fields.has_contract_price.value || false,
      legacy_price_basis: state.fields.legacy_price_basis.value,
      price: state.fields.price.value || 0,
      has_additional_hourly_pricing: state.fields.has_additional_hourly_pricing.value || false,
      legacy_etaisyys_field: state.fields.legacy_etaisyys_field.value || null,
      has_distance_been_fixed: state.fields.has_distance_been_fixed.value || false,
    }),
    rows: state.rows.map((row) => {
      const { tableData, ...rest } = row as ShipmentRowWithTableData & WithIsNew;
      rest.serial_number = rest.serial_number ?? null;
      rest.parcel_id = rest.parcel_id ?? null;
      rest.quantity = rest.quantity ?? null;
      rest.weight_per_piece_kg = rest.weight_per_piece_kg ?? null;
      if (rest.isNew) {
        const { id, ...rowWithOutId } = rest;
        return rowWithOutId;
      } else {
        return rest;
      }
    }) as ShipmentPutBody['rows'],
    additional_services: state.additionalServiceFields.map((field) => ({
      additional_service_id: field.additionalService.id,
      shipment_additional_service_id: field.shipmentAdditionalServiceId,
      amount: field.value || 0,
    })),
  };
};

const getUpdateDistanceAndItsRelatedShipmentsPutBodyFromState = (
  state: State,
): UpdateDistanceAndItsRelatedShipmentsPatchBody => {
  return {
    legacy_etaisyys_field: state.fields.legacy_etaisyys_field.value || null,
    address1: state.fields.pickup_address.value || '',
    city1: state.fields.pickup_city.value || '',
    address2: state.fields.delivery_address.value || '',
    city2: state.fields.delivery_city.value || '',
  };
};

const saveShipment = async (
  shipmentId: Shipment['id'],
  state: State,
  dispatch: Dispatch<Action>,
  currentUser?: User,
) => {
  dispatch({ type: 'SET_LOADING', payload: true });
  if (!currentUser?.organization_id) {
    throw new Error('Missing org id');
  }
  if (!state.isValid && !canAccessCoordination(currentUser)) {
    dispatch(checkFieldsMessage);
    return;
  }
  try {
    if (canAccessCoordination(currentUser)) {
      await api.shipments.updateShipment({
        shipmentId,
        shipmentPutBody: getShipmentPutBodyFromState(state, currentUser),
      });
      if (
        state.fields.has_distance_been_fixed.value &&
        state.fields.legacy_etaisyys_field.value &&
        state.fields.legacy_etaisyys_field.value !== state.originalShipment?.legacy_etaisyys_field
      ) {
        await api.distances.updateDistanceAndItsRelatedShipments({
          updateDistanceAndItsRelatedShipmentsPatchBody: getUpdateDistanceAndItsRelatedShipmentsPutBodyFromState(state),
        });
      }
    } else {
      await api.organizationShipments.updateOrganizationShipment({
        organizationId: state.fields.organization_id.value,
        shipmentId,
        organizationShipmentPutBody: getOrganizationShipmentPutBodyFromState(state),
      });
    }
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Toimitus tallennettu!' } });
    load(state.fields.organization_id.value, shipmentId, dispatch, currentUser);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
  }
  dispatch({ type: 'SET_LOADING', payload: false });
};

type EditShipmentParams = {
  shipmentId?: string;
};

const EditShipment: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const organization = useCurrentUserOrganization();
  const [state, dispatch] = useReducer(reducer, getInitialState(currentUser));
  const { shipmentId } = useParams<EditShipmentParams>();
  const parsedShipmentId = parseInt(shipmentId || '');
  const hasCoordinationAccess = canAccessCoordination(currentUser);
  const hasCustomerCoordinationAccess = canAccessCustomerCoordination(currentUser);
  const [hideRelatedShipments, setHideRelatedShipments] = React.useState(true);
  const [showAllRelatedShipments, setShowAllRelatedShipments] = React.useState(false);
  const customPricingCategories = state.pricingModels.find(
    (pricingModel) => pricingModel.id === state.fields.pricing_model.value,
  )?.custom_pricing_categories;

  useEffect(() => {
    if (currentUser?.organization_id) {
      dispatch({ type: 'CLEAR_SHIPMENT', payload: { currentUser } });
      load(currentUser.organization_id, parsedShipmentId, dispatch, currentUser);
    }
  }, [currentUser, parsedShipmentId]);

  useEffect(() => {
    if (currentUser && state.originalShipment) {
      getRelatedShipments(
        state.originalShipment.id,
        state.originalShipment.organization_id,
        state.originalShipment.reference_number,
        showAllRelatedShipments,
        dispatch,
      );
    }
  }, [state.originalShipment?.reference_number, showAllRelatedShipments]);

  const stateMenuItems = [
    <MenuItem key={ShipmentPutBodyStateEnum.EiVarastossa} value={ShipmentPutBodyStateEnum.EiVarastossa}>
      Ei varastossa
    </MenuItem>,
    <MenuItem key={ShipmentPutBodyStateEnum.Noudettavissa} value={ShipmentPutBodyStateEnum.Noudettavissa}>
      Noudettavissa
    </MenuItem>,
    <MenuItem
      key={ShipmentPutBodyStateEnum.Noudettu}
      value={ShipmentPutBodyStateEnum.Noudettu}
      disabled={!hasCoordinationAccess}
    >
      Noudettu
    </MenuItem>,
    <MenuItem
      key={ShipmentPutBodyStateEnum.Toimitettu}
      value={ShipmentPutBodyStateEnum.Toimitettu}
      disabled={!hasCoordinationAccess}
    >
      Toimitettu
    </MenuItem>,
    <MenuItem key={ShipmentPutBodyStateEnum.Peruttu} value={ShipmentPutBodyStateEnum.Peruttu}>
      Peruttu
    </MenuItem>,
    <MenuItem
      key={ShipmentPutBodyStateEnum.Odottaa}
      value={ShipmentPutBodyStateEnum.Odottaa}
      disabled={!hasCoordinationAccess}
    >
      Odottaa
    </MenuItem>,
  ];
  const [previousShipment, nextShipment] = React.useMemo(previousAndNextShipmentLinks, [shipmentId]);
  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title={`Toimitus ${parsedShipmentId}`}>
            <ButtonContainer>
              <SaveButton
                disabled={
                  (!canAccessCoordination(currentUser) || state.isLoading) &&
                  (!state.isValid || !state.canEdit || state.isLoading)
                }
                color={!state.isValid ? 'warning' : 'success'}
                id="save-shipment-button"
                tooltip={
                  !state.canEdit
                    ? 'Peruttua tai jo kuormaan lisättyä toimitusta ei voi muokata.'
                    : !state.isValid
                      ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä'
                      : ''
                }
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  saveShipment(parsedShipmentId, state, dispatch, currentUser);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }}
              >
                Tallenna toimitus
              </SaveButton>
              <Button
                disabled={state.isLoading}
                id="copy-shipment-button"
                onClick={() => {
                  if (!state.originalShipment || !organization) {
                    throw new Error('Prerequisities not met');
                  }
                  navigate('/shipments/new', {
                    state: {
                      shipment: copyShipment(state.originalShipment),
                      shipmentRows: state.rows,
                      additionalServiceFields: state.additionalServiceFields,
                    },
                  });
                }}
              >
                Luo kopio
              </Button>
              <Button
                disabled={state.isLoading}
                id="create-return-shipment-button"
                onClick={() => {
                  if (!state.originalShipment || !organization) {
                    throw new Error('Prerequisities not met');
                  }
                  navigate('/shipments/new', {
                    state: {
                      shipment: convertToReturnShipment(state.originalShipment),
                      shipmentRows: state.rows,
                      additionalServiceFields: state.additionalServiceFields,
                    },
                  });
                }}
              >
                Luo paluutoimitus
              </Button>
              <Button
                disabled={state.isLoading}
                id="create-waybill-button"
                onClick={() => {
                  if (state.originalShipment) {
                    downloadWaybill(state.originalShipment, state.rows, state.driver);
                  }
                }}
              >
                Luo rahtikirja
              </Button>
              {/* TODO This is commented out, since we are not ready to use it */}
              {/* <Button
                disabled={state.isLoading}
                id="create-shipment-row-stickers-button"
                onClick={() => {
                  if (state.originalShipment && state.rows) {
                    downloadShipmentRowStickers(state.originalShipment, state.rows);
                  }
                }}
              >
                Luo kollitarrat
              </Button> */}
            </ButtonContainer>
            {(nextShipment || previousShipment) && (
              <HeaderRow>
                {previousShipment && (
                  <Link
                    id="previous-shipment-button"
                    data-cy={'previous-shipment'}
                    component={RouterLink}
                    to={previousShipment}
                  >
                    Edellinen toimitus
                  </Link>
                )}
                {nextShipment && (
                  <Link
                    style={{ marginLeft: 'auto' }}
                    id="next-shipment-button"
                    data-cy={'next-shipment'}
                    component={RouterLink}
                    to={nextShipment}
                  >
                    Seuraava toimitus
                  </Link>
                )}
              </HeaderRow>
            )}
          </Header>
          {state?.originalShipment?.id ? (
            <UpdateInfo updated_by={state.updated_by} updated_at={state.updated_at} />
          ) : null}
        </HeaderContainer>
        <FieldSetContainer>
          <FieldSet id="orderer-fields-container">
            <legend>Tilaaja</legend>
            <OrganizationField
              state={state}
              dispatch={dispatch}
              disabled={!canAccessCoordination(currentUser)}
              currentUser={currentUser}
            />
            <Orderer state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <BillingOffice state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <OrdererPhoneNumber state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <ReferenceNumber state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <JobNumber state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <BillingReferenceNumber state={state} dispatch={dispatch} disabled={state.isLoading} />
            <CustomerDistributionArea state={state} dispatch={dispatch} disabled={true} />
            <OtherContractNumber state={state} dispatch={dispatch} disabled={state.isLoading} />
            {customPricingCategories && customPricingCategories.length > 0 ? (
              <CustomPricingCategory state={state} dispatch={dispatch} disabled={state.isLoading} />
            ) : null}
          </FieldSet>
          <FieldSet id="pickup-fields-container">
            <legend>Nouto</legend>
            <LocationContainer className="pickup_address_link">
              <AddressLink
                title="Näytä sijainti"
                showIcon={true}
                address={state.fields.pickup_address.value}
                postalCode={state.fields.pickup_postal_code.value}
                city={state.fields.pickup_city.value}
              />
            </LocationContainer>
            <PickupOffice state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <PickupName state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <PickupAddress state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <PickupPostalCode state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <PickupCity state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <PickupPhoneNumber state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <PickupTimePicker state={state} dispatch={dispatch} disabled={!state.canEdit} currentUser={currentUser} />
            <StateSelect state={state} dispatch={dispatch} disabled={!state.canEdit}>
              {stateMenuItems}
            </StateSelect>
          </FieldSet>
          <FieldSet id="delivery-fields-container">
            <legend>Toimitus</legend>
            <LocationContainer className="delivery_address_link">
              {isPreciseDelivery(
                state.fields.delivery_time_window_start.value,
                state.fields.delivery_time_window_end.value,
              ) ? (
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                  <Alert className="precise-delivery-alert" severity="warning">
                    Täsmätoimitus!
                  </Alert>
                </Box>
              ) : null}
              {isNumber(state.fields.price.value) && state.fields.price.value < 0 ? (
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                  <Alert className="negative-price-alert" severity="warning">
                    Negatiivinen hinta
                  </Alert>
                </Box>
              ) : null}
              <AddressLink
                title="Näytä sijainti"
                showIcon={true}
                address={state.fields.delivery_address.value}
                postalCode={state.fields.delivery_postal_code.value}
                city={state.fields.delivery_city.value}
              />
            </LocationContainer>
            <DeliveryOffice state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <DeliveryName state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <DeliveryAddress state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <DeliveryPostalCode state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <DeliveryCity state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <DeliveryPhoneNumber
              state={state}
              dispatch={dispatch}
              disabled={!state.canEdit}
              organization={organization}
            />
            <DeliveryPhoneNumberSecondary state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <DeliveryDatePicker state={state} dispatch={dispatch} disabled={!state.canEdit} currentUser={currentUser} />
            <DeliveryTimePicker state={state} dispatch={dispatch} disabled={!state.canEdit} />
          </FieldSet>
          <FieldSet className="additional-services-container">
            <legend>Lisäpalvelut</legend>
            <AdditionalRequirementFields
              state={state}
              dispatch={dispatch}
              disabled={!state.canEdit || state.isLoading}
            />
            {hasCoordinationAccess && (
              <AdditionalServices state={state} dispatch={dispatch} disabled={state.isLoading} />
            )}
          </FieldSet>
          <FieldSet id="notes-fields-container">
            <legend>Lisätietoja</legend>
            <Notes state={state} dispatch={dispatch} disabled={!state.canEdit} />
          </FieldSet>
          {isHostOrganizationUser(currentUser) || canAccessCustomerPricing(currentUser) ? (
            <PricingFieldSet state={state} dispatch={dispatch} currentUser={currentUser} isEditShipment={true} />
          ) : null}
          {state.originalShipment !== undefined ? (
            <>
              <FieldSet id="timeline-fields-container">
                <legend>Tapahtumat</legend>
                <ShipmentTimeline
                  shipment={state.originalShipment}
                  state={state}
                  dispatch={dispatch}
                  currentUser={currentUser}
                />
              </FieldSet>
              <FieldSet id="attachment-fields-container">
                <legend>Liitteet</legend>
                {state.photos.map((photo) => (
                  <PhotoChip key={photo.id} photo={photo} currentUser={currentUser} />
                ))}

                <ShipmentAddFileDialog
                  open={state.isAddPhotoDialogOpen}
                  shipmentId={parsedShipmentId}
                  onClose={() => {
                    dispatch({ type: 'SET_IS_PHOTO_DIALOG_OPEN', payload: { isDialogOpen: false } });
                  }}
                  onSuccess={() => {
                    let getPhotosApi;
                    if (canAccessCoordination(currentUser)) {
                      getPhotosApi = api.shipments.getShipmentPhotos({ shipmentId: parsedShipmentId });
                    } else if (currentUser?.organization_id) {
                      getPhotosApi = api.organizationShipments.getOrganizationShipmentPhotos({
                        organizationId: currentUser?.organization_id,
                        shipmentId: parsedShipmentId,
                      });
                    } else {
                      throw new Error('Organization id not found');
                    }
                    getPhotosApi.then((response) => {
                      if (response?.data.length > 0) {
                        dispatch({ type: 'SET_SHIPMENT_PHOTOS', payload: { photos: response.data } });
                      }
                    });
                  }}
                  onLoading={() => dispatch({ type: 'SET_LOADING', payload: true })}
                  onLoadingDone={() => dispatch({ type: 'SET_LOADING', payload: false })}
                  showMessage={(message) => dispatch({ type: 'SET_MESSAGE', payload: { message } })}
                  currentUser={currentUser}
                />
                <FullWidthButton
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch({ type: 'SET_IS_PHOTO_DIALOG_OPEN', payload: { isDialogOpen: true } });
                  }}
                  id={'open-add-file-dialog'}
                >
                  Lisää tiedosto
                </FullWidthButton>
              </FieldSet>
            </>
          ) : null}
          {hasCoordinationAccess || hasCustomerCoordinationAccess ? (
            <CoordinationFieldSet state={state} currentUser={currentUser} />
          ) : null}
          <RelatedShipmentsFieldSet
            state={state}
            dispatch={dispatch}
            currentUser={currentUser}
            hideRelatedShipments={hideRelatedShipments}
            setHideRelatedShipments={setHideRelatedShipments}
            isRelatedShipmentsLoading={state.isRelatedShipmentsLoading}
            showAllRelatedShipments={showAllRelatedShipments}
            setShowAllRelatedShipments={setShowAllRelatedShipments}
          />
          {hasCoordinationAccess ? (
            <FieldSet id="update-history-fields-container">
              <legend>Muokkaushistoria</legend>
              <UpdateHistory shipmentId={parsedShipmentId} isLoading={state.isLoading} />
            </FieldSet>
          ) : null}
          <FieldSet id="size-fields-container">
            <legend>Kokotiedot</legend>
            <LengthLdmInput state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <VolumeM3Input state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <WeightKgInput state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <ChargeableWeightInput state={state} dispatch={dispatch} disabled={true} />
            {state.fields.sizes.feedback ? (
              <FormHelperText sx={{ color: theme.palette.error.main }}>{state.fields.sizes.feedback}</FormHelperText>
            ) : null}
          </FieldSet>
        </FieldSetContainer>
        <div style={{ maxWidth: '100%' }}>
          <ShipmentRowTable
            user={currentUser}
            rows={state.rows}
            onRowsUpdate={state.canEdit ? (rows) => updateRows(rows, dispatch) : undefined}
          />
        </div>
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default EditShipment;
