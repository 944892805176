import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  api,
  getAllPages,
  Organization,
  OrganizationShipment,
  OrganizationsResponseBody,
  Shipment,
  User,
} from '../../api';
import { ReactTable } from '../../components/ReactTable';
import Notification, {
  getSnackbarPropsFromState,
  NotificationType,
  SetMessageAction,
} from '../../components/Notification';
import { Link } from '@mui/material';
import { AutoCompleteFilter, DateColumnFilter, SelectColumnFilter } from '../../components/TableFilters';
import { CellValue, ColumnInstance, Row, TableRowProps, TableState } from 'react-table';
import {
  DateRangePicker,
  getDateRangePickerPropsFromState,
  getDefaultDateRange,
  Range,
  SetDateRangeAction,
} from '../../components/DateRangePicker';
import Main from '../../components/Main';
import { formatDate } from '../../formatters';
import { AddressLink } from '../../components/map';
import { getViewSettings, updateViewSettings } from '../settings';
import { ShipmentRowSubRowAsync } from '../../components/ShipmentRowSubRow';
import { saveShipmentSet } from './components/ShipmentSetHistory';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { SetLoadingAction } from '../../components/Loading';
import { getStateAndTimeFromShipment } from '../../utils';

const VIEW_ID = 'shipments' as const;

interface ShipmentsViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
}

interface WithOrganizationName {
  organizationName?: string;
}

interface State {
  shipments: ((OrganizationShipment | Shipment) & WithOrganizationName)[];
  organizations: Organization[];
  notification: NotificationType;
  dateRange: Range | null;
  isLoading: boolean;
  viewSettings: ShipmentsViewSettings;
  shipmentSetId: string;
}

const getInitialState = (): State => {
  return {
    shipments: [],
    organizations: [],
    notification: {
      message: null,
    },
    dateRange: getDefaultDateRange(),
    isLoading: true,
    viewSettings: { filters: [], sortBy: [], hiddenColumns: [] },
    shipmentSetId: '',
  };
};

type Action =
  | {
      type: 'INITIALIZE';
      payload: { shipments: (OrganizationShipment | Shipment)[]; organizations: Organization[] };
    }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction
  | { type: 'SET_VIEW_SETTINGS'; payload: ShipmentsViewSettings };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      const shipmentSetId = saveShipmentSet(action.payload.shipments);
      return {
        ...state,
        shipmentSetId,
        shipments: action.payload.shipments.map((shipment) => {
          return {
            ...shipment,
            organizationName: action.payload.organizations.find((org) => org.id === shipment.organization_id)?.name,
          };
        }),
        organizations: action.payload.organizations,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('shipments', action.payload);
      return {
        ...state,
        viewSettings: action.payload,
      };
  }
};

const fetchShipments = async (
  currentUser: User,
  state: State,
  dispatch: React.Dispatch<Action>,
  abortController: AbortController,
) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    if (state.dateRange?.start && state.dateRange?.end) {
      let shipments: (OrganizationShipment | Shipment)[] = [];
      let organizationsResponse: OrganizationsResponseBody | undefined = undefined;
      [shipments, organizationsResponse] = currentUser.is_multi_organization
        ? await Promise.all([
            getAllPages(
              api.shipments.getShipments.bind(api.shipments),
              {
                agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
                agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
              },
              abortController,
            ),
            api.organizations.getOrganizations({}),
          ])
        : await Promise.all([
            await getAllPages(
              api.organizationShipments.getOrganizationShipments.bind(api.organizationShipments),
              {
                organizationId: currentUser.organization_id,
                agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
                agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
              },
              abortController,
            ),
            Promise.resolve(undefined),
          ]);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          shipments,
          organizations: organizationsResponse?.data ?? [],
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa toimituksia',
        severity: 'error',
      },
    });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

const Shipments: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const currentUser = useCurrentUser();
  const viewSettings = React.useMemo(() => getViewSettings<ShipmentsViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (currentUser?.organization_id && state.dateRange) {
      const abortController = new AbortController();
      fetchShipments(currentUser, state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [currentUser, state.dateRange]);

  const renderRowSubComponent = React.useCallback(
    (row: Row, rowProps: TableRowProps, visibleColumns: ColumnInstance[]) => (
      <ShipmentRowSubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        hideSizeInformation={true}
      />
    ),
    [],
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'Id',
        accessor: 'id',
        Cell: ({ row }: { row: Row }) => (
          <Link
            component={RouterLink}
            to={{ pathname: `/shipments/${row.values.id}`, search: `?shipmentSet=${state.shipmentSetId}` }}
          >
            {row.values.id}
          </Link>
        ),
      },
      {
        id: 'reference_number',
        Header: 'Asiakasviite',
        accessor: 'reference_number',
      },
      {
        id: 'organization',
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Lähettäjä',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'pickup_name',
          },
          {
            Header: 'Osoite',
            accessor: 'pickup_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="pickup-address-link"
                  title={shipment.cell.row.original.pickup_address}
                  address={shipment.cell.row.original.pickup_address}
                  postalCode={shipment.cell.row.original.pickup_postal_code}
                  city={shipment.cell.row.original.pickup_city}
                />
              );
            },
          },
          {
            Header: 'Kaupunki',
            accessor: 'pickup_city',
          },
        ],
      },
      {
        Header: 'Vastaanottaja',
        columns: [
          {
            Header: 'Nimi',
            accessor: 'delivery_name',
          },
          {
            Header: 'Osoite',
            accessor: 'delivery_address',
            Cell: (shipment: CellValue) => {
              return (
                <AddressLink
                  className="delivery-address-link"
                  title={shipment.cell.row.original.delivery_address}
                  address={shipment.cell.row.original.delivery_address}
                  postalCode={shipment.cell.row.original.delivery_postal_code}
                  city={shipment.cell.row.original.delivery_city}
                />
              );
            },
          },
          {
            Header: 'Kaupunki',
            accessor: 'delivery_city',
          },
        ],
      },
      {
        Header: 'Tila',
        accessor: 'state',
        Filter: SelectColumnFilter,
        sortType: (row1: Row<Shipment>, row2: Row<Shipment>) => {
          return new Intl.Collator('fi', { numeric: true, caseFirst: 'false' }).compare(
            getStateAndTimeFromShipment(row1.original, true),
            getStateAndTimeFromShipment(row2.original, true),
          );
        },
        Cell: ({ row }: { row: Row<Shipment> }) => getStateAndTimeFromShipment(row.original),
      },
      {
        Header: 'Sovittu toimituspäivä',
        accessor: 'agreed_delivery_window_starts_at',
        Cell: (shipment: CellValue) => (shipment.value ? formatDate(shipment.value) : ''),
        Filter: DateColumnFilter,
        sortType: 'datetime',
      },
      {
        Header: 'Puhelinnumero',
        accessor: 'delivery_phone_number',
      },
      {
        Header: 'Lisätietoja',
        accessor: 'note',
      },
    ],
    [state.shipmentSetId],
  );
  const data = React.useMemo(() => state.shipments, [state.shipments]);
  return (
    <Main>
      {currentUser !== undefined && (
        <ReactTable
          columns={columns}
          data={data}
          header="Toimitukset"
          dateRangePicker={<DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)}></DateRangePicker>}
          isLoading={state.isLoading}
          emptyText="Ei näytettäviä toimituksia."
          renderRowSubComponent={renderRowSubComponent}
          initialState={{
            sortBy: viewSettings.sortBy ?? [
              {
                id: 'id',
                desc: true,
              },
            ],
            filters: viewSettings.filters ?? [],
            hiddenColumns: [
              (!currentUser?.is_multi_organization && !currentUser?.is_superuser && 'organization') || undefined,
            ],
          }}
          onStateChange={(state) => {
            dispatch({
              type: 'SET_VIEW_SETTINGS',
              payload: { filters: state.filters, sortBy: state.sortBy, hiddenColumns: state.hiddenColumns },
            });
          }}
        />
      )}
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default Shipments;
