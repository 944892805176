import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button } from '@mui/material';
import { dateFormat, formatWeekDay } from '../../../formatters';
import { DateTime } from 'luxon';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import theme from '../../../theme';
import { DateCalendar } from '@mui/x-date-pickers';
import { getSalaryPeriod } from '../DriverHours';
import { updateDateRangeToUrl } from '../../../components/DateRangePicker';
import { isToday } from '../../../utils';

const setDateAndUpdateUrlIfNeeded = (dateTime: DateTime, isDriverHoursView: boolean, setDate: any) => {
  if (!isDriverHoursView) {
    updateDateRangeToUrl({
      start: dateTime,
      end: dateTime,
    });
  }
  setDate(dateTime.toJSDate());
};

const DriverDateMenu: React.FC<{ date: Date; setDate: any }> = ({ date, setDate }) => {
  const dateTime = DateTime.fromJSDate(date);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const isDriverHoursView = new URL(window.location.href).pathname.includes('hours');
  const [minDate, maxDate] = isDriverHoursView ? getSalaryPeriod(DateTime.now(), true) : [null, null];
  const handleExpanded = () => setExpanded(!expanded);
  const isSelectedDateToday = isToday(dateTime);

  return (
    <Accordion
      expanded={expanded}
      sx={{ backgroundColor: isSelectedDateToday ? undefined : theme.palette.table.light }}
    >
      <AccordionSummary sx={{ padding: 0, height: '1rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="text"
            sx={{ color: theme.palette.primary.dark }}
            disabled={Boolean(minDate && dateTime.startOf('day') <= minDate.startOf('day'))}
            onClick={() => setDateAndUpdateUrlIfNeeded(dateTime.minus({ days: 1 }), isDriverHoursView, setDate)}
          >
            <KeyboardArrowLeft />
          </Button>
          <Button
            variant="text"
            sx={{
              fontSize: '1.25rem',
              fontWeight: isSelectedDateToday ? 'bold' : undefined,
              color: theme.palette.primary.dark,
            }}
            onClick={handleExpanded}
          >
            {formatWeekDay(dateTime)} {dateTime.toFormat(dateFormat)}
          </Button>
          <Button
            variant="text"
            sx={{ color: theme.palette.primary.dark }}
            disabled={Boolean(maxDate && dateTime.startOf('day') >= maxDate.startOf('day'))}
            onClick={() => setDateAndUpdateUrlIfNeeded(dateTime.plus({ days: 1 }), isDriverHoursView, setDate)}
          >
            <KeyboardArrowRight />
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <DateCalendar
          minDate={minDate ?? undefined}
          maxDate={maxDate ?? undefined}
          value={dateTime ? dateTime : null}
          onChange={(newDate) => {
            if (newDate) {
              setDateAndUpdateUrlIfNeeded(newDate, isDriverHoursView, setDate);
            }
            handleExpanded();
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default DriverDateMenu;
