import React from 'react';
import { DateTimePickerProps, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { dateTimeFormat } from '../../formatters';
import { TimeClockView } from './StandardTimePicker';
import { mobileActionBar } from './StandardDatePicker';

export const StandardDateTimePicker: React.FC<DateTimePickerProps<any>> = (props) => (
  <DesktopDateTimePicker
    {...props}
    format={dateTimeFormat}
    value={props.value instanceof Date ? DateTime.fromJSDate(props.value) : props.value}
    viewRenderers={{ hours: TimeClockView, minutes: TimeClockView }}
    slotProps={{
      actionBar: mobileActionBar,
      textField: {
        ...props.slotProps?.textField,
        placeholder: '',
        size: 'small',
        variant: 'standard',
        error: (props.slotProps?.textField as any).error,
        helperText: (props.slotProps?.textField as any).helperText,
      },
    }}
  />
);
