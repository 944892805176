/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  DriverOverviewResponseBody,
  DriverOverviewResponseBodyFromJSON,
  DriverOverviewResponseBodyToJSON,
  DriverPostPutBody,
  DriverPostPutBodyFromJSON,
  DriverPostPutBodyToJSON,
  DriverResponseBody,
  DriverResponseBodyFromJSON,
  DriverResponseBodyToJSON,
  DriverResponseBody1,
  DriverResponseBody1FromJSON,
  DriverResponseBody1ToJSON,
  DriverShipmentsResponseBody,
  DriverShipmentsResponseBodyFromJSON,
  DriverShipmentsResponseBodyToJSON,
  DriverWithWorkHoursAndTimeOffs,
  DriverWithWorkHoursAndTimeOffsFromJSON,
  DriverWithWorkHoursAndTimeOffsToJSON,
  DriverWithWorkHoursAndTimeOffsPostBody,
  DriverWithWorkHoursAndTimeOffsPostBodyFromJSON,
  DriverWithWorkHoursAndTimeOffsPostBodyToJSON,
  DriversResponseBody,
  DriversResponseBodyFromJSON,
  DriversResponseBodyToJSON,
  DriversWithWorkHoursAndTimeOffsResponseBody,
  DriversWithWorkHoursAndTimeOffsResponseBodyFromJSON,
  DriversWithWorkHoursAndTimeOffsResponseBodyToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  LoadResponseBody,
  LoadResponseBodyFromJSON,
  LoadResponseBodyToJSON,
  LoadsResponseBody,
  LoadsResponseBodyFromJSON,
  LoadsResponseBodyToJSON,
  PatchDriverLoadShipmentRowsRequestBody,
  PatchDriverLoadShipmentRowsRequestBodyFromJSON,
  PatchDriverLoadShipmentRowsRequestBodyToJSON,
  PatchDriverLoadShipmentsByIdRequestBody,
  PatchDriverLoadShipmentsByIdRequestBodyFromJSON,
  PatchDriverLoadShipmentsByIdRequestBodyToJSON,
  PatchDriverLoadShipmentsRowsRequestBody,
  PatchDriverLoadShipmentsRowsRequestBodyFromJSON,
  PatchDriverLoadShipmentsRowsRequestBodyToJSON,
  PhotoFileResponseBody,
  PhotoFileResponseBodyFromJSON,
  PhotoFileResponseBodyToJSON,
  PhotoResponseBody,
  PhotoResponseBodyFromJSON,
  PhotoResponseBodyToJSON,
  PhotosResponseBody,
  PhotosResponseBodyFromJSON,
  PhotosResponseBodyToJSON,
  ShipmentResponseBody,
  ShipmentResponseBodyFromJSON,
  ShipmentResponseBodyToJSON,
  ShipmentRowProductsResponseBody,
  ShipmentRowProductsResponseBodyFromJSON,
  ShipmentRowProductsResponseBodyToJSON,
  ShipmentRowResponseBody,
  ShipmentRowResponseBodyFromJSON,
  ShipmentRowResponseBodyToJSON,
  ShipmentRowsResponseBody,
  ShipmentRowsResponseBodyFromJSON,
  ShipmentRowsResponseBodyToJSON,
  ShipmentsPatchResponseBody,
  ShipmentsPatchResponseBodyFromJSON,
  ShipmentsPatchResponseBodyToJSON,
  ShipmentsRowsResponseBody,
  ShipmentsRowsResponseBodyFromJSON,
  ShipmentsRowsResponseBodyToJSON,
  SingleShipmentPatchBody,
  SingleShipmentPatchBodyFromJSON,
  SingleShipmentPatchBodyToJSON,
  WorkHour,
  WorkHourFromJSON,
  WorkHourToJSON,
} from '../models';

export interface DriverResourcesApiCreateDriverRequest {
  driverPostPutBody: DriverPostPutBody;
}

export interface DriverResourcesApiDeleteDriverLoadShipmentPhotosRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  photoId: string;
}

export interface DriverResourcesApiGetDailyDriverOverviewRequest {
  username: string;
  driveDate: Date;
  workingDays?: number;
}

export interface DriverResourcesApiGetDriverRequest {
  driverId: number;
}

export interface DriverResourcesApiGetDriverLoadRequest {
  username: string;
  loadId: number;
}

export interface DriverResourcesApiGetDriverLoadShipmentRequest {
  username: string;
  loadId: number;
  shipmentId: number;
}

export interface DriverResourcesApiGetDriverLoadShipmentPhotoRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  photoId: string;
}

export interface DriverResourcesApiGetDriverLoadShipmentPhotosRequest {
  username: string;
  loadId: number;
  shipmentId: number;
}

export interface DriverResourcesApiGetDriverLoadShipmentRowRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  shipmentRowId: number;
}

export interface DriverResourcesApiGetDriverLoadShipmentRowShipmentRowProductsRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  shipmentRowId: number;
}

export interface DriverResourcesApiGetDriverLoadShipmentRowsRequest {
  username: string;
  loadId: number;
  shipmentId: number;
}

export interface DriverResourcesApiGetDriverLoadShipmentsRequest {
  username: string;
  loadId: number;
  next?: number;
}

export interface DriverResourcesApiGetDriverLoadsRequest {
  username: string;
  next?: number;
  driveDate?: Date;
}

export interface DriverResourcesApiGetDriverWithWorkHoursAndTimeOffsRequest {
  username: string;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

export interface DriverResourcesApiGetDriversRequest {
  alsoInactive?: boolean;
  next?: number;
}

export interface DriverResourcesApiGetDriversWithWorkHoursAndTimeOffsRequest {
  next?: number;
  agreedDeliveryWindowDateRangeStartsAt?: Date;
  agreedDeliveryWindowDateRangeEndsAt?: Date;
}

export interface DriverResourcesApiPatchDriverLoadShipmentRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  patchDriverLoadShipmentsByIdRequestBody: PatchDriverLoadShipmentsByIdRequestBody;
}

export interface DriverResourcesApiPatchDriverLoadShipmentRowsRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  patchDriverLoadShipmentRowsRequestBody: PatchDriverLoadShipmentRowsRequestBody;
}

export interface DriverResourcesApiPatchDriverLoadShipmentsRequest {
  username: string;
  loadId: number;
  singleShipmentPatchBody: Array<SingleShipmentPatchBody>;
}

export interface DriverResourcesApiPatchDriverLoadShipmentsRowsRequest {
  username: string;
  loadId: number;
  patchDriverLoadShipmentsRowsRequestBody: PatchDriverLoadShipmentsRowsRequestBody;
}

export interface DriverResourcesApiPostDriverLoadShipmentPhotosRequest {
  username: string;
  loadId: number;
  shipmentId: number;
  description: string;
  timestamp: Date;
  shipmentRowId?: number;
  photo?: Blob;
}

export interface DriverResourcesApiPostDriverWithWorkHoursAndTimeOffsRequest {
  username: string;
  driverWithWorkHoursAndTimeOffsPostBody: DriverWithWorkHoursAndTimeOffsPostBody;
}

export interface DriverResourcesApiUpdateDriverRequest {
  driverId: number;
  driverPostPutBody: DriverPostPutBody;
}

/**
 *
 */
export class DriverResourcesApi extends runtime.BaseAPI {
  /**
   * Create a Driver.
   */
  async createDriverRaw(
    requestParameters: DriverResourcesApiCreateDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverResponseBody>> {
    if (requestParameters.driverPostPutBody === null || requestParameters.driverPostPutBody === undefined) {
      throw new runtime.RequiredError(
        'driverPostPutBody',
        'Required parameter requestParameters.driverPostPutBody was null or undefined when calling createDriver.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DriverPostPutBodyToJSON(requestParameters.driverPostPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverResponseBodyFromJSON(jsonValue));
  }

  /**
   * Create a Driver.
   */
  async createDriver(
    requestParameters: DriverResourcesApiCreateDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverResponseBody> {
    const response = await this.createDriverRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a photo based on id
   */
  async deleteDriverLoadShipmentPhotosRaw(
    requestParameters: DriverResourcesApiDeleteDriverLoadShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling deleteDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling deleteDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling deleteDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.photoId === null || requestParameters.photoId === undefined) {
      throw new runtime.RequiredError(
        'photoId',
        'Required parameter requestParameters.photoId was null or undefined when calling deleteDriverLoadShipmentPhotos.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/photos/{photo_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'photo_id'}}`, encodeURIComponent(String(requestParameters.photoId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a photo based on id
   */
  async deleteDriverLoadShipmentPhotos(
    requestParameters: DriverResourcesApiDeleteDriverLoadShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.deleteDriverLoadShipmentPhotosRaw(requestParameters, initOverrides);
  }

  /**
   * Get daily overview of Shipments and Loads assigned to a Driver.
   */
  async getDailyDriverOverviewRaw(
    requestParameters: DriverResourcesApiGetDailyDriverOverviewRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverOverviewResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDailyDriverOverview.',
      );
    }

    if (requestParameters.driveDate === null || requestParameters.driveDate === undefined) {
      throw new runtime.RequiredError(
        'driveDate',
        'Required parameter requestParameters.driveDate was null or undefined when calling getDailyDriverOverview.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.driveDate !== undefined) {
      queryParameters['drive_date'] = (requestParameters.driveDate as any).toISOString().substr(0, 10);
    }

    if (requestParameters.workingDays !== undefined) {
      queryParameters['working_days'] = requestParameters.workingDays;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/overview`.replace(
          `{${'username'}}`,
          encodeURIComponent(String(requestParameters.username)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverOverviewResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get daily overview of Shipments and Loads assigned to a Driver.
   */
  async getDailyDriverOverview(
    requestParameters: DriverResourcesApiGetDailyDriverOverviewRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverOverviewResponseBody> {
    const response = await this.getDailyDriverOverviewRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a Driver.
   */
  async getDriverRaw(
    requestParameters: DriverResourcesApiGetDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverResponseBody>> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
      throw new runtime.RequiredError(
        'driverId',
        'Required parameter requestParameters.driverId was null or undefined when calling getDriver.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{driver_id}`.replace(
          `{${'driver_id'}}`,
          encodeURIComponent(String(requestParameters.driverId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a Driver.
   */
  async getDriver(
    requestParameters: DriverResourcesApiGetDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverResponseBody> {
    const response = await this.getDriverRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get a Load assigned to a Driver.
   */
  async getDriverLoadRaw(
    requestParameters: DriverResourcesApiGetDriverLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LoadResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoad.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoad.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LoadResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get a Load assigned to a Driver.
   */
  async getDriverLoad(
    requestParameters: DriverResourcesApiGetDriverLoadRequest,
    initOverrides?: RequestInit,
  ): Promise<LoadResponseBody> {
    const response = await this.getDriverLoadRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Shipment of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentRaw(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipment.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipment.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get Shipment of a Load assigned to a Driver.
   */
  async getDriverLoadShipment(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentResponseBody> {
    const response = await this.getDriverLoadShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get photos of a shipment of a load assigned to a driver
   */
  async getDriverLoadShipmentPhotoRaw(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PhotoFileResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipmentPhoto.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipmentPhoto.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipmentPhoto.',
      );
    }

    if (requestParameters.photoId === null || requestParameters.photoId === undefined) {
      throw new runtime.RequiredError(
        'photoId',
        'Required parameter requestParameters.photoId was null or undefined when calling getDriverLoadShipmentPhoto.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/photos/{photo_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'photo_id'}}`, encodeURIComponent(String(requestParameters.photoId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoFileResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get photos of a shipment of a load assigned to a driver
   */
  async getDriverLoadShipmentPhoto(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentPhotoRequest,
    initOverrides?: RequestInit,
  ): Promise<PhotoFileResponseBody> {
    const response = await this.getDriverLoadShipmentPhotoRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Photos of a Shipment of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentPhotosRaw(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PhotosResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipmentPhotos.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/photos`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotosResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get Photos of a Shipment of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentPhotos(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<PhotosResponseBody> {
    const response = await this.getDriverLoadShipmentPhotosRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentRow of a Shipment, that is part of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentRowRaw(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRowRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentRowResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipmentRow.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipmentRow.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipmentRow.',
      );
    }

    if (requestParameters.shipmentRowId === null || requestParameters.shipmentRowId === undefined) {
      throw new runtime.RequiredError(
        'shipmentRowId',
        'Required parameter requestParameters.shipmentRowId was null or undefined when calling getDriverLoadShipmentRow.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/rows/{shipment_row_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'shipment_row_id'}}`, encodeURIComponent(String(requestParameters.shipmentRowId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentRowResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get ShipmentRow of a Shipment, that is part of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentRow(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRowRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentRowResponseBody> {
    const response = await this.getDriverLoadShipmentRowRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentRowProducts of a ShipmentRow, that is part of a Shipment that is part of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentRowShipmentRowProductsRaw(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRowShipmentRowProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentRowProductsResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipmentRowShipmentRowProducts.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipmentRowShipmentRowProducts.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipmentRowShipmentRowProducts.',
      );
    }

    if (requestParameters.shipmentRowId === null || requestParameters.shipmentRowId === undefined) {
      throw new runtime.RequiredError(
        'shipmentRowId',
        'Required parameter requestParameters.shipmentRowId was null or undefined when calling getDriverLoadShipmentRowShipmentRowProducts.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/rows/{shipment_row_id}/shipment_row_products`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId)))
          .replace(`{${'shipment_row_id'}}`, encodeURIComponent(String(requestParameters.shipmentRowId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentRowProductsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get ShipmentRowProducts of a ShipmentRow, that is part of a Shipment that is part of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentRowShipmentRowProducts(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRowShipmentRowProductsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentRowProductsResponseBody> {
    const response = await this.getDriverLoadShipmentRowShipmentRowProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get ShipmentRows of a Shipment, that is part of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentRowsRaw(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentRowsResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipmentRows.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipmentRows.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling getDriverLoadShipmentRows.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/rows`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentRowsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get ShipmentRows of a Shipment, that is part of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentRows(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentRowsResponseBody> {
    const response = await this.getDriverLoadShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Shipments of a Load assigned to a Driver.
   */
  async getDriverLoadShipmentsRaw(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverShipmentsResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoadShipments.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling getDriverLoadShipments.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverShipmentsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get Shipments of a Load assigned to a Driver.
   */
  async getDriverLoadShipments(
    requestParameters: DriverResourcesApiGetDriverLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverShipmentsResponseBody> {
    const response = await this.getDriverLoadShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Loads assigned to a Driver.
   */
  async getDriverLoadsRaw(
    requestParameters: DriverResourcesApiGetDriverLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LoadsResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverLoads.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.driveDate !== undefined) {
      queryParameters['drive_date'] = (requestParameters.driveDate as any).toISOString().substr(0, 10);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads`.replace(
          `{${'username'}}`,
          encodeURIComponent(String(requestParameters.username)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => LoadsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get Loads assigned to a Driver.
   */
  async getDriverLoads(
    requestParameters: DriverResourcesApiGetDriverLoadsRequest,
    initOverrides?: RequestInit,
  ): Promise<LoadsResponseBody> {
    const response = await this.getDriverLoadsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get work hours and time offs of given driver
   */
  async getDriverWithWorkHoursAndTimeOffsRaw(
    requestParameters: DriverResourcesApiGetDriverWithWorkHoursAndTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverWithWorkHoursAndTimeOffs>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling getDriverWithWorkHoursAndTimeOffs.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/work_hours_and_time_offs`.replace(
          `{${'username'}}`,
          encodeURIComponent(String(requestParameters.username)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverWithWorkHoursAndTimeOffsFromJSON(jsonValue));
  }

  /**
   * Get work hours and time offs of given driver
   */
  async getDriverWithWorkHoursAndTimeOffs(
    requestParameters: DriverResourcesApiGetDriverWithWorkHoursAndTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverWithWorkHoursAndTimeOffs> {
    const response = await this.getDriverWithWorkHoursAndTimeOffsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all Drivers.
   */
  async getDriversRaw(
    requestParameters: DriverResourcesApiGetDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriversResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.alsoInactive !== undefined) {
      queryParameters['also_inactive'] = requestParameters.alsoInactive;
    }

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriversResponseBodyFromJSON(jsonValue));
  }

  /**
   * Get all Drivers.
   */
  async getDrivers(
    requestParameters: DriverResourcesApiGetDriversRequest,
    initOverrides?: RequestInit,
  ): Promise<DriversResponseBody> {
    const response = await this.getDriversRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all active drivers and their work hours and time offs.
   */
  async getDriversWithWorkHoursAndTimeOffsRaw(
    requestParameters: DriverResourcesApiGetDriversWithWorkHoursAndTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriversWithWorkHoursAndTimeOffsResponseBody>> {
    const queryParameters: any = {};

    if (requestParameters.next !== undefined) {
      queryParameters['next'] = requestParameters.next;
    }

    if (requestParameters.agreedDeliveryWindowDateRangeStartsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_starts_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeStartsAt as any
      ).toISOString();
    }

    if (requestParameters.agreedDeliveryWindowDateRangeEndsAt !== undefined) {
      queryParameters['agreed_delivery_window_date_range_ends_at'] = (
        requestParameters.agreedDeliveryWindowDateRangeEndsAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/drivers/work_hours_and_time_offs`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DriversWithWorkHoursAndTimeOffsResponseBodyFromJSON(jsonValue),
    );
  }

  /**
   * Get all active drivers and their work hours and time offs.
   */
  async getDriversWithWorkHoursAndTimeOffs(
    requestParameters: DriverResourcesApiGetDriversWithWorkHoursAndTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<DriversWithWorkHoursAndTimeOffsResponseBody> {
    const response = await this.getDriversWithWorkHoursAndTimeOffsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Patch Shipment of a Load assigned to a Driver.
   */
  async patchDriverLoadShipmentRaw(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling patchDriverLoadShipment.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling patchDriverLoadShipment.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling patchDriverLoadShipment.',
      );
    }

    if (
      requestParameters.patchDriverLoadShipmentsByIdRequestBody === null ||
      requestParameters.patchDriverLoadShipmentsByIdRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'patchDriverLoadShipmentsByIdRequestBody',
        'Required parameter requestParameters.patchDriverLoadShipmentsByIdRequestBody was null or undefined when calling patchDriverLoadShipment.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchDriverLoadShipmentsByIdRequestBodyToJSON(requestParameters.patchDriverLoadShipmentsByIdRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentResponseBodyFromJSON(jsonValue));
  }

  /**
   * Patch Shipment of a Load assigned to a Driver.
   */
  async patchDriverLoadShipment(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentResponseBody> {
    const response = await this.patchDriverLoadShipmentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Partially update ShipmentRows of a Shipment, that is part of a Load assigned to a Driver.
   */
  async patchDriverLoadShipmentRowsRaw(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentRowsResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling patchDriverLoadShipmentRows.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling patchDriverLoadShipmentRows.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling patchDriverLoadShipmentRows.',
      );
    }

    if (
      requestParameters.patchDriverLoadShipmentRowsRequestBody === null ||
      requestParameters.patchDriverLoadShipmentRowsRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'patchDriverLoadShipmentRowsRequestBody',
        'Required parameter requestParameters.patchDriverLoadShipmentRowsRequestBody was null or undefined when calling patchDriverLoadShipmentRows.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/rows`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchDriverLoadShipmentRowsRequestBodyToJSON(requestParameters.patchDriverLoadShipmentRowsRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentRowsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Partially update ShipmentRows of a Shipment, that is part of a Load assigned to a Driver.
   */
  async patchDriverLoadShipmentRows(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentRowsResponseBody> {
    const response = await this.patchDriverLoadShipmentRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Patch a batch of shipments of a Load assigned to a Driver.
   */
  async patchDriverLoadShipmentsRaw(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentsPatchResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling patchDriverLoadShipments.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling patchDriverLoadShipments.',
      );
    }

    if (requestParameters.singleShipmentPatchBody === null || requestParameters.singleShipmentPatchBody === undefined) {
      throw new runtime.RequiredError(
        'singleShipmentPatchBody',
        'Required parameter requestParameters.singleShipmentPatchBody was null or undefined when calling patchDriverLoadShipments.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.singleShipmentPatchBody.map(SingleShipmentPatchBodyToJSON),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentsPatchResponseBodyFromJSON(jsonValue));
  }

  /**
   * Patch a batch of shipments of a Load assigned to a Driver.
   */
  async patchDriverLoadShipments(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentsPatchResponseBody> {
    const response = await this.patchDriverLoadShipmentsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Partially update ShipmentRows of shipments in a load
   */
  async patchDriverLoadShipmentsRowsRaw(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentsRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ShipmentsRowsResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling patchDriverLoadShipmentsRows.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling patchDriverLoadShipmentsRows.',
      );
    }

    if (
      requestParameters.patchDriverLoadShipmentsRowsRequestBody === null ||
      requestParameters.patchDriverLoadShipmentsRowsRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        'patchDriverLoadShipmentsRowsRequestBody',
        'Required parameter requestParameters.patchDriverLoadShipmentsRowsRequestBody was null or undefined when calling patchDriverLoadShipmentsRows.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/rows`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId))),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchDriverLoadShipmentsRowsRequestBodyToJSON(requestParameters.patchDriverLoadShipmentsRowsRequestBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentsRowsResponseBodyFromJSON(jsonValue));
  }

  /**
   * Partially update ShipmentRows of shipments in a load
   */
  async patchDriverLoadShipmentsRows(
    requestParameters: DriverResourcesApiPatchDriverLoadShipmentsRowsRequest,
    initOverrides?: RequestInit,
  ): Promise<ShipmentsRowsResponseBody> {
    const response = await this.patchDriverLoadShipmentsRowsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Post photo related to a shipment
   */
  async postDriverLoadShipmentPhotosRaw(
    requestParameters: DriverResourcesApiPostDriverLoadShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PhotoResponseBody>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling postDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.loadId === null || requestParameters.loadId === undefined) {
      throw new runtime.RequiredError(
        'loadId',
        'Required parameter requestParameters.loadId was null or undefined when calling postDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.shipmentId === null || requestParameters.shipmentId === undefined) {
      throw new runtime.RequiredError(
        'shipmentId',
        'Required parameter requestParameters.shipmentId was null or undefined when calling postDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.description === null || requestParameters.description === undefined) {
      throw new runtime.RequiredError(
        'description',
        'Required parameter requestParameters.description was null or undefined when calling postDriverLoadShipmentPhotos.',
      );
    }

    if (requestParameters.timestamp === null || requestParameters.timestamp === undefined) {
      throw new runtime.RequiredError(
        'timestamp',
        'Required parameter requestParameters.timestamp was null or undefined when calling postDriverLoadShipmentPhotos.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.description !== undefined) {
      queryParameters['description'] = requestParameters.description;
    }

    if (requestParameters.timestamp !== undefined) {
      queryParameters['timestamp'] = (requestParameters.timestamp as any).toISOString();
    }

    if (requestParameters.shipmentRowId !== undefined) {
      queryParameters['shipment_row_id'] = requestParameters.shipmentRowId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.photo !== undefined) {
      formParams.append('photo', requestParameters.photo as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/loads/{load_id}/shipments/{shipment_id}/photos`
          .replace(`{${'username'}}`, encodeURIComponent(String(requestParameters.username)))
          .replace(`{${'load_id'}}`, encodeURIComponent(String(requestParameters.loadId)))
          .replace(`{${'shipment_id'}}`, encodeURIComponent(String(requestParameters.shipmentId))),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhotoResponseBodyFromJSON(jsonValue));
  }

  /**
   * Post photo related to a shipment
   */
  async postDriverLoadShipmentPhotos(
    requestParameters: DriverResourcesApiPostDriverLoadShipmentPhotosRequest,
    initOverrides?: RequestInit,
  ): Promise<PhotoResponseBody> {
    const response = await this.postDriverLoadShipmentPhotosRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create new work hour for driver
   */
  async postDriverWithWorkHoursAndTimeOffsRaw(
    requestParameters: DriverResourcesApiPostDriverWithWorkHoursAndTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<WorkHour>> {
    if (requestParameters.username === null || requestParameters.username === undefined) {
      throw new runtime.RequiredError(
        'username',
        'Required parameter requestParameters.username was null or undefined when calling postDriverWithWorkHoursAndTimeOffs.',
      );
    }

    if (
      requestParameters.driverWithWorkHoursAndTimeOffsPostBody === null ||
      requestParameters.driverWithWorkHoursAndTimeOffsPostBody === undefined
    ) {
      throw new runtime.RequiredError(
        'driverWithWorkHoursAndTimeOffsPostBody',
        'Required parameter requestParameters.driverWithWorkHoursAndTimeOffsPostBody was null or undefined when calling postDriverWithWorkHoursAndTimeOffs.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{username}/work_hours_and_time_offs`.replace(
          `{${'username'}}`,
          encodeURIComponent(String(requestParameters.username)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DriverWithWorkHoursAndTimeOffsPostBodyToJSON(requestParameters.driverWithWorkHoursAndTimeOffsPostBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WorkHourFromJSON(jsonValue));
  }

  /**
   * Create new work hour for driver
   */
  async postDriverWithWorkHoursAndTimeOffs(
    requestParameters: DriverResourcesApiPostDriverWithWorkHoursAndTimeOffsRequest,
    initOverrides?: RequestInit,
  ): Promise<WorkHour> {
    const response = await this.postDriverWithWorkHoursAndTimeOffsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a driver by id.
   */
  async updateDriverRaw(
    requestParameters: DriverResourcesApiUpdateDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DriverResponseBody1>> {
    if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
      throw new runtime.RequiredError(
        'driverId',
        'Required parameter requestParameters.driverId was null or undefined when calling updateDriver.',
      );
    }

    if (requestParameters.driverPostPutBody === null || requestParameters.driverPostPutBody === undefined) {
      throw new runtime.RequiredError(
        'driverPostPutBody',
        'Required parameter requestParameters.driverPostPutBody was null or undefined when calling updateDriver.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/drivers/{driver_id}`.replace(
          `{${'driver_id'}}`,
          encodeURIComponent(String(requestParameters.driverId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: DriverPostPutBodyToJSON(requestParameters.driverPostPutBody),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DriverResponseBody1FromJSON(jsonValue));
  }

  /**
   * Update a driver by id.
   */
  async updateDriver(
    requestParameters: DriverResourcesApiUpdateDriverRequest,
    initOverrides?: RequestInit,
  ): Promise<DriverResponseBody1> {
    const response = await this.updateDriverRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
