import {
  Input,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  styled,
  Checkbox,
  Tooltip,
  Typography,
  CircularProgress,
  SelectChangeEvent,
  FormControlLabel,
  FormHelperText,
  List,
  TextField,
  FormControl,
  DialogActions,
  Box,
} from '@mui/material';
import React, { BaseSyntheticEvent, Dispatch, SetStateAction, useState } from 'react';
import { Action, debouncedValidateFieldsDispatch, FieldName, State, updateFieldValue } from '../shipment.state';
import {
  api,
  Office,
  Organization,
  PricingModelWithAreas,
  Shipment,
  ShipmentPutBodyStateEnum,
  User,
} from '../../../api';
import { OrganizationPicker } from '../../../components/OrganizationPicker';
import { FieldSet } from '../../../components/StyledComponents/FieldSet';
import {
  formatDate,
  formatDistancePricingBasis,
  formatFloat,
  formatPrice,
  formatPricingUnit,
} from '../../../formatters';
import { Link as RouterLink } from 'react-router-dom';
import { ImmutableTextField } from '../../../components/ImmutableTextField';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { load } from '../EditShipment';
import { PricingModelPicker } from '../../../components/PricingModelPicker';
import { FullWidthButton } from '../../../components/StyledComponents/FullWidthButton';
import { getJSDateOrNull, StandardDatePicker } from '../../../components/DateAndTimePickers/StandardDatePicker';
import { StandardTimePicker } from '../../../components/DateAndTimePickers/StandardTimePicker';
import { EmptySelectMenuItem } from '../../../components/EmptySelectMenuItem';
import { PickersActionBar, PickersActionBarProps, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { RouteLink } from '../../../components/map';
import {
  canAccessCoordination,
  canAccessCustomerPricing,
  canAccessPricing,
  isHostOrganizationUser,
} from '../../../utils';
import theme from '../../../theme';

const StyledFormControl = styled(FormControl)(() => ({
  width: '7rem',
}));

const StyledDatePickerHelperText = styled('span')(() => ({
  color: 'gray',
  maxWidth: '18.5rem',
  fontSize: '0.75rem',
}));

interface InputProps extends React.HTMLAttributes<HTMLElement> {
  state: State;
  disabled?: boolean;
}

interface DispatchableInputProps extends InputProps {
  dispatch: Dispatch<Action>;
}

export const WeightKgInput: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StyledFormControl
      required={state.fields.weight_kg.required}
      error={state.fields.weight_kg.hasError || state.fields.sizes.hasError}
    >
      <InputLabel htmlFor="weight_kg">Kokonaispaino</InputLabel>
      <Input
        disabled={disabled}
        id="weight_kg"
        name="weight_kg"
        type="number"
        startAdornment={<InputAdornment position="start">kg</InputAdornment>}
        value={state.fields.weight_kg.value}
        inputProps={{ min: 0 }}
        //disable changing value with mouse scroll
        onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
        onChange={(event) =>
          updateFieldValue('weight_kg', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.weight_kg.feedback ? (
        <FormHelperText sx={{ color: theme.palette.error.main }}>{state.fields.weight_kg.feedback}</FormHelperText>
      ) : null}
    </StyledFormControl>
  );
};
export const ChargeableWeightInput: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StyledFormControl error={state.fields.chargeable_weight_kg.hasError}>
      <InputLabel htmlFor="chargeable_weight_kg">Rahdituspaino</InputLabel>
      <Input
        disabled={disabled}
        id="chargeable_weight_kg"
        name="chargeable_weight_kg"
        type="number"
        startAdornment={<InputAdornment position="start">kg</InputAdornment>}
        value={state.fields.chargeable_weight_kg.value}
        inputProps={{ min: 0 }}
        onChange={(event) =>
          updateFieldValue(
            'chargeable_weight_kg',
            event.target.value !== '' ? parseFloat(event.target.value) : '',
            dispatch,
          )
        }
      />
    </StyledFormControl>
  );
};

export const VolumeM3Input: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StyledFormControl
      required={state.fields.volume_m3.required}
      error={state.fields.volume_m3.hasError || state.fields.sizes.hasError}
    >
      <InputLabel htmlFor="volume_m3">Kokonaistilavuus</InputLabel>
      <Input
        disabled={disabled}
        id="volume_m3"
        name="volume_m3"
        type="number"
        startAdornment={<InputAdornment position="start">m&sup3;</InputAdornment>}
        value={state.fields.volume_m3.value}
        inputProps={{ min: 0, max: 999 }}
        //disable changing value with mouse scroll
        onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
        onChange={(event) =>
          updateFieldValue('volume_m3', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.volume_m3.feedback ? (
        <FormHelperText sx={{ color: theme.palette.error.main }}>{state.fields.volume_m3.feedback}</FormHelperText>
      ) : null}
    </StyledFormControl>
  );
};

export const LengthLdmInput: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <StyledFormControl
      required={state.fields.length_ldm.required}
      error={state.fields.length_ldm.hasError || state.fields.sizes.hasError}
    >
      <InputLabel htmlFor="length_ldm">Lavametrit</InputLabel>
      <Input
        disabled={disabled}
        id="length_ldm"
        name="length_ldm"
        type="number"
        startAdornment={<InputAdornment position="start">lvm</InputAdornment>}
        value={state.fields.length_ldm.value}
        //disable changing value with mouse scroll
        onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
        onChange={(event) =>
          updateFieldValue('length_ldm', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
        inputProps={{ min: 0, max: 99 }}
      />
      {state.fields.length_ldm.feedback ? (
        <FormHelperText sx={{ color: theme.palette.error.main }}>{state.fields.length_ldm.feedback}</FormHelperText>
      ) : null}
    </StyledFormControl>
  );
};

const DeliveryTimePickerContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  '> *': {
    flex: 1,
  },
});

export const DeliveryTimePicker: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <DeliveryTimePickerContainer>
      <StandardTimePicker
        sx={{ maxWidth: '12rem' }}
        disabled={disabled}
        label="Aikaisintaan"
        value={state.fields.delivery_time_window_start.value}
        slotProps={{
          textField: {
            required: state.fields.delivery_time_window_start.required,
            id: 'delivery_time_window_start',
            name: 'delivery_time_window_start',
          },
        }}
        onChange={(time) => updateFieldValue('delivery_time_window_start', getJSDateOrNull(time), dispatch)}
      />
      <StandardTimePicker
        disabled={disabled}
        ampm={false}
        label="Viimeistään"
        value={state.fields.delivery_time_window_end.value}
        slotProps={{
          textField: {
            required: state.fields.delivery_time_window_end.required,
            id: 'delivery_time_window_end',
            name: 'delivery_time_window_end',
            error: state.fields.delivery_time_window_end.hasError,
            helperText: state.fields.delivery_time_window_end.feedback,
          },
        }}
        onChange={(time) => updateFieldValue('delivery_time_window_end', getJSDateOrNull(time), dispatch)}
      />
    </DeliveryTimePickerContainer>
  );
};

function PickersDayWithDoubleClick(props: PickersDayProps<DateTime>) {
  return <PickersDay {...props} />;
}

function DatePickerHelperText(props: PickersActionBarProps) {
  return (
    <DialogActions className={props.className} sx={{ paddingTop: 0 }}>
      <PickersActionBar {...props} />
      <StyledDatePickerHelperText>
        Tuplaklikkaamalla päivämäärää saat valittua kerralla nouto- ja toimituspäivän.
      </StyledDatePickerHelperText>
    </DialogActions>
  );
}

export const DeliveryDatePicker: React.FC<DispatchableInputProps & WithUser> = ({
  state,
  dispatch,
  disabled,
  currentUser,
}) => {
  return (
    <StandardDatePicker
      disabled={disabled}
      label="Toimituspäivä"
      minDate={canAccessCoordination(currentUser) ? undefined : DateTime.local().startOf('day')}
      value={state.fields.delivery_date.value}
      slots={{
        actionBar: DatePickerHelperText,
        day: (props) =>
          PickersDayWithDoubleClick({
            ...props,
            onDoubleClick: () => updateFieldValue('pickup_date', state.fields.delivery_date.value ?? null, dispatch),
          }),
      }}
      slotProps={{
        textField: {
          required: state.fields.delivery_date.required,
          id: 'delivery_date',
          name: 'delivery_date',
          error: state.fields.delivery_date.hasError,
          helperText: state.fields.delivery_date.feedback,
        },
      }}
      onChange={(date) => updateFieldValue('delivery_date', getJSDateOrNull(date), dispatch)}
    />
  );
};

export const Notes: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      multiline={true}
      disabled={disabled}
      required={state.fields.note.required}
      name="note"
      error={state.fields.note.hasError}
      helperText={state.fields.note.feedback}
      label="Lisätietoja"
      value={state.fields.note.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

interface WithOrganization {
  organization?: Organization;
}

export const DeliveryPhoneNumber: React.FC<DispatchableInputProps & WithOrganization> = ({
  state,
  dispatch,
  disabled,
  organization,
}) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.delivery_phone_number.required}
      id="delivery_phone_number"
      name="delivery_phone_number"
      error={state.fields.delivery_phone_number.hasError}
      helperText={state.fields.delivery_phone_number.feedback}
      label={
        (organization?.id === state.fields.organization_id.value && organization?.send_delivery_notification_sms) ||
        state.organizations.find((org) => org.id === state.fields.organization_id.value)?.send_delivery_notification_sms
          ? 'Puhelinnumero / Avisointinumero'
          : 'Puhelinnumero'
      }
      value={state.fields.delivery_phone_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const DeliveryPhoneNumberSecondary: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.delivery_phone_number_secondary.required}
      id="delivery_phone_number_secondary"
      name="delivery_phone_number_secondary"
      error={state.fields.delivery_phone_number_secondary.hasError}
      helperText={state.fields.delivery_phone_number_secondary.feedback}
      label="Toinen puhelinnumero"
      value={state.fields.delivery_phone_number_secondary.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const DeliveryCity: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.delivery_city.required}
      name="delivery_city"
      error={state.fields.delivery_city.hasError}
      helperText={state.fields.delivery_city.feedback}
      label="Kaupunki"
      value={state.fields.delivery_city.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const DeliveryPostalCode: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.delivery_postal_code.required}
      name="delivery_postal_code"
      error={state.fields.delivery_postal_code.hasError}
      helperText={state.fields.delivery_postal_code.feedback}
      label="Postinumero"
      value={state.fields.delivery_postal_code.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const DeliveryAddress: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.delivery_address.required}
      name="delivery_address"
      error={state.fields.delivery_address.hasError}
      helperText={state.fields.delivery_address.feedback}
      label="Osoite"
      value={state.fields.delivery_address.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const DeliveryName: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.delivery_name.required}
      name="delivery_name"
      error={state.fields.delivery_name.hasError}
      helperText={state.fields.delivery_name.feedback}
      label="Nimi"
      value={state.fields.delivery_name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export interface StateSelectProps extends DispatchableInputProps {
  children: React.ReactElement[];
}

export const StateSelect: React.FC<StateSelectProps> = ({ state, dispatch, disabled, children }) => {
  return (
    <FormControl>
      <InputLabel shrink id="state_label">
        Tila
      </InputLabel>
      <Select
        id="state"
        labelId="state_label"
        disabled={disabled}
        value={state.fields.state.value}
        onChange={(event) => updateFieldValue('state', event.target.value as ShipmentPutBodyStateEnum, dispatch)}
      >
        {children}
      </Select>
    </FormControl>
  );
};

const PickupTimePickerContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
});

export const PickupTimePicker: React.FC<DispatchableInputProps & WithUser> = ({
  state,
  dispatch,
  disabled,
  currentUser,
}) => {
  return (
    <PickupTimePickerContainer>
      <StandardDatePicker
        sx={{ flexGrow: 1 }}
        disabled={disabled}
        label="Noutopäivä"
        minDate={canAccessCoordination(currentUser) ? undefined : DateTime.local().startOf('day')}
        value={state.fields.pickup_date.value}
        slots={{
          actionBar: DatePickerHelperText,
          day: (props) =>
            PickersDayWithDoubleClick({
              ...props,
              onDoubleClick: () => updateFieldValue('delivery_date', state.fields.pickup_date.value ?? null, dispatch),
            }),
        }}
        slotProps={{
          textField: {
            required: state.fields.pickup_date.required,
            id: 'pickup_date',
            name: 'pickup_date',
            error: state.fields.pickup_date.hasError,
            helperText: state.fields.pickup_date.feedback,
          },
        }}
        onChange={(date) => {
          updateFieldValue('pickup_date', getJSDateOrNull(date), dispatch);
        }}
      />
      <StandardTimePicker
        sx={{ flexGrow: 0, width: '7rem' }}
        disabled={disabled}
        ampm={false}
        label="Noutoaika"
        value={state.fields.pickup_time.value}
        slotProps={{
          textField: {
            required: state.fields.pickup_time.required,
            id: 'pickup_time',
            name: 'pickup_time',
            error: state.fields.pickup_time.hasError,
            helperText: state.fields.pickup_time.feedback,
          },
        }}
        onChange={(time) => updateFieldValue('pickup_time', getJSDateOrNull(time), dispatch)}
      />
    </PickupTimePickerContainer>
  );
};

export const PickupPhoneNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.pickup_phone_number.required}
      id="pickup_phone_number"
      name="pickup_phone_number"
      error={state.fields.pickup_phone_number.hasError}
      helperText={state.fields.pickup_phone_number.feedback}
      label="Puhelinnumero"
      value={state.fields.pickup_phone_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const PickupCity: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.pickup_city.required}
      name="pickup_city"
      error={state.fields.pickup_city.hasError}
      helperText={state.fields.pickup_city.feedback}
      label="Kaupunki"
      value={state.fields.pickup_city.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const PickupPostalCode: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.pickup_postal_code.required}
      name="pickup_postal_code"
      error={state.fields.pickup_postal_code.hasError}
      helperText={state.fields.pickup_postal_code.feedback}
      label="Postinumero"
      value={state.fields.pickup_postal_code.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const PickupAddress: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.pickup_address.required}
      name="pickup_address"
      error={state.fields.pickup_address.hasError}
      helperText={state.fields.pickup_address.feedback}
      label="Osoite"
      value={state.fields.pickup_address.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const PickupName: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.pickup_name.required}
      name="pickup_name"
      error={state.fields.pickup_name.hasError}
      helperText={state.fields.pickup_name.feedback}
      label="Nimi"
      value={state.fields.pickup_name.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const JobNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.job_number.required}
      name="job_number"
      error={state.fields.job_number.hasError}
      helperText={state.fields.job_number.feedback}
      label="Työnumero"
      value={state.fields.job_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const ReferenceNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.reference_number.required}
      name="reference_number"
      error={state.fields.reference_number.hasError}
      helperText={state.fields.reference_number.feedback}
      label="Asiakasviite"
      value={state.fields.reference_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const BillingReferenceNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.billing_reference_number.required}
      name="billing_reference_number"
      error={state.fields.billing_reference_number.hasError}
      helperText={state.fields.billing_reference_number.feedback}
      label="Laskutusviite"
      value={state.fields.billing_reference_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const CustomerDistributionArea: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.customer_distribution_area.required}
      name="customer_distribution_area"
      error={state.fields.customer_distribution_area.hasError}
      helperText={state.fields.customer_distribution_area.feedback}
      label="Jakelualue"
      value={state.fields.customer_distribution_area.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

interface WithUser {
  currentUser?: User;
}

export const OrganizationField: React.FC<DispatchableInputProps & WithUser> = ({
  state,
  dispatch,
  disabled,
  currentUser,
}) => {
  return currentUser?.is_multi_organization || currentUser?.is_superuser ? (
    <OrganizationPicker
      className="organization-field"
      disabled={disabled}
      organizations={state.organizations}
      value={state.organizations.find((org) => org.id === state.fields.organization_id.value) ?? null}
      onChange={(organization) => {
        updateFieldValue('organization_id', organization?.id ?? null, dispatch);
        if (organization) {
          Promise.all([
            api.organizationOffices.getOrganizationOffices({ organizationId: organization?.id }),
            api.organizationAdditionalServices.getOrganizationAdditionalServices({ organizationId: organization?.id }),
          ]).then((responses) => {
            const [organizationOfficesResponse, organizationAdditionalServicesResponse] = responses;
            updateFieldValue('billing_office_organization_specific_id', '', dispatch);
            updateFieldValue('delivery_office_organization_specific_id', '', dispatch);
            updateFieldValue('pickup_office_organization_specific_id', '', dispatch);
            dispatch({
              type: 'CHANGE_ORGANIZATION',
              payload: {
                organizationOffices: organizationOfficesResponse.data,
                organizationAdditionalServices: organizationAdditionalServicesResponse.data,
              },
            });
            dispatch({
              type: 'UPDATE_PRICING_MODEL_AND_CUSTOM_CATEGORY',
            });
          });
        }
      }}
    />
  ) : null;
};

export const OrdererPhoneNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.orderer_phone_number.required}
      id="orderer_phone_number"
      name="orderer_phone_number"
      error={state.fields.orderer_phone_number.hasError}
      helperText={state.fields.orderer_phone_number.feedback}
      label="Puhelinnumero"
      value={state.fields.orderer_phone_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

export const Orderer: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      required={state.fields.orderer.required}
      name="orderer"
      error={state.fields.orderer.hasError}
      helperText={state.fields.orderer.feedback}
      label="Nimi"
      value={state.fields.orderer.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const updatePickupAddress = (office: Office, dispatch: React.Dispatch<Action>) => {
  updateFieldValue('pickup_name', office.name, dispatch);
  updateFieldValue('pickup_address', office.address, dispatch);
  updateFieldValue('pickup_postal_code', office.postal_code, dispatch);
  updateFieldValue('pickup_city', office.city, dispatch);
  updateFieldValue('pickup_phone_number', office.phone_number, dispatch);
};

const updateDeliveryAddress = (office: Office, dispatch: React.Dispatch<Action>) => {
  updateFieldValue('delivery_name', office.name, dispatch);
  updateFieldValue('delivery_address', office.address, dispatch);
  updateFieldValue('delivery_postal_code', office.postal_code, dispatch);
  updateFieldValue('delivery_city', office.city, dispatch);
  updateFieldValue('delivery_phone_number', office.phone_number, dispatch);
};

type addressType = 'pickup' | 'delivery';

const updateOfficeAndAddress = (
  event: SelectChangeEvent<string | null>,
  addressType: addressType,
  state: State,
  dispatch: React.Dispatch<Action>,
) => {
  const organizationSpecificId = event.target.value as string;
  updateFieldValue(event.target.name as FieldName, organizationSpecificId, dispatch);

  const office = state.organizationOffices.find(
    (office) =>
      office.organization_specific_office_id == organizationSpecificId &&
      office.organization_id == state.fields.organization_id.value,
  );
  if (office) {
    if (addressType == 'pickup') {
      updatePickupAddress(office, dispatch);
    } else {
      updateDeliveryAddress(office, dispatch);
    }
  }
};

const getAvailableOfficeMenuItems = (state: State) => {
  return state.organizationOffices
    .filter((office) => office.billing_enabled)
    .map((office, index) => (
      <MenuItem key={index + 1} value={office.organization_specific_office_id}>
        {office.name}
      </MenuItem>
    ));
};

export const PickupOffice: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="pickup_office_organization_specific_id-label">Toimipaikka</InputLabel>
      <Select
        disabled={disabled}
        id="pickup_office_organization_specific_id"
        name="pickup_office_organization_specific_id"
        error={state.fields.pickup_office_organization_specific_id.hasError}
        label="Toimipaikka"
        value={state.fields.pickup_office_organization_specific_id.value}
        required={false}
        onChange={(event) => {
          updateOfficeAndAddress(event, 'pickup', state, dispatch);
        }}
      >
        <EmptySelectMenuItem value="" />
        {getAvailableOfficeMenuItems(state)}
      </Select>
    </FormControl>
  );
};

const getAvailableCustomPricingCategoryMenuItems = (
  customPricingCategories: PricingModelWithAreas['custom_pricing_categories'],
) => {
  return customPricingCategories.map((customPricingCategory, index) => (
    <MenuItem key={index + 1} value={customPricingCategory.id}>
      {customPricingCategory.name}
    </MenuItem>
  ));
};

export const CustomPricingCategory: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  const customPricingCategories =
    state.pricingModels.find((pricingModel) => pricingModel.id === state.fields.pricing_model.value)
      ?.custom_pricing_categories ?? [];
  return (
    <FormControl fullWidth>
      <InputLabel id="custom_pricing_category_id-label">Hinnoittelukategoria</InputLabel>
      <Select
        disabled={disabled}
        id="custom_pricing_category_id"
        name="custom_pricing_category_id"
        error={state.fields.custom_pricing_category_id.hasError}
        label="Hinnoittelukategoria"
        value={state.fields.custom_pricing_category_id.value}
        displayEmpty={true}
        required={false}
        onChange={(event) => {
          updateFieldValue(event.target.name as FieldName, event.target.value as string, dispatch);
        }}
      >
        {getAvailableCustomPricingCategoryMenuItems(customPricingCategories)}
      </Select>
    </FormControl>
  );
};

export const DeliveryOffice: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="delivery_office_organization_specific_id-label">Toimipaikka</InputLabel>
      <Select
        disabled={disabled}
        id="delivery_office_organization_specific_id"
        name="delivery_office_organization_specific_id"
        error={state.fields.delivery_office_organization_specific_id.hasError}
        label="Toimipaikka"
        value={state.fields.delivery_office_organization_specific_id.value}
        required={false}
        onChange={(event) => {
          updateOfficeAndAddress(event, 'delivery', state, dispatch);
        }}
      >
        <EmptySelectMenuItem value="" />
        {getAvailableOfficeMenuItems(state)}
      </Select>
    </FormControl>
  );
};

export const BillingOffice: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="billing_office_organization_specific_id-label">Kustannuspaikka</InputLabel>
      <Select
        disabled={disabled}
        id="billing_office_organization_specific_id"
        name="billing_office_organization_specific_id"
        error={state.fields.billing_office_organization_specific_id.hasError}
        label="Kustannuspaikka"
        value={state.fields.billing_office_organization_specific_id.value}
        required={false}
        onChange={async (event) => {
          updateFieldValue(event.target.name as FieldName, event.target.value as string, dispatch);
          dispatch({
            type: 'UPDATE_PRICING_MODEL_AND_CUSTOM_CATEGORY',
          });
        }}
      >
        <EmptySelectMenuItem value="" />
        {getAvailableOfficeMenuItems(state)}
      </Select>
    </FormControl>
  );
};

export const AdditionalHourlyPricingCheckbox: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <Tooltip title="Tuntityön hinta lisätään erikseen muun hinnan päälle">
      <FormControlLabel
        control={
          <Checkbox
            name="has_additional_hourly_pricing"
            className="has_additional_hourly_pricing_checkbox"
            checked={state.fields.has_additional_hourly_pricing.value || false}
            disabled={disabled}
            onChange={(event) =>
              updateFieldValue(
                event.target.name as FieldName,
                !state.fields.has_additional_hourly_pricing.value,
                dispatch,
              )
            }
          />
        }
        label="Tuntilisä"
      />
    </Tooltip>
  );
};

const HasDistanceBeenFixedCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="has_distance_been_fixed"
          name="has_distance_been_fixed"
          checked={state.fields.has_distance_been_fixed.value || false}
          onChange={(event) => {
            updateFieldValue(event.target.name as FieldName, !state.fields.has_distance_been_fixed.value, dispatch);
            if (state.fields.has_distance_been_fixed.value === false) {
              updateFieldValue('legacy_etaisyys_field', state.originalShipment?.legacy_etaisyys_field ?? '', dispatch);
            }
          }}
        />
      }
      label="Korjattu etäisyys"
    />
  );
};

interface IsEditShipment {
  isEditShipment: boolean;
}

export const LegacyEtaisyysField: React.FC<DispatchableInputProps & IsEditShipment> = ({
  state,
  dispatch,
  disabled,
  isEditShipment,
}) => {
  return (
    <>
      <PriceContainer>
        <FormControl className={'legacy_etaisyys_field'} error={state.fields.legacy_etaisyys_field.hasError}>
          <InputLabel htmlFor="legacy_etaisyys_field">Etäisyys</InputLabel>
          <Input
            disabled={!state.fields.has_distance_been_fixed.value || disabled}
            id="legacy_etaisyys_field"
            name="legacy_etaisyys_field"
            type="number"
            startAdornment={<InputAdornment position="start">km</InputAdornment>}
            value={state.fields.legacy_etaisyys_field.value}
            onChange={(event) => {
              updateFieldValue(
                'legacy_etaisyys_field',
                event.target.value !== '' ? parseFloat(event.target.value) : '',
                dispatch,
              );
            }}
            inputProps={{ min: 0, max: 2000 }}
          />
          {state.fields.legacy_etaisyys_field.feedback ? (
            <FormHelperText>{state.fields.legacy_etaisyys_field.feedback}</FormHelperText>
          ) : null}
        </FormControl>
        {isEditShipment ? <HasDistanceBeenFixedCheckbox state={state} dispatch={dispatch} /> : null}
        {isEditShipment ? (
          <RouteLink
            address1={state.fields.pickup_address.value}
            postalCode1={state.fields.pickup_postal_code.value}
            city1={state.fields.pickup_city.value}
            address2={state.fields.delivery_address.value}
            postalCode2={state.fields.delivery_postal_code.value}
            city2={state.fields.delivery_city.value}
          />
        ) : null}
      </PriceContainer>
    </>
  );
};

export const AdditionalServices: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <List>
      {state.additionalServiceFields
        .sort((a, b) => (a.additionalService.name ?? '').localeCompare(b.additionalService.name ?? ''))
        .filter(
          (additionalServiceField) =>
            !(additionalServiceField.additionalService.archived && (additionalServiceField.value || 0) === 0),
        )
        .map((additionalServiceField) => {
          return (
            <li key={additionalServiceField.additionalService.id} className="additional-service-list-item">
              <FormControl error={additionalServiceField.hasError} sx={{ width: '100%' }}>
                <InputLabel
                  className="additional-service-label"
                  htmlFor={`additionalService-${additionalServiceField.additionalService.name}`}
                >
                  {additionalServiceField.additionalService.name}
                </InputLabel>
                <Input
                  id={`additionalService_${additionalServiceField.additionalService.id}`}
                  name={`additionalService_${additionalServiceField.additionalService.id}`}
                  className="additional-service"
                  disabled={disabled}
                  type="number"
                  error={additionalServiceField.hasError}
                  value={additionalServiceField.value ?? ''}
                  inputProps={{ min: 0, max: 1000 }}
                  startAdornment={<InputAdornment position="start">kpl</InputAdornment>}
                  onChange={(event) => {
                    dispatch({
                      type: 'UPDATE_SHIPMENT_ADDITIONAL_SERVICE',
                      payload: {
                        additionalService: additionalServiceField.additionalService,
                        value: event.target.value,
                      },
                    });
                    debouncedValidateFieldsDispatch(dispatch);
                  }}
                />
                {additionalServiceField.feedback ? (
                  <FormHelperText className="additional-service-helper-text">
                    {additionalServiceField.feedback}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </li>
          );
        })}
    </List>
  );
};

const PriceContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
});

const ContractPriceCheckbox: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          className="has_contract_price_checkbox"
          name="has_contract_price"
          checked={state.fields.has_contract_price.value || false}
          onChange={(event) =>
            updateFieldValue(event.target.name as FieldName, !state.fields.has_contract_price.value, dispatch)
          }
        />
      }
      label="Sopimushinta"
    />
  );
};

const Price: React.FC<DispatchableInputProps> = ({ dispatch, state }) => {
  return (
    <PriceContainer>
      <StyledFormControl error={state.fields.price.hasError}>
        <InputLabel htmlFor="price">Hinta</InputLabel>
        <Input
          disabled={!state.fields.has_contract_price.value}
          id="price"
          name="price"
          type="number"
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          value={state.fields.price.value}
          inputProps={{ min: 0 }}
          //disable changing value with mouse scroll
          onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
          onChange={(event) =>
            updateFieldValue('price', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
          }
        />
        {state.fields.price.feedback ? <FormHelperText>{state.fields.price.feedback}</FormHelperText> : null}
      </StyledFormControl>
      <ContractPriceCheckbox state={state} dispatch={dispatch} />
    </PriceContainer>
  );
};

const HourlyWorkAndWaitContainer = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
});

const HourlyWorkAndWaitStandardFormControl = styled(FormControl)({
  flexGrow: 0,
  width: '5rem',
});

const HourlyWorkAndWaitTextField = styled(TextField)({
  flexGrow: 1,
});

const HourlyWorkHours: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitStandardFormControl error={state.fields.hourly_work_hours.hasError}>
      <InputLabel htmlFor="hourly_work_hours">Tuntityö</InputLabel>
      <Input
        disabled={disabled}
        id="hourly_work_hours"
        name="hourly_work_hours"
        type="number"
        startAdornment={<InputAdornment position="start">h</InputAdornment>}
        value={state.fields.hourly_work_hours.value}
        inputProps={{ min: 0 }}
        //disable changing value with mouse scroll
        onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
        onChange={(event) =>
          updateFieldValue(
            'hourly_work_hours',
            event.target.value !== '' ? parseFloat(event.target.value) : '',
            dispatch,
          )
        }
      />
      {state.fields.hourly_work_hours.feedback ? (
        <FormHelperText>{state.fields.hourly_work_hours.feedback}</FormHelperText>
      ) : null}
    </HourlyWorkAndWaitStandardFormControl>
  );
};

const HourlyWorkReason: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitTextField
      disabled={disabled}
      name="hourly_work_reason"
      error={state.fields.hourly_work_reason.hasError}
      helperText={state.fields.hourly_work_reason.feedback}
      label="Tuntityön syy"
      value={state.fields.hourly_work_reason.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const WaitHours: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitStandardFormControl error={state.fields.wait_hours.hasError}>
      <InputLabel htmlFor="wait_hours">Odotusaika</InputLabel>
      <Input
        disabled={disabled}
        id="wait_hours"
        name="wait_hours"
        type="number"
        startAdornment={<InputAdornment position="start">h</InputAdornment>}
        value={state.fields.wait_hours.value}
        inputProps={{ min: 0 }}
        onChange={(event) =>
          updateFieldValue('wait_hours', event.target.value !== '' ? parseFloat(event.target.value) : '', dispatch)
        }
      />
      {state.fields.wait_hours.feedback ? <FormHelperText>{state.fields.wait_hours.feedback}</FormHelperText> : null}
    </HourlyWorkAndWaitStandardFormControl>
  );
};

const WaitReason: React.FC<DispatchableInputProps> = ({ dispatch, state, disabled }) => {
  return (
    <HourlyWorkAndWaitTextField
      disabled={disabled}
      name="wait_reason"
      error={state.fields.wait_reason.hasError}
      helperText={state.fields.wait_reason.feedback}
      label="Odotuksen syy"
      value={state.fields.wait_reason.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
    />
  );
};

const getNewPriceBasis = (currentUser: User | undefined, originalShipment: Shipment | undefined) => {
  const newPriceBasisObject = originalShipment?.price_basis;
  if (!newPriceBasisObject) {
    return;
  }

  return (
    <div style={{ color: 'rgba(0, 0, 0, 0.6)', paddingBottom: '0.5rem', fontSize: '0.9rem' }}>
      {/* host organization users have price input field, no need to show price twice */}
      {canAccessCustomerPricing(currentUser) && !isHostOrganizationUser(currentUser) && (
        <>
          <span>Hinta</span>
          <ul style={{ marginTop: 0, paddingLeft: '1rem' }}>
            <li>{originalShipment.price} €</li>
          </ul>
        </>
      )}
      <span>Hintaperuste</span>
      <ul style={{ marginTop: 0, paddingLeft: '1rem' }}>
        {newPriceBasisObject.basic_price ? <li>Perushinta: {formatPrice(newPriceBasisObject.basic_price)} €</li> : null}
        {newPriceBasisObject.pricing_unit_value && newPriceBasisObject.distance_value ? (
          <li>
            Hintaperuste: {newPriceBasisObject.pricing_unit_value} {formatPricingUnit(newPriceBasisObject.pricing_unit)}{' '}
            / {newPriceBasisObject.distance_value}{' '}
            {formatDistancePricingBasis(newPriceBasisObject.distance_pricing_basis)}
          </li>
        ) : null}
        {newPriceBasisObject.express_delivery ? (
          <li>Pikalisä: {formatPrice(newPriceBasisObject.express_delivery)} €</li>
        ) : null}
        {newPriceBasisObject.price_ratio_for_combined_shipment &&
        newPriceBasisObject.price_ratio_for_combined_shipment !== 1 ? (
          <li>
            Osuus yhteistoimituksesta: {formatFloat(newPriceBasisObject.price_ratio_for_combined_shipment * 100)} %
          </li>
        ) : null}
        {newPriceBasisObject.working_hours || newPriceBasisObject.price_per_hour ? (
          <li>
            Työtunnit: {newPriceBasisObject.working_hours} h *{' '}
            {newPriceBasisObject.price_per_hour
              ? formatPrice(newPriceBasisObject.price_per_hour)
              : newPriceBasisObject.price_per_hour}{' '}
            €
          </li>
        ) : null}
        {newPriceBasisObject.combination_vehicle_price_per_hour ? (
          <li>
            Yhdistelmäajoneuvolisä: {newPriceBasisObject.working_hours} h *{' '}
            {formatPrice(newPriceBasisObject.combination_vehicle_price_per_hour)} €
          </li>
        ) : null}
        {newPriceBasisObject.car_type ? <li>Autotyyppi: {newPriceBasisObject.car_type}</li> : null}
        {newPriceBasisObject.additional_services?.length && newPriceBasisObject.additional_services?.length > 0 ? (
          <li>
            Lisäpalvelut:
            <ul style={{ paddingLeft: '0.5rem' }}>
              {newPriceBasisObject?.additional_services?.map((additionalService, index) => (
                <li key={index} style={{ listStyle: 'none' }}>
                  {additionalService.name} /{' '}
                  {additionalService.unit_price
                    ? formatPrice(additionalService.unit_price)
                    : additionalService.unit_price}{' '}
                  € * {additionalService.amount} kpl
                </li>
              ))}
            </ul>
          </li>
        ) : null}
        {newPriceBasisObject.error_message ? <li>Hinnoitteluvirhe: {newPriceBasisObject.error_message}</li> : null}
      </ul>
    </div>
  );
};

export const PricingFieldSet: React.FC<DispatchableInputProps & WithUser & IsEditShipment> = ({
  dispatch,
  state,
  currentUser,
  isEditShipment,
}) => {
  const hasContractPrice = Boolean(state.fields.has_contract_price.value);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <FieldSet id="pricing-fields-container">
      <legend>Hinnoittelu</legend>
      {canAccessPricing(currentUser) && (
        <>
          <PricingModelPicker
            disabled={state.isLoading || hasContractPrice}
            pricingModels={state.pricingModels}
            label={'Hinnoittelumalli'}
            value={
              state.pricingModels.find((pricingModel) => pricingModel.id === state.fields.pricing_model.value) ?? null
            }
            onChange={(pricingModel) => {
              updateFieldValue('pricing_model', pricingModel?.id ?? null, dispatch);
              if (pricingModel?.custom_pricing_categories && pricingModel?.default_custom_pricing_category) {
                updateFieldValue('custom_pricing_category_id', pricingModel?.default_custom_pricing_category, dispatch);
              } else {
                updateFieldValue('custom_pricing_category_id', '', dispatch);
              }
            }}
            isTooltipVisible={isTooltipVisible}
            tooltip="Ei käytössä sopimushinnoittelun kanssa"
            onTooltipOpen={() => {
              if (hasContractPrice) {
                setIsTooltipVisible(true);
              }
            }}
            onTooltipClose={() => setIsTooltipVisible(false)}
          />
          <Price state={state} dispatch={dispatch} />
        </>
      )}
      {canAccessPricing(currentUser) || canAccessCustomerPricing(currentUser)
        ? getNewPriceBasis(currentUser, state.originalShipment)
        : null}
      {canAccessPricing(currentUser) && (
        <>
          <HourlyWorkAndWaitContainer>
            <HourlyWorkHours state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <HourlyWorkReason state={state} dispatch={dispatch} disabled={!state.canEdit} />{' '}
            <AdditionalHourlyPricingCheckbox state={state} dispatch={dispatch} disabled={!state.canEdit} />
          </HourlyWorkAndWaitContainer>
          <HourlyWorkAndWaitContainer>
            <WaitHours state={state} dispatch={dispatch} disabled={!state.canEdit} />
            <WaitReason state={state} dispatch={dispatch} disabled={!state.canEdit} />
          </HourlyWorkAndWaitContainer>
          <LegacyEtaisyysField
            state={state}
            dispatch={dispatch}
            disabled={!state.canEdit}
            isEditShipment={isEditShipment}
          />
        </>
      )}
    </FieldSet>
  );
};

export const CoordinationFieldSet: React.FC<InputProps & WithUser> = ({ state, currentUser }) => {
  const driverPhoneNumber = state.driver?.company_phone_number ?? '';
  return (
    <FieldSet id="coordination-fields-container">
      <legend>Ajojärjestely</legend>
      <ImmutableTextField label={'Kuorma'} id="load">
        {canAccessCoordination(currentUser) ? (
          <Link component={RouterLink} to={{ pathname: `/loads/${state.load?.id}` }}>
            {state.load ? `${state.load.id} ${state.load.organization_id ?? ''}` : ''}
          </Link>
        ) : (
          <Typography variant="body2">
            {state.load ? `${state.load.id} ${state.load.organization_id ?? ''}` : ''}
          </Typography>
        )}
      </ImmutableTextField>
      <ImmutableTextField label={'Kuljettaja'} id="load-driver">
        <Typography variant="body2">
          {`${state.driver?.last_name ?? ''} ${state.driver?.first_name ?? ''}`}{' '}
          <Link href={`tel:${driverPhoneNumber}`}>{driverPhoneNumber}</Link>
        </Typography>
      </ImmutableTextField>
      <ImmutableTextField label={'Auto'} id="load-car">
        <Typography variant="body2">{state.car ? (state.car?.licence_plate ?? '') : ''}</Typography>
      </ImmutableTextField>
      <ImmutableTextField label={'Ajopäivä'} id="load-drive-date">
        <Typography variant="body2">{(state.load?.drive_date && formatDate(state.load.drive_date)) ?? ''}</Typography>
      </ImmutableTextField>
      <ImmutableTextField label={'Avisointi'} id="delivery-status-url-identifier">
        <Link
          href={
            window.location.href.indexOf('prod') !== -1 || window.location.href.indexOf('extranet2.timecap.fi') !== -1
              ? `https://extranet2.timecap.fi/avi/${state.originalShipment?.delivery_status_url_identifier}`
              : `https://staging.dev.timecap.fi/avi/${state.originalShipment?.delivery_status_url_identifier}`
          }
          target="_blank"
        >
          {state.originalShipment?.delivery_status_url_identifier ? 'Näytä avisointi' : ''}
        </Link>
      </ImmutableTextField>
    </FieldSet>
  );
};

export const AdditionalRequirementFields: React.FC<DispatchableInputProps & WithUser> = ({
  dispatch,
  state,
  disabled,
}) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            className="is_adr_delivery_checkbox"
            name="is_adr_delivery"
            checked={state.fields.is_adr_delivery.value || false}
            onChange={(event) =>
              updateFieldValue(event.target.name as FieldName, !state.fields.is_adr_delivery.value, dispatch)
            }
          />
        }
        label="ADR"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            className="requires_hoist_checkbox"
            name="requires_hoist"
            checked={state.fields.requires_hoist.value || false}
            onChange={(event) =>
              updateFieldValue(event.target.name as FieldName, !state.fields.requires_hoist.value, dispatch)
            }
          />
        }
        label="Nosturi"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            className="is_express_delivery_checkbox"
            name="is_express_delivery"
            checked={state.fields.is_express_delivery.value || false}
            onChange={(event) =>
              updateFieldValue(event.target.name as FieldName, !state.fields.is_express_delivery.value, dispatch)
            }
          />
        }
        label="Pikatoimitus"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            className="requires_combination_vehicle_checkbox"
            name="requires_combination_vehicle"
            checked={state.fields.requires_combination_vehicle.value || false}
            onChange={(event) =>
              updateFieldValue(
                event.target.name as FieldName,
                !state.fields.requires_combination_vehicle.value,
                dispatch,
              )
            }
          />
        }
        label="Yhdistelmäajoneuvo"
      />
    </>
  );
};

export const OtherContractNumber: React.FC<DispatchableInputProps> = ({ state, dispatch, disabled }) => {
  return (
    <TextField
      disabled={disabled}
      name="other_contract_number"
      error={state.fields.other_contract_number.hasError}
      helperText={state.fields.other_contract_number.feedback}
      label="Toimitusmääräys"
      value={state.fields.other_contract_number.value}
      onChange={(event) => updateFieldValue(event.target.name as FieldName, event.target.value, dispatch)}
      multiline={true}
      maxRows={3}
    />
  );
};

interface WithHideAndLoadingRelatedShipments {
  hideRelatedShipments: boolean;
  setHideRelatedShipments: Dispatch<SetStateAction<boolean>>;
  isRelatedShipmentsLoading: boolean;
  showAllRelatedShipments?: boolean;
  setShowAllRelatedShipments?: Dispatch<SetStateAction<boolean>>;
}

export const RelatedShipmentsFieldSet: React.FC<
  DispatchableInputProps & WithUser & WithHideAndLoadingRelatedShipments
> = ({
  state,
  dispatch,
  currentUser,
  hideRelatedShipments,
  setHideRelatedShipments,
  isRelatedShipmentsLoading,
  showAllRelatedShipments,
  setShowAllRelatedShipments,
}) => {
  const defaultNumberOfShownShipments = 10;
  return (
    <FieldSet className="related-shipments-container">
      <legend>Sama viitenumero</legend>
      {isRelatedShipmentsLoading ? (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '0.25rem' }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {state.relatedShipments.map((shipment, index) => (
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                currentUser && load(shipment.organization_id, shipment.id, dispatch, currentUser);
              }}
              style={
                index + 1 > defaultNumberOfShownShipments && hideRelatedShipments ? { display: 'none' } : undefined
              }
              key={shipment.id}
              component={RouterLink}
              to={{ pathname: `/shipments/${shipment.id}` }}
            >
              {shipment.id}{' '}
              {shipment.agreed_delivery_window_starts_at
                ? `(${formatDate(shipment.agreed_delivery_window_starts_at)})`
                : ''}
            </Link>
          ))}
          {state.relatedShipments.length > defaultNumberOfShownShipments ? (
            <FullWidthButton variant="text" onClick={() => setHideRelatedShipments(!hideRelatedShipments)}>
              {hideRelatedShipments ? <ExpandMore /> : <ExpandLess />}
            </FullWidthButton>
          ) : null}
          {!showAllRelatedShipments ? (
            <Tooltip
              title="Oletuksena näytetään vain kuukauden takaiset saman viitenumeron toimitukset."
              aria-label="Oletuksena näytetään vain kuukauden takaiset saman viitenumeron toimitukset."
            >
              <span>
                {/* This span makes Tooltip work with disabled button. Disabled elements do not send events, so we need this wrapper */}
                <FullWidthButton
                  variant="text"
                  className="get-all-related-shipments-button"
                  disabled={state.isLoading}
                  onClick={() => setShowAllRelatedShipments && setShowAllRelatedShipments(!showAllRelatedShipments)}
                >
                  Hae kaikki
                </FullWidthButton>
              </span>
            </Tooltip>
          ) : null}
        </>
      )}
    </FieldSet>
  );
};
