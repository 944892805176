/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SingleShipmentPatchBody
 */
export interface SingleShipmentPatchBody {
  /**
   *
   * @type {number}
   * @memberof SingleShipmentPatchBody
   */
  id: number;
  /**
   * NB. toimitetaan_seuraavaksi is not stored in database yet but it only triggers few actions like delivery notifications.
   * @type {string}
   * @memberof SingleShipmentPatchBody
   */
  state?: SingleShipmentPatchBodyStateEnum;
  /**
   *
   * @type {string}
   * @memberof SingleShipmentPatchBody
   */
  recipient?: string | null;
  /**
   *
   * @type {string}
   * @memberof SingleShipmentPatchBody
   */
  note?: string | null;
  /**
   *
   * @type {number}
   * @memberof SingleShipmentPatchBody
   */
  order_in_load?: number | null;
  /**
   *
   * @type {number}
   * @memberof SingleShipmentPatchBody
   */
  hourly_work_hours?: number;
  /**
   *
   * @type {boolean}
   * @memberof SingleShipmentPatchBody
   */
  unloaded_with_customer_permission?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum SingleShipmentPatchBodyStateEnum {
  Noudettavissa = 'noudettavissa',
  Noutokohteessa = 'noutokohteessa',
  Noudettu = 'noudettu',
  Toimituskohteessa = 'toimituskohteessa',
  Toimitettu = 'toimitettu',
  Peruttu = 'peruttu',
  Odottaa = 'odottaa',
  ToimitetaanSeuraavaksi = 'toimitetaan_seuraavaksi',
}

export function SingleShipmentPatchBodyFromJSON(json: any): SingleShipmentPatchBody {
  return SingleShipmentPatchBodyFromJSONTyped(json, false);
}

export function SingleShipmentPatchBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleShipmentPatchBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    state: !exists(json, 'state') ? undefined : json['state'],
    recipient: !exists(json, 'recipient') ? undefined : json['recipient'],
    note: !exists(json, 'note') ? undefined : json['note'],
    order_in_load: !exists(json, 'order_in_load') ? undefined : json['order_in_load'],
    hourly_work_hours: !exists(json, 'hourly_work_hours') ? undefined : json['hourly_work_hours'],
    unloaded_with_customer_permission: !exists(json, 'unloaded_with_customer_permission')
      ? undefined
      : json['unloaded_with_customer_permission'],
  };
}

export function SingleShipmentPatchBodyToJSON(value?: SingleShipmentPatchBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    state: value.state,
    recipient: value.recipient,
    note: value.note,
    order_in_load: value.order_in_load,
    hourly_work_hours: value.hourly_work_hours,
    unloaded_with_customer_permission: value.unloaded_with_customer_permission,
  };
}
