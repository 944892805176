/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SearchShipmentsPostBody
 */
export interface SearchShipmentsPostBody {
  /**
   *
   * @type {number}
   * @memberof SearchShipmentsPostBody
   */
  load_id?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  organization_id?: string;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  state?: SearchShipmentsPostBodyStateEnum;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  reference_number?: string;
  /**
   *
   * @type {number}
   * @memberof SearchShipmentsPostBody
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  job_number?: string;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  delivery_name?: string;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  delivery_address?: string;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  delivery_phone_number?: string;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  pickup_name?: string;
  /**
   *
   * @type {string}
   * @memberof SearchShipmentsPostBody
   */
  pickup_address?: string;
  /**
   *
   * @type {Date}
   * @memberof SearchShipmentsPostBody
   */
  agreed_delivery_window_date_range_starts_at?: Date;
  /**
   *
   * @type {Date}
   * @memberof SearchShipmentsPostBody
   */
  agreed_delivery_window_date_range_ends_at?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum SearchShipmentsPostBodyStateEnum {
  EiVarastossa = 'ei varastossa',
  Noudettavissa = 'noudettavissa',
  Noutokohteessa = 'noutokohteessa',
  Noudettu = 'noudettu',
  Toimituskohteessa = 'toimituskohteessa',
  Toimitettu = 'toimitettu',
  Peruttu = 'peruttu',
  Odottaa = 'odottaa',
}

export function SearchShipmentsPostBodyFromJSON(json: any): SearchShipmentsPostBody {
  return SearchShipmentsPostBodyFromJSONTyped(json, false);
}

export function SearchShipmentsPostBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchShipmentsPostBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    load_id: !exists(json, 'load_id') ? undefined : json['load_id'],
    organization_id: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    state: !exists(json, 'state') ? undefined : json['state'],
    reference_number: !exists(json, 'reference_number') ? undefined : json['reference_number'],
    id: !exists(json, 'id') ? undefined : json['id'],
    job_number: !exists(json, 'job_number') ? undefined : json['job_number'],
    delivery_name: !exists(json, 'delivery_name') ? undefined : json['delivery_name'],
    delivery_address: !exists(json, 'delivery_address') ? undefined : json['delivery_address'],
    delivery_phone_number: !exists(json, 'delivery_phone_number') ? undefined : json['delivery_phone_number'],
    pickup_name: !exists(json, 'pickup_name') ? undefined : json['pickup_name'],
    pickup_address: !exists(json, 'pickup_address') ? undefined : json['pickup_address'],
    agreed_delivery_window_date_range_starts_at: !exists(json, 'agreed_delivery_window_date_range_starts_at')
      ? undefined
      : new Date(json['agreed_delivery_window_date_range_starts_at']),
    agreed_delivery_window_date_range_ends_at: !exists(json, 'agreed_delivery_window_date_range_ends_at')
      ? undefined
      : new Date(json['agreed_delivery_window_date_range_ends_at']),
  };
}

export function SearchShipmentsPostBodyToJSON(value?: SearchShipmentsPostBody | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    load_id: value.load_id,
    organization_id: value.organization_id,
    state: value.state,
    reference_number: value.reference_number,
    id: value.id,
    job_number: value.job_number,
    delivery_name: value.delivery_name,
    delivery_address: value.delivery_address,
    delivery_phone_number: value.delivery_phone_number,
    pickup_name: value.pickup_name,
    pickup_address: value.pickup_address,
    agreed_delivery_window_date_range_starts_at:
      value.agreed_delivery_window_date_range_starts_at === undefined
        ? undefined
        : value.agreed_delivery_window_date_range_starts_at.toISOString(),
    agreed_delivery_window_date_range_ends_at:
      value.agreed_delivery_window_date_range_ends_at === undefined
        ? undefined
        : value.agreed_delivery_window_date_range_ends_at.toISOString(),
  };
}
