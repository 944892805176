import {
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleGlobalFilterButton,
  MRT_Cell,
  MRT_RowData,
} from 'material-react-table';
import React from 'react';
import { DateRangePicker, DateRangePickerProps, Range } from './MRTDateRangePicker';
import { Badge, Box, Divider, IconButton, Tooltip } from '@mui/material';
import { GetApp, Tune } from '@mui/icons-material';
import { dateFormat } from '../../formatters';
import { utils as xlsxUtils, writeFile as writeXlsxFile } from 'xlsx';
import { MRT_ShowHideColumnsMenu } from './MRTShowHideColumnsMenu';

export interface MRTToolbarProps<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
  dateRangePickerProps: DateRangePickerProps;
  dateRange: Range;
  header: string;
}

const exportAsXlsx = (rows: any[], dateRange: Range, header: string) => {
  const rowColumnHeaders = rows.map((row) => {
    return row.getVisibleCells().map((cell: MRT_Cell<any>) => cell.column.columnDef.header ?? '');
  });
  const rowCellValues = rows.map((row) => {
    const values = row.getVisibleCells().map((cell: MRT_Cell<any>) => {
      return cell.getValue() ? cell.getValue() : '';
    });
    return values;
  });

  const worksheet = xlsxUtils.aoa_to_sheet([rowColumnHeaders[0], ...rowCellValues]);
  const workbook = xlsxUtils.book_new();
  xlsxUtils.book_append_sheet(workbook, worksheet, 'Sheet 1');

  writeXlsxFile(
    workbook,
    `${header}_${dateRange.start.toFormat(dateFormat)}-${dateRange.end.toFormat(dateFormat)}.xlsx`,
  );
};

export const MRTToolbar = <TData extends MRT_RowData>({
  table,
  dateRangePickerProps,
  dateRange,
  header,
}: MRTToolbarProps<TData>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const hiddenColumnsLength = Object.values(table.getState().columnVisibility).filter((id) => !id).length;
  const globalFilterValueExists = Boolean(table.getState().globalFilter);
  const filterValuesLength = table.getState().columnFilters && table.getState().columnFilters.length;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <DateRangePicker {...dateRangePickerProps} />
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderWidth: '0.1rem', marginLeft: '0.25rem', marginRight: '0.25rem' }}
      />
      <Box>
        <Badge badgeContent={globalFilterValueExists ? 1 : null} color="primary" overlap="circular">
          <MRT_ToggleGlobalFilterButton table={table} data-cy="global-filter-button" />{' '}
        </Badge>
        <Tooltip arrow={true} title="Lataa">
          <IconButton onClick={() => exportAsXlsx(table.getFilteredRowModel().rows, dateRange, header)}>
            <GetApp />
          </IconButton>
        </Tooltip>
        <Tooltip arrow={true} title="Muokkaa sarakkeita ja suodattimia">
          <Badge badgeContent={hiddenColumnsLength} color="primary" overlap="circular">
            <Badge
              badgeContent={filterValuesLength}
              color="warning"
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => {
                  handleClick(event);
                }}
              >
                <Tune />
              </IconButton>
            </Badge>
          </Badge>
        </Tooltip>
        <MRT_ShowHideColumnsMenu table={table} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        <MRT_ToggleDensePaddingButton table={table} />
      </Box>
    </Box>
  );
};
