import { TableState } from 'react-table';
import { Driver, Load, Office, Organization, OrganizationDriver, OrganizationShipment, Shipment } from '../../api';
import { updateViewSettings } from '../settings';
import { Range, SetDateRangeAction, getDefaultDateRange } from '../../components/DateRangePicker';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export type ReportShipment = (Shipment | OrganizationShipment) & {
  organizationName?: string;
  billingOfficeName?: string;
  driverName?: string;
};

export interface ShipmentReportViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
  hideCancelledShipments: boolean;
}

export interface ShipmentAggregatesReportViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
}

export interface ShipmentBillingReportViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
  showRegularPricedShipments: boolean;
  showContractPricedShipments: boolean;
  showShipmentsWithBillingReferenceNumber: boolean;
  showShipmentsWithoutBillingReferenceNumber: boolean;
  showShipmentsWithBillingDate: boolean;
  organization: Organization | undefined;
  selectedOffices: Office[];
  billingDate: Date | null;
}

export interface ShipmentAggregatesReportData {
  count: number;
  sum_weight_kg: number;
  sum_volume_m3: number;
  sum_length_ldm: number;
  sum_chargeable_weight_kg: number;
  sum_price: number;
  sum_hourly_work_hours: number;
  organization_id: Organization['id'];
  organizationName?: string;
}

export interface State {
  shipments: ReportShipment[];
  organizations: Organization[];
  offices: Office[];
  organizationShipments: OrganizationShipment[];
  selectedShipments: { [key: number]: boolean };
  notification: NotificationType;
  dateRange: Range | null;
  isLoading: boolean;
  shipmentReportViewSettings: ShipmentReportViewSettings;
  shipmentAggregatesReportViewSettings: ShipmentAggregatesReportViewSettings;
  shipmentAggregatesReportData: ShipmentAggregatesReportData[];
  shipmentBillingReportViewSettings: ShipmentBillingReportViewSettings;
}

export const getInitialState = (): State => {
  return {
    shipments: [],
    organizations: [],
    offices: [],
    organizationShipments: [],
    selectedShipments: {},
    notification: {
      message: null,
    },
    dateRange: getDefaultDateRange(),
    isLoading: true,
    shipmentReportViewSettings: {
      filters: [],
      sortBy: [],
      hiddenColumns: [],
      hideCancelledShipments: true,
    },
    shipmentAggregatesReportViewSettings: { filters: [], sortBy: [], hiddenColumns: [] },
    shipmentBillingReportViewSettings: {
      filters: [],
      sortBy: [],
      hiddenColumns: [],
      showRegularPricedShipments: true,
      showContractPricedShipments: true,
      showShipmentsWithBillingReferenceNumber: true,
      showShipmentsWithoutBillingReferenceNumber: true,
      showShipmentsWithBillingDate: true,
      organization: undefined,
      billingDate: null,
      selectedOffices: [],
    },
    shipmentAggregatesReportData: [],
  };
};

export type Action =
  | {
      type: 'INITIALIZE';
      payload: {
        shipments?: ReportShipment[];
        organizations: Organization[];
        offices?: Office[];
        drivers?: Driver[] | OrganizationDriver[];
        loads?: Load[];
        shipmentAggregatesReportData?: ShipmentAggregatesReportData[];
      };
    }
  | {
      type: 'SET_ORGANIZATION_SHIPMENTS';
      payload: {
        organizationShipments: OrganizationShipment[];
      };
    }
  | { type: 'SELECT_SHIPMENT'; payload: { shipmentId: Shipment['id']; value: boolean } }
  | { type: 'CLEAR_SELECTED_SHIPMENTS' }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction
  | {
      type: 'SET_VIEW_SETTINGS';
      payload: {
        viewId: string;
        shipmentReportViewSettings?: ShipmentReportViewSettings;
        shipmentAggregatesReportViewSettings?: ShipmentAggregatesReportViewSettings;
        shipmentBillingReportViewSettings?: ShipmentBillingReportViewSettings;
      };
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        shipments: (action.payload.shipments ?? []).map((shipment) => {
          const load = (action.payload.loads ?? []).find((load) => load.id === shipment.load_id);
          const driver = load
            ? (action.payload.drivers ?? []).find((driver) => driver.id === load.driver_id)
            : undefined;
          return {
            ...shipment,
            organizationName: action.payload.organizations.find((org) => org.id === shipment.organization_id)?.name,
            driverName: driver ? `${driver.last_name} ${driver.first_name}` : '',
          };
        }),
        organizations: action.payload.organizations,
        offices: action.payload.offices ?? [],
        shipmentAggregatesReportData: (action.payload.shipmentAggregatesReportData ?? []).map((datapoint) => {
          datapoint.organizationName = action.payload.organizations.find(
            (org) => org.id === datapoint.organization_id,
          )?.name;
          return datapoint;
        }),
      };
    case 'SET_ORGANIZATION_SHIPMENTS':
      return {
        ...state,
        organizationShipments: action.payload.organizationShipments,
      };
    case 'SELECT_SHIPMENT':
      return {
        ...state,
        selectedShipments: { ...state.selectedShipments, [action.payload.shipmentId]: action.payload.value },
      };
    case 'CLEAR_SELECTED_SHIPMENTS':
      return {
        ...state,
        selectedShipments: {},
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings(
        action.payload.viewId,
        action.payload.shipmentReportViewSettings ??
          action.payload.shipmentAggregatesReportViewSettings ??
          action.payload.shipmentBillingReportViewSettings,
      );
      const newState = {
        ...state,
      };
      if (action.payload.shipmentReportViewSettings) {
        newState.shipmentReportViewSettings = action.payload.shipmentReportViewSettings;
      }
      if (action.payload.shipmentAggregatesReportViewSettings) {
        newState.shipmentAggregatesReportViewSettings = action.payload.shipmentAggregatesReportViewSettings;
      }
      if (action.payload.shipmentBillingReportViewSettings) {
        newState.shipmentBillingReportViewSettings = action.payload.shipmentBillingReportViewSettings;
      }
      return newState;
  }
};
