import { Dispatch } from 'react';
import { api, UnhandledShipmentRow } from '../../api';
import { TableState } from 'react-table';
import { getDefaultDateRange, Range, SetDateRangeAction } from '../../components/DateRangePicker';
import { getViewSettings, updateViewSettings } from '../settings';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { SetLoadingAction } from '../../components/Loading';

export interface ShipmentRowsCoordinationViewSettings {
  filters: TableState['filters'];
  sortBy: TableState['sortBy'];
  hiddenColumns: TableState['hiddenColumns'];
  onlyUnhandledShipmentRows: boolean;
}

export interface State {
  shipmentRows: UnhandledShipmentRow[];
  notification: NotificationType;
  dateRange: Range | null;
  isLoading: boolean;
  viewSettings: ShipmentRowsCoordinationViewSettings;
}

export const getInitialState = (): State => {
  return {
    shipmentRows: [],
    notification: {
      message: null,
    },
    dateRange: getDefaultDateRange(),
    isLoading: true,
    viewSettings: { filters: [], sortBy: [], hiddenColumns: [], onlyUnhandledShipmentRows: false },
  };
};

export type Action =
  | {
      type: 'INITIALIZE';
      payload: {
        shipmentRows: UnhandledShipmentRow[];
        settings: ShipmentRowsCoordinationViewSettings;
      };
    }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction
  | { type: 'SET_VIEW_SETTINGS'; payload: ShipmentRowsCoordinationViewSettings }
  | { type: 'SET_TABLE_SETTINGS'; payload: TableState<any> };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        shipmentRows: action.payload.shipmentRows,
        viewSettings: {
          onlyUnhandledShipmentRows: action.payload.settings.onlyUnhandledShipmentRows ?? false,
          filters: action.payload.settings.filters ?? [],
          sortBy: action.payload.settings.sortBy ?? [],
          hiddenColumns: action.payload.settings.hiddenColumns ?? [],
        },
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_VIEW_SETTINGS':
      updateViewSettings('coordination_shipment_rows', action.payload);
      return {
        ...state,
        viewSettings: { ...state.viewSettings, ...action.payload },
      };
    case 'SET_TABLE_SETTINGS':
      const settings = {
        ...state.viewSettings,
        filters: action.payload.filters,
        sortBy: action.payload.sortBy,
        hiddenColumns: action.payload.hiddenColumns,
      };
      updateViewSettings('coordination_shipment_rows', settings);
      const newState = {
        ...state,
        viewSettings: settings,
      };
      return newState;
  }
};

export const load = async (state: State, dispatch: Dispatch<Action>): Promise<void> => {
  const settings = getViewSettings<ShipmentRowsCoordinationViewSettings>('coordination_shipment_rows');
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    if (state.dateRange?.start && state.dateRange?.end) {
      const shipmentRows = (
        await api.shipments.getUnhandledShipmentRows({
          agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
          agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
        })
      ).data;
      dispatch({
        type: 'INITIALIZE',
        payload: {
          shipmentRows,
          settings,
        },
      });
    }
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa toimitusrivejä',
        severity: 'error',
      },
    });
  }
};

export const filterShipmentRows = (
  shipmentRows: UnhandledShipmentRow[],
  onlyUnhandledShipmentRows: boolean,
): UnhandledShipmentRow[] => {
  return onlyUnhandledShipmentRows
    ? shipmentRows.filter((shipmentRow) => shipmentRow.relatedShipmentRows && !shipmentRow.relatedShipmentRows.length)
    : shipmentRows;
};
