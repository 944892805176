import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Fields } from '../SearchShipments';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { dateNotRequired } from '../../../validation';
import { SearchShipmentsPostBodyStateEnum } from '../../../api';
import { EmptySelectMenuItem } from '../../../components/EmptySelectMenuItem';

type FormInputProps = TextFieldProps & {
  name: keyof Fields;
  defaultValue?: string | DateTime;
  formMethods?: UseFormReturn<Fields>;
};

export const FormInput: React.FC<FormInputProps> = (props) => {
  const { control } = useFormContext<Fields>();
  return (
    <Controller<Fields>
      render={({ field }) => (
        <TextField {...field} sx={{ marginBottom: '0.25rem' }} type={props.type} label={props.label} />
      )}
      control={control}
      fullWidth={true}
      {...props}
    />
  );
};

interface WithHideAndLoadingRelatedShipments {
  setError: Dispatch<SetStateAction<boolean>>;
}

export const FormKeyboardDatePicker: React.FC<FormInputProps & WithHideAndLoadingRelatedShipments> = (props) => {
  const { control } = useFormContext<Fields>();

  return (
    <Controller<Fields>
      render={({ field: { ref, onBlur, name, value, ...field } }) => {
        const jsDateOrNull = value instanceof DateTime ? value.toJSDate() : null;
        return (
          <DatePicker
            {...field}
            inputRef={ref}
            label={props.label}
            onError={() => {
              props.setError(Boolean(dateNotRequired.validate(jsDateOrNull).error));
            }}
            slotProps={{
              textField: {
                onBlur: onBlur,
                name: name,
                value: value,
                error: Boolean(dateNotRequired.validate(jsDateOrNull).error),
                helperText: dateNotRequired.validate(jsDateOrNull).error?.message,
              },
            }}
          />
        );
      }}
      control={control}
      {...props}
    />
  );
};

export const FormLast30DaysCheckbox: React.FC<FormInputProps> = (props) => {
  const { control } = useFormContext<Fields>();
  const [isSearchButtonTooltipVisible, setIsSearchButtonTooltipVisible] = useState(false);
  const formMethods = props.formMethods;
  if (!formMethods) {
    return null;
  } else
    return (
      <FormControlLabel
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
        control={
          <Controller<Fields>
            render={({ field: { onChange, value, name } }) => {
              const deliveryDateExists = formMethods.getValues().delivery_date;
              const tooltipHelperText = 'Ei käytössä päivämäärähakujen kanssa';
              useEffect(() => {
                // refresh component when delivery date changes
              }, [formMethods.formState.isDirty || deliveryDateExists]);
              return (
                <Tooltip
                  className="last-30-days-and-onwards-tooltip"
                  title={deliveryDateExists ? tooltipHelperText : ''}
                  aria-label={deliveryDateExists ? tooltipHelperText : ''}
                  open={isSearchButtonTooltipVisible}
                  onOpen={() => {
                    setIsSearchButtonTooltipVisible(true);
                  }}
                  onClose={() => setIsSearchButtonTooltipVisible(false)}
                >
                  {/* This span makes Tooltip work with disabled Checkbox. Disabled elements do not send events, so we need this wrapper */}
                  <span className="last-30-days-and-onwards">
                    <Checkbox
                      disabled={!!deliveryDateExists}
                      className="last-30-days-and-onwards-checkbox"
                      checked={value as boolean}
                      id="last30DaysAndOnwards"
                      color="primary"
                      name={name}
                      onChange={onChange}
                      value={value}
                    />
                  </span>
                </Tooltip>
              );
            }}
            control={control}
            {...props}
          />
        }
        label={props.label}
      />
    );
};

const stateMenuItems = [
  <MenuItem key={SearchShipmentsPostBodyStateEnum.EiVarastossa} value={SearchShipmentsPostBodyStateEnum.EiVarastossa}>
    Ei varastossa
  </MenuItem>,
  <MenuItem key={SearchShipmentsPostBodyStateEnum.Noudettavissa} value={SearchShipmentsPostBodyStateEnum.Noudettavissa}>
    Noudettavissa
  </MenuItem>,
  <MenuItem key={SearchShipmentsPostBodyStateEnum.Noudettu} value={SearchShipmentsPostBodyStateEnum.Noudettu}>
    Noudettu
  </MenuItem>,
  <MenuItem key={SearchShipmentsPostBodyStateEnum.Toimitettu} value={SearchShipmentsPostBodyStateEnum.Toimitettu}>
    Toimitettu
  </MenuItem>,
  <MenuItem key={SearchShipmentsPostBodyStateEnum.Peruttu} value={SearchShipmentsPostBodyStateEnum.Peruttu}>
    Peruttu
  </MenuItem>,
  <MenuItem key={SearchShipmentsPostBodyStateEnum.Odottaa} value={SearchShipmentsPostBodyStateEnum.Odottaa}>
    Odottaa
  </MenuItem>,
];

export const FormSelect: React.FC<FormInputProps> = (props) => {
  const { control } = useFormContext<Fields>();
  return (
    <Controller<Fields>
      render={({ field }) => (
        <FormControl>
          <InputLabel id="state_label">Tila</InputLabel>
          <Select {...field} labelId="state_label" type={props.type} label={props.label}>
            <EmptySelectMenuItem value="" />
            {stateMenuItems}
          </Select>
        </FormControl>
      )}
      control={control}
      fullWidth={true}
      {...props}
    />
  );
};
