import React from 'react';
import { DesktopTimePicker, MultiSectionDigitalClock, TimePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { TimeValidationError } from '@mui/x-date-pickers';
import { timeFormat, timeFormatWithSeconds } from '../../formatters';
import { mobileActionBar } from './StandardDatePicker';

export const getTimeStringOrNull = (date: DateTime): string | null => {
  return date ? date.set({ second: 0, millisecond: 0 }).toFormat(timeFormatWithSeconds) : null;
};

export function TimeClockView(props: any) {
  return (
    <MultiSectionDigitalClock
      timeSteps={{ minutes: 1 }}
      value={props.value}
      onViewChange={props.onViewChange}
      onChange={props.onChange}
      sx={{ justifyContent: 'center' }}
    />
  );
}

export const StandardTimePicker: React.FC<TimePickerProps<any>> = (props) => {
  const [error, setError] = React.useState<TimeValidationError | null>(null);
  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'invalidDate': {
        return 'Virheellinen aika';
      }
      default: {
        return '';
      }
    }
  }, [error]);
  return (
    <DesktopTimePicker
      {...props}
      format={timeFormat}
      value={props.value ? DateTime.fromJSDate(props.value) : props.value}
      onError={(newError) => setError(newError)}
      viewRenderers={{ hours: TimeClockView, minutes: TimeClockView }}
      slotProps={{
        actionBar: mobileActionBar,
        textField: {
          ...props.slotProps?.textField,
          placeholder: '',
          size: 'small',
          variant: 'standard',
          error: (props.slotProps?.textField as any).error ?? Boolean(error),
          helperText: (props.slotProps?.textField as any).helperText ?? errorMessage,
        },
      }}
    />
  );
};

export const StringTimePicker: React.FC<TimePickerProps<any>> = (props) => {
  return (
    <DesktopTimePicker
      {...props}
      format={timeFormat}
      value={props.value ? DateTime.fromFormat(props.value ?? '', timeFormatWithSeconds) : props.value}
      viewRenderers={{ hours: TimeClockView, minutes: TimeClockView }}
      slotProps={{
        actionBar: mobileActionBar,
        textField: {
          ...props.slotProps?.textField,
          placeholder: '',
          size: 'small',
          variant: 'standard',
          error: (props.slotProps?.textField as any).error,
          helperText: (props.slotProps?.textField as any).helperText,
        },
      }}
    />
  );
};
