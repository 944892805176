/* tslint:disable */
/* eslint-disable */
/**
 * Timeweb 2.0
 * A JSON HTTP API for Timeweb 2.0 service.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A Shipment serialized for an organization with limited information. Contains only schedule related fields.
 * @export
 * @interface OrganizationScheduleShipment42
 */
export interface OrganizationScheduleShipment42 {
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  readonly organization_id: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  billing_office_organization_specific_id: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  state: OrganizationScheduleShipment42StateEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  job_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  other_contract_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_office_organization_specific_id: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_address: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_phone_number: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_city: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  delivery_office_organization_specific_id: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  delivery_name: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  delivery_address: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  delivery_postal_code: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  delivery_city: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  note: string | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  length_ldm: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  weight_kg: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  chargeable_weight_kg: number | null;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  volume_m3: number;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  delivered_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  delivery_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  delivery_window_ends_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  recipient: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  reference_number: string | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  agreed_delivery_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  agreed_delivery_window_ends_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_window_starts_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  pickup_window_ends_at: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationScheduleShipment42
   */
  is_express_delivery: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationScheduleShipment42
   */
  requires_combination_vehicle: boolean;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  readonly load_id: number | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  readonly updated_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  readonly updated_by: string | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  arrived_to_pickup_location_at: Date | null;
  /**
   *
   * @type {Date}
   * @memberof OrganizationScheduleShipment42
   */
  arrived_to_delivery_location_at: Date | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  readonly customer_distribution_area?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationScheduleShipment42
   */
  is_adr_delivery: boolean;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  custom_pricing_category_id: number | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationScheduleShipment42
   */
  unloaded_with_customer_permission: boolean;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  hourly_work_hours?: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationScheduleShipment42
   */
  billing_reference_number: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationScheduleShipment42
   */
  requires_hoist: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationScheduleShipment42
   */
  is_line_haul_transport: boolean;
  /**
   *
   * @type {number}
   * @memberof OrganizationScheduleShipment42
   */
  planned_unload_hour: number | null;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationScheduleShipment42StateEnum {
  EiVarastossa = 'ei varastossa',
  Noudettavissa = 'noudettavissa',
  Noutokohteessa = 'noutokohteessa',
  Noudettu = 'noudettu',
  Toimituskohteessa = 'toimituskohteessa',
  Toimitettu = 'toimitettu',
  Peruttu = 'peruttu',
  Odottaa = 'odottaa',
}

export function OrganizationScheduleShipment42FromJSON(json: any): OrganizationScheduleShipment42 {
  return OrganizationScheduleShipment42FromJSONTyped(json, false);
}

export function OrganizationScheduleShipment42FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationScheduleShipment42 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    organization_id: json['organization_id'],
    billing_office_organization_specific_id: json['billing_office_organization_specific_id'],
    state: json['state'],
    job_number: json['job_number'],
    other_contract_number: json['other_contract_number'],
    pickup_office_organization_specific_id: json['pickup_office_organization_specific_id'],
    pickup_name: json['pickup_name'],
    pickup_address: json['pickup_address'],
    pickup_postal_code: json['pickup_postal_code'],
    pickup_phone_number: json['pickup_phone_number'],
    pickup_city: json['pickup_city'],
    delivery_office_organization_specific_id: json['delivery_office_organization_specific_id'],
    delivery_name: json['delivery_name'],
    delivery_address: json['delivery_address'],
    delivery_postal_code: json['delivery_postal_code'],
    delivery_city: json['delivery_city'],
    note: json['note'],
    length_ldm: json['length_ldm'],
    weight_kg: json['weight_kg'],
    chargeable_weight_kg: json['chargeable_weight_kg'],
    volume_m3: json['volume_m3'],
    delivered_at: json['delivered_at'] === null ? null : new Date(json['delivered_at']),
    delivery_window_starts_at:
      json['delivery_window_starts_at'] === null ? null : new Date(json['delivery_window_starts_at']),
    delivery_window_ends_at:
      json['delivery_window_ends_at'] === null ? null : new Date(json['delivery_window_ends_at']),
    recipient: json['recipient'],
    reference_number: json['reference_number'],
    agreed_delivery_window_starts_at:
      json['agreed_delivery_window_starts_at'] === null ? null : new Date(json['agreed_delivery_window_starts_at']),
    agreed_delivery_window_ends_at:
      json['agreed_delivery_window_ends_at'] === null ? null : new Date(json['agreed_delivery_window_ends_at']),
    pickup_window_starts_at:
      json['pickup_window_starts_at'] === null ? null : new Date(json['pickup_window_starts_at']),
    pickup_window_ends_at: json['pickup_window_ends_at'] === null ? null : new Date(json['pickup_window_ends_at']),
    is_express_delivery: json['is_express_delivery'],
    requires_combination_vehicle: json['requires_combination_vehicle'],
    load_id: json['load_id'],
    updated_at: json['updated_at'] === null ? null : new Date(json['updated_at']),
    updated_by: json['updated_by'],
    arrived_to_pickup_location_at:
      json['arrived_to_pickup_location_at'] === null ? null : new Date(json['arrived_to_pickup_location_at']),
    arrived_to_delivery_location_at:
      json['arrived_to_delivery_location_at'] === null ? null : new Date(json['arrived_to_delivery_location_at']),
    customer_distribution_area: !exists(json, 'customer_distribution_area')
      ? undefined
      : json['customer_distribution_area'],
    is_adr_delivery: json['is_adr_delivery'],
    custom_pricing_category_id: json['custom_pricing_category_id'],
    unloaded_with_customer_permission: json['unloaded_with_customer_permission'],
    hourly_work_hours: !exists(json, 'hourly_work_hours') ? undefined : json['hourly_work_hours'],
    billing_reference_number: json['billing_reference_number'],
    requires_hoist: json['requires_hoist'],
    is_line_haul_transport: json['is_line_haul_transport'],
    planned_unload_hour: json['planned_unload_hour'],
  };
}

export function OrganizationScheduleShipment42ToJSON(value?: OrganizationScheduleShipment42 | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    billing_office_organization_specific_id: value.billing_office_organization_specific_id,
    state: value.state,
    job_number: value.job_number,
    other_contract_number: value.other_contract_number,
    pickup_office_organization_specific_id: value.pickup_office_organization_specific_id,
    pickup_name: value.pickup_name,
    pickup_address: value.pickup_address,
    pickup_postal_code: value.pickup_postal_code,
    pickup_phone_number: value.pickup_phone_number,
    pickup_city: value.pickup_city,
    delivery_office_organization_specific_id: value.delivery_office_organization_specific_id,
    delivery_name: value.delivery_name,
    delivery_address: value.delivery_address,
    delivery_postal_code: value.delivery_postal_code,
    delivery_city: value.delivery_city,
    note: value.note,
    length_ldm: value.length_ldm,
    weight_kg: value.weight_kg,
    chargeable_weight_kg: value.chargeable_weight_kg,
    volume_m3: value.volume_m3,
    delivered_at: value.delivered_at === null ? null : value.delivered_at.toISOString(),
    delivery_window_starts_at:
      value.delivery_window_starts_at === null ? null : value.delivery_window_starts_at.toISOString(),
    delivery_window_ends_at:
      value.delivery_window_ends_at === null ? null : value.delivery_window_ends_at.toISOString(),
    recipient: value.recipient,
    reference_number: value.reference_number,
    agreed_delivery_window_starts_at:
      value.agreed_delivery_window_starts_at === null ? null : value.agreed_delivery_window_starts_at.toISOString(),
    agreed_delivery_window_ends_at:
      value.agreed_delivery_window_ends_at === null ? null : value.agreed_delivery_window_ends_at.toISOString(),
    pickup_window_starts_at:
      value.pickup_window_starts_at === null ? null : value.pickup_window_starts_at.toISOString(),
    pickup_window_ends_at: value.pickup_window_ends_at === null ? null : value.pickup_window_ends_at.toISOString(),
    is_express_delivery: value.is_express_delivery,
    requires_combination_vehicle: value.requires_combination_vehicle,
    arrived_to_pickup_location_at:
      value.arrived_to_pickup_location_at === null ? null : value.arrived_to_pickup_location_at.toISOString(),
    arrived_to_delivery_location_at:
      value.arrived_to_delivery_location_at === null ? null : value.arrived_to_delivery_location_at.toISOString(),
    is_adr_delivery: value.is_adr_delivery,
    custom_pricing_category_id: value.custom_pricing_category_id,
    unloaded_with_customer_permission: value.unloaded_with_customer_permission,
    hourly_work_hours: value.hourly_work_hours,
    billing_reference_number: value.billing_reference_number,
    requires_hoist: value.requires_hoist,
    is_line_haul_transport: value.is_line_haul_transport,
    planned_unload_hour: value.planned_unload_hour,
  };
}
