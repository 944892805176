import {
  DriverWithWorkHoursAndTimeOffs,
  DriverWithWorkHoursAndTimeOffsProfessionEnum,
  TimeOff,
  WorkHour,
} from '../../api';
import { Range, SetDateRangeAction, getCurrentSalaryPeriodDateRange } from '../../components/DateRangePicker';
import { max, maxBy, sumBy } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { NotificationType, SetMessageAction } from '../../components/Notification';
import { isWeekend, roundToTwoDecimals } from '../../utils';
import { getLunchBreak, multipleDateRangeOverlaps } from '../workHours/workHours';
import { SetLoadingAction } from '../../components/Loading';

type DriverDailySummary = {
  workHourAndTimeOffNetHoursSum: number;
  timeOffType: number;
  timeOffsOrWorkHoursOverlap: boolean;
};

type DateAndDriverInfo = {
  [key: number]: DriverDailySummary;
};

export type DriverWithWorkDaysAndSummary = {
  driver: string;
  profession: DriverWithWorkHoursAndTimeOffsProfessionEnum;
  sum: string;
  overUnder: number;
} & DateAndDriverInfo;

export interface State {
  driverAndDates: DriverWithWorkDaysAndSummary[];
  notification: NotificationType;
  dateRange: Range | null;
  isLoading: boolean;
}

export const workHourDateFormat = 'yyyyMMdd';

const hoursInAWorkDay = 8;

export const initialState: State = {
  notification: {
    message: null,
  },
  dateRange: getCurrentSalaryPeriodDateRange(),
  isLoading: true,
  driverAndDates: [],
};

export type Action =
  | {
      type: 'INITIALIZE';
      payload: {
        driverWithWorkHoursAndTimeOffs: DriverWithWorkHoursAndTimeOffs[];
      };
    }
  | SetMessageAction
  | SetDateRangeAction
  | SetLoadingAction;

const getNumberOfWorkHoursInDateRange = (dateRange: State['dateRange'], workHours: WorkHour[], timeOffs: TimeOff[]) => {
  if (!dateRange?.start || !dateRange?.end) {
    return 0;
  }
  const workHoursEndDate = maxBy(workHours, 'date')?.date;
  const timeOffsEndDate = maxBy(timeOffs, 'date')?.date;
  const yesterday = DateTime.now().startOf('day').minus({ days: 1 }).toJSDate();
  const dateRangeEnd = yesterday < dateRange?.end.toJSDate() ? yesterday : dateRange?.end.toJSDate();
  const latestDate = max([workHoursEndDate, timeOffsEndDate, dateRangeEnd]) ?? dateRangeEnd;

  const startDate = dateRange.start;
  const endDate = DateTime.fromJSDate(latestDate).endOf('day');

  let hours = 0;
  Interval.fromDateTimes(startDate, endDate)
    .splitBy({ day: 1 })
    .map((d) => {
      if (
        !isWeekend(d.start) ||
        timeOffs.find((t) => t.date && DateTime.fromJSDate(t.date).startOf('day').equals(d.start.startOf('day')))
      ) {
        hours += hoursInAWorkDay;
      }
    });
  return hours;
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        driverAndDates: action.payload.driverWithWorkHoursAndTimeOffs
          .filter((d) => d.employee_number)
          .map((d) => {
            const workHoursWithoutLunchBreaks = d.work_hours.map((wH) => {
              const sameDayWorkHours = d.work_hours.filter(
                (workHour) =>
                  workHour.date &&
                  wH.date &&
                  DateTime.fromJSDate(workHour.date).startOf('day').equals(DateTime.fromJSDate(wH.date).startOf('day')),
              );

              const isLunchBreak = getLunchBreak(sameDayWorkHours);
              return {
                ...wH,
                net_hours:
                  isLunchBreak.workHourId === wH.id && isLunchBreak.type === '0.5' && wH.net_hours
                    ? wH.net_hours - 0.5
                    : wH.net_hours,
              };
            });
            const driverWorkHoursSum = sumBy(workHoursWithoutLunchBreaks, 'net_hours');
            const driverTimeOffsSum = d.time_offs.length * hoursInAWorkDay;
            const driverSum = driverWorkHoursSum + driverTimeOffsSum;
            const numberOfHoursInWorkHoursAndTimeOffs = getNumberOfWorkHoursInDateRange(
              state.dateRange,
              d.work_hours,
              d.time_offs,
            );

            const dateRangeInterval =
              state.dateRange?.start &&
              state.dateRange?.end &&
              Interval.fromDateTimes(state.dateRange.start, state.dateRange.end);

            const dateAndDriverInfo: DateAndDriverInfo = Object.fromEntries(
              (dateRangeInterval as Interval).splitBy({ day: 1 }).map((key) => {
                const dateTime = key.start;
                const dailyDriverWorkHours = workHoursWithoutLunchBreaks.filter(
                  (wH) => wH.date && DateTime.fromJSDate(wH.date).startOf('day').equals(dateTime.startOf('day')),
                );
                const dailyDriverTimeOffs = d.time_offs.find(
                  (tO) => tO.date && DateTime.fromJSDate(tO.date).startOf('day').equals(dateTime.startOf('day')),
                );
                const dailyDriverWorkHoursSum = sumBy(dailyDriverWorkHours, 'net_hours');
                const dailyDriverTimeOffsSum = dailyDriverTimeOffs ? hoursInAWorkDay : 0;
                const dailySum = dailyDriverWorkHoursSum + dailyDriverTimeOffsSum;
                const timeOffType = dailyDriverTimeOffs ? dailyDriverTimeOffs.time_off_type : 0;
                const overlaps =
                  (dailyDriverWorkHours.length > 0 &&
                    multipleDateRangeOverlaps(dailyDriverWorkHours, dailyDriverWorkHours[0])) ||
                  Boolean(dailyDriverWorkHours.length > 0 && dailyDriverTimeOffs);
                return [
                  dateTime.toFormat(workHourDateFormat),
                  {
                    workHourAndTimeOffNetHoursSum: roundToTwoDecimals(dailySum),
                    timeOffType: timeOffType,
                    timeOffsOrWorkHoursOverlap: overlaps,
                  },
                ];
              }),
            );
            return {
              driver: d.employee_name,
              profession: d.profession as DriverWithWorkHoursAndTimeOffsProfessionEnum,
              sum: `${roundToTwoDecimals(driverSum)} / ${numberOfHoursInWorkHoursAndTimeOffs}`,
              overUnder: roundToTwoDecimals(driverSum - numberOfHoursInWorkHoursAndTimeOffs),
              ...dateAndDriverInfo,
            };
          }),
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        notification: {
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
  }
};
