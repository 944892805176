import React, { Dispatch, useEffect, useReducer } from 'react';
import {
  api,
  Organization,
  OrganizationShipmentPostBody,
  OrganizationShipmentPostBodyStateEnum,
  OrganizationShipmentResponseBody,
  ShipmentPostBody,
  ShipmentPostBodyStateEnum,
  ShipmentResponseBody,
  ShipmentRowStateEnum,
  User,
} from '../../api';
import Main from '../../components/Main';
import {
  Action,
  getAgreedDeliveryWindow,
  getInitialState,
  getPickupWindow,
  getShipmentDefaultValues,
  reducer,
  ShipmentLocationState,
  ShipmentRowWithTableData,
  State,
  updateRows,
} from './shipment.state';
import { Loading } from '../../components/Loading';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { ShipmentRowTable, WithIsNew } from './components/ShipmentRowTable';
import { SaveButton } from '../../components/SaveButton';
import { Header } from '../../components/Header';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { StyledForm } from '../../components/StyledComponents/StyledForm';
import { useCurrentUser, useCurrentUserOrganization } from '../../hooks/useCurrentUser';
import { canAccessCoordination, canAccessPricing } from '../../utils';
import { AllShipmentFields } from './components/allShipmentFields';

const getOrganizationShipmentPostBodyFromState = (state: State): OrganizationShipmentPostBody => {
  const pickupWindow = getPickupWindow(state.fields);
  const agreedDeliveryWindow = getAgreedDeliveryWindow(state.fields);
  return {
    agreed_delivery_window_starts_at: agreedDeliveryWindow.startsAt.toJSDate(),
    agreed_delivery_window_ends_at: agreedDeliveryWindow.endsAt.toJSDate(),
    billing_office_organization_specific_id: state.fields.billing_office_organization_specific_id.value || '',
    delivery_office_organization_specific_id: state.fields.delivery_office_organization_specific_id.value || null,
    delivery_name: state.fields.delivery_name.value,
    delivery_address: state.fields.delivery_address.value,
    delivery_city: state.fields.delivery_city.value,
    delivery_phone_number: state.fields.delivery_phone_number.value || null,
    delivery_postal_code: state.fields.delivery_postal_code.value,
    length_ldm: state.fields.length_ldm.value || 0,
    note: state.fields.note.value,
    orderer: state.fields.orderer.value || null,
    orderer_phone_number: state.fields.orderer_phone_number.value || null,
    reference_number: state.fields.reference_number.value,
    job_number: state.fields.job_number.value,
    pickup_office_organization_specific_id: state.fields.pickup_office_organization_specific_id.value || null,
    pickup_name: state.fields.pickup_name.value,
    pickup_address: state.fields.pickup_address.value,
    pickup_city: state.fields.pickup_city.value,
    pickup_phone_number: state.fields.pickup_phone_number.value || null,
    pickup_postal_code: state.fields.pickup_postal_code.value,
    pickup_window_starts_at: pickupWindow?.startsAt.toJSDate() ?? null,
    pickup_window_ends_at: pickupWindow?.endsAt.toJSDate() ?? null,
    weight_kg: state.fields.weight_kg.value || 0,
    volume_m3: state.fields.volume_m3.value || 0,
    pricing_model: state.fields.pricing_model.value || null,
    custom_pricing_category_id: state.fields.custom_pricing_category_id.value || null,
    state: state.fields.state.value as unknown as OrganizationShipmentPostBodyStateEnum,
    other_contract_number: state.fields.other_contract_number.value || null,
    is_adr_delivery: state.fields.is_adr_delivery.value || false,
    delivery_phone_number_secondary: state.fields.delivery_phone_number_secondary.value || null,
    billing_reference_number: state.fields.billing_reference_number.value || null,
    requires_combination_vehicle: state.fields.requires_combination_vehicle.value || false,
    requires_hoist: state.fields.requires_hoist.value || false,
    is_express_delivery: state.fields.is_express_delivery.value || false,
    rows: state.rows.map((row) => {
      const { tableData, ...rest } = row;
      rest.serial_number = rest.serial_number ?? null;
      rest.parcel_id = rest.parcel_id ?? null;
      rest.quantity = rest.quantity ?? null;
      rest.weight_per_piece_kg = rest.weight_per_piece_kg ?? null;
      rest.state = ShipmentRowStateEnum.ReadyForPickup;
      return rest;
    }) as OrganizationShipmentPostBody['rows'],
  };
};

const getShipmentPostBodyFromState = (state: State, currentUser?: User): ShipmentPostBody => {
  return {
    ...getOrganizationShipmentPostBodyFromState(state),
    hourly_work_hours: state.fields.hourly_work_hours.value || 0,
    hourly_work_reason: state.fields.hourly_work_reason.value,
    job_number: state.fields.job_number.value,
    load_id: state.fields.load_id.value || null,
    order_in_load: state.originalShipment?.order_in_load ?? null,
    organization_id: state.fields.organization_id.value,
    other_contract_number: state.fields.other_contract_number.value || null,
    recipient: state.originalShipment?.recipient ?? null,
    state: state.fields.state.value as unknown as ShipmentPostBodyStateEnum,
    wait_reason: state.fields.wait_reason.value,
    weight_kg: state.fields.weight_kg.value || 0,
    wait_hours: state.fields.wait_hours.value || 0,
    ...(canAccessPricing(currentUser) && {
      has_contract_price: state.fields.has_contract_price.value || false,
      legacy_price_basis: state.fields.legacy_price_basis.value,
      price: state.fields.price.value || 0,
      has_additional_hourly_pricing: state.fields.has_additional_hourly_pricing.value || false,
      legacy_etaisyys_field: state.fields.legacy_etaisyys_field.value || null,
      has_distance_been_fixed: state.fields.has_distance_been_fixed.value || false,
    }),
    rows: state.rows.map((row) => {
      const { tableData, ...rest } = row as ShipmentRowWithTableData & WithIsNew;
      rest.serial_number = rest.serial_number ?? null;
      rest.parcel_id = rest.parcel_id ?? null;
      rest.quantity = rest.quantity ?? null;
      rest.weight_per_piece_kg = rest.weight_per_piece_kg ?? null;
      rest.state = ShipmentRowStateEnum.ReadyForPickup;
      if (rest.isNew) {
        const { id, ...rowWithOutId } = rest;
        return rowWithOutId;
      } else {
        return rest;
      }
    }) as ShipmentPostBody['rows'],
    additional_services: state.additionalServiceFields.map((field) => ({
      additional_service_id: field.additionalService.id,
      shipment_additional_service_id: field.shipmentAdditionalServiceId,
      amount: field.value || 0,
    })),
  };
};

const saveNewShipment = async (
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
  currentUser?: User,
) => {
  if (!currentUser?.organization_id) {
    throw new Error('Missing org id');
  }
  if (!state.isValid && !canAccessCoordination(currentUser)) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });
  try {
    let shipmentResponse: OrganizationShipmentResponseBody | ShipmentResponseBody;
    if (canAccessCoordination(currentUser)) {
      shipmentResponse = await api.shipments.createShipment({
        shipmentPostBody: getShipmentPostBodyFromState(state, currentUser),
      });
    } else {
      shipmentResponse = await api.organizationShipments.createOrganizationShipment({
        organizationId: state.fields.organization_id.value,
        organizationShipmentPostBody: getOrganizationShipmentPostBodyFromState(state),
      });
    }
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Toimitus tallennettu!' } });
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false });
      navigate(`/shipments/${shipmentResponse.data.id}`);
    }, 1000);
  } catch (err) {
    dispatch(tryAgainMessage);
    console.error(err);
    dispatch({ type: 'SET_LOADING', payload: false });
  }
};

const load = async (
  userOrganization: Organization,
  currentUser: User,
  location: Location<ShipmentLocationState>,
  dispatch: Dispatch<Action>,
) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let organizations: Organization[] = [];
    if (currentUser.is_multi_organization || currentUser.is_superuser) {
      organizations = (await api.organizations.getOrganizations({})).data;
    } else {
      organizations = [userOrganization];
    }
    const organization = location.state?.shipment
      ? (organizations.find((org) => org.id === location.state?.shipment.organization_id) ?? userOrganization)
      : userOrganization;
    const [organizationOfficesResponse, organizationAdditionalServicesResponse] = await Promise.all([
      api.organizationOffices.getOrganizationOffices({ organizationId: organization.id }),
      api.organizationAdditionalServices.getOrganizationAdditionalServices({ organizationId: organization.id }),
    ]);
    let ordererValue = '';
    if (currentUser.first_name) {
      ordererValue = (currentUser.first_name + ' ' + (currentUser.last_name || '')).trim();
    } else {
      ordererValue = currentUser.username;
    }
    let pricingModelsResponse;
    if (canAccessCoordination(currentUser)) {
      pricingModelsResponse = (await api.pricing.getPricingModels()).data;
    } else {
      pricingModelsResponse = (
        await api.organizationPricing.getOrganizationPricingModels({
          organizationId: organization.id,
        })
      ).data;
    }
    dispatch({
      type: 'INITIALIZE_SHIPMENT',
      payload: {
        shipment: {
          ...(location.state?.shipment ? location.state?.shipment : getShipmentDefaultValues(userOrganization)),
          orderer: ordererValue,
          orderer_phone_number: currentUser.gsm || null,
          load_id: null,
          order_in_load: null,
        },
        shipmentRows: location.state?.shipmentRows ?? [],
        currentUser,
        organization,
        organizationOffices: organizationOfficesResponse.data,
        organizationAdditionalServices: organizationAdditionalServicesResponse.data,
        shipmentAdditionalServices:
          location.state?.additionalServiceFields.map((additionalServiceField) => ({
            quantity: additionalServiceField.value || 0,
            additional_service_id: additionalServiceField.additionalService.id,
          })) ?? [],
        organizations: organizations ?? [organization],
        isNewShipment: true,
        pricingModels: pricingModelsResponse ?? [],
      },
    });
    if (location.state?.shipment) {
      dispatch({
        type: 'VALIDATE_FIELDS',
      });
    }
  } catch (err) {
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Sivun lataus epäonnistui', severity: 'error' } });
    console.error(err);
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

const CreateShipment: React.FC = () => {
  const currentUser = useCurrentUser();
  const userOrganization = useCurrentUserOrganization();
  const [state, dispatch] = useReducer(reducer, getInitialState(currentUser));
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (userOrganization === undefined || currentUser === undefined) {
      return;
    }
    dispatch({
      type: 'CLEAR_SHIPMENT',
      payload: { currentUser },
    });
    load(userOrganization, currentUser, location, dispatch);
  }, [userOrganization, currentUser]);

  const canSave = canAccessCoordination(currentUser) || (state.isValid && state.canSave);

  return (
    <Main>
      <Loading isLoading={state.isLoading}></Loading>
      <StyledForm noValidate autoComplete="off">
        <Header title="Uusi toimitus">
          <SaveButton
            disabled={!canSave || state.isLoading}
            color={!state.isValid ? 'warning' : 'success'}
            id="save-shipment-button"
            tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              saveNewShipment(state, dispatch, navigate, currentUser);
              event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
            }}
          >
            Luo toimitus
          </SaveButton>
        </Header>
        <AllShipmentFields
          dispatch={dispatch}
          state={state}
          currentUser={currentUser}
          organization={userOrganization}
        />
        <div style={{ maxWidth: '100%' }}>
          <ShipmentRowTable user={currentUser} rows={state.rows} onRowsUpdate={(rows) => updateRows(rows, dispatch)} />
        </div>
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default CreateShipment;
