import React, { Dispatch, useEffect, useReducer } from 'react';
import Main from '../../components/Main';
import { Loading } from '../../components/Loading';
import { Action, getInitialState, reducer, State } from './car.state';
import { api, Car, CarPutBody, CarPutBodyTypeEnum, getAllPages } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import Notification, {
  checkFieldsMessage,
  getSnackbarPropsFromState,
  tryAgainMessage,
} from '../../components/Notification';
import { Header } from '../../components/Header';
import { SaveButton } from '../../components/SaveButton';
import { AllCarFields } from './components/inputs';
import { HeaderContainer } from '../../components/StyledComponents/HeaderContainer';
import { CarServiceTable } from './components/carServiceTable';
import { StyledForm } from '../../components/StyledComponents/StyledForm';

const load = async (licencePlate: Car['licence_plate'], dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const [carResponse, carsResponse, driversResponse] = await Promise.all([
      api.cars.getCarByLicencePlate({ carLicencePlate: licencePlate as string }),
      getAllPages(api.cars.getCars.bind(api.cars), { alsoInactive: true }),
      getAllPages(api.drivers.getDrivers.bind(api.drivers), {}),
    ]);
    dispatch({
      type: 'INITIALIZE_CAR',
      payload: {
        car: carResponse.data,
      },
    });
    dispatch({
      type: 'SET_CARS',
      payload: {
        cars: carsResponse,
      },
    });
    const carServicesResponse = (await api.carServices.getCarCarServices({ carId: carResponse.data.id })).data;
    dispatch({
      type: 'SET_CAR_SERVICES',
      payload: {
        carServices: carServicesResponse,
      },
    });
    dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: driversResponse,
      },
    });
    dispatch({
      type: 'VALIDATE_FIELDS',
    });
  } catch (err) {
    let message = 'Auton lataus epäonnistui!';
    switch ((err as any).status) {
      case 404:
        message = 'Autoa ei löytynyt!';
        break;
    }
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message,
        severity: 'error',
      },
    });
  }
  dispatch({
    type: 'SET_LOADING',
    payload: false,
  });
};

export const getCarPutBodyFromState = (state: State): CarPutBody => {
  return {
    id: state.fields.id.value,
    licence_plate: state.fields.licence_plate.value,
    is_active: state.fields.is_active.value,
    default_driver_id: state.fields.default_driver_id.value || null,
    car_identifier: state.fields.car_identifier.value || null,
    car_number: state.fields.car_number.value || null,
    manufacturer: state.fields.manufacturer.value,
    model: state.fields.model.value,
    type: state.fields.type.value as unknown as CarPutBodyTypeEnum,
    imei: state.fields.imei.value || null,
    vin_number: state.fields.vin_number.value || null,
    first_registeration_date: state.fields.first_registeration_date.value || null,
    vehicle_inspection_date: state.fields.vehicle_inspection_date.value || null,
    transport_licence: state.fields.transport_licence.value || null,
    transport_licence_date: state.fields.transport_licence_date.value || null,
    tyres: state.fields.tyres.value || null,
    hoist: state.fields.hoist.value || null,
    platform_details: state.fields.platform_details.value || null,
    is_adr: state.fields.is_adr.value,
    unladen_weight: state.fields.unladen_weight.value || null,
    gross_weight: state.fields.gross_weight.value || null,
    height: state.fields.height.value || null,
    fuel_card: state.fields.fuel_card.value || null,
    fuel_id: state.fields.fuel_id.value || null,
    hoist_inspection_date: state.fields.hoist_inspection_date.value || null,
    note: state.fields.note.value || null,
    is_coordination: state.fields.is_coordination.value,
    default_trailer_id: state.fields.default_trailer_id.value || null,
  };
};

const saveCar = async (
  carId: Car['id'],
  state: State,
  dispatch: Dispatch<Action>,
  navigate: ReturnType<typeof useNavigate>,
) => {
  if (!carId) {
    throw new Error('Missing car id');
  }
  if (!state.isValid) {
    dispatch(checkFieldsMessage);
    return;
  }
  dispatch({ type: 'SET_LOADING', payload: true });

  try {
    const updatedCar = await api.cars.updateCar({
      carId,
      carPutBody: getCarPutBodyFromState(state),
    });
    await Promise.all(
      state.carServices.map((carService) => {
        if (carService.isNew) {
          const { id, ...carServiceWithoutId } = carService;
          return api.carServices.createCarService({
            carServicePostBody: carServiceWithoutId,
          });
        }
        if (carService.isUpdated) {
          return api.carServices.updateCarService({
            carServiceId: carService.id,
            carServicePutBody: carService,
          });
        }
      }),
    );
    dispatch({ type: 'SET_MESSAGE', payload: { message: 'Auto tallennettu!' } });
    load(updatedCar.data.licence_plate as string, dispatch);
    if (state.originalCar?.licence_plate !== updatedCar.data.licence_plate) {
      setTimeout(() => {
        dispatch({ type: 'SET_LOADING', payload: false });

        navigate(`/cars/${updatedCar.data.licence_plate}`);
      }, 1000);
    }
  } catch (err) {
    dispatch(tryAgainMessage);
    dispatch({ type: 'SET_LOADING', payload: false });

    console.error(err);
  }
};

type EditCarParams = {
  carLicencePlate?: string;
};

const EditCar: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { carLicencePlate } = useParams<EditCarParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (carLicencePlate) {
      load(carLicencePlate || '', dispatch);
    }
  }, [carLicencePlate]);

  return (
    <Main>
      <Loading isLoading={state.isLoading} />
      <StyledForm noValidate autoComplete="off">
        <HeaderContainer>
          <Header title={`Auto ${carLicencePlate || ''}`}>
            <SaveButton
              disabled={!state.isValid || state.isLoading}
              id="save-car-button"
              tooltip={!state.isValid ? 'Kaikkia pakollisia kenttiä ei ole täytetty tai ne sisältävät virheitä' : ''}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                if (state.originalCar) {
                  saveCar(state.originalCar.id, state, dispatch, navigate);
                  event.stopPropagation(); // Without this the event ends up to Snackbar and it closes
                }
              }}
            >
              Tallenna
            </SaveButton>
          </Header>
        </HeaderContainer>
        <AllCarFields state={state} dispatch={dispatch} showId={true} />
        <CarServiceTable carId={state.fields.id.value} carServices={state.carServices} dispatch={dispatch} />
      </StyledForm>
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </Main>
  );
};

export default EditCar;
