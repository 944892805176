import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { MRT_Row, MRT_RowData, MRT_TableInstance } from 'material-react-table';
import React from 'react';

interface Props<TData extends MRT_RowData> extends BoxProps {
  row: MRT_Row<TData>;
  table: MRT_TableInstance<TData>;
  variant?: 'icon' | 'text';
  isValid: boolean;
  isLoading: boolean;
}

export const MRT_EditActionButtons = <TData extends MRT_RowData>({
  row,
  table,
  variant = 'icon',
  isValid,
  isLoading,
}: Props<TData>) => {
  const {
    getState,
    options: {
      icons: { CancelIcon, SaveIcon },
      localization,
      onEditingRowSave,
      onEditingRowCancel,
    },
    refs: { editInputRefs },
    setEditingRow,
  } = table;
  const { editingRow } = getState();

  const handleCancel = () => {
    onEditingRowCancel?.({ row, table });
    setEditingRow(null);
  };

  const handleSave = () => {
    //look for auto-filled input values
    Object.values(editInputRefs?.current)?.forEach((input) => {
      if (input.value !== undefined && (Object as any).hasOwn(editingRow?._valuesCache as object, input.name)) {
        (editingRow as any)._valuesCache[input.name] = input.value;
      }
    });
    onEditingRowSave?.({
      exitEditingMode: () => setEditingRow(null),
      row: editingRow ?? row,
      table,
      values: editingRow?._valuesCache ?? { ...row.original },
    });
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      {variant === 'icon' ? (
        <>
          <Tooltip arrow title={localization.cancel}>
            <IconButton
              disabled={isLoading}
              aria-label={localization.cancel}
              onClick={handleCancel}
              data-cy="cancel-row-button"
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={localization.save}>
            <span>
              <IconButton
                disabled={!isValid || isLoading}
                aria-label={localization.save}
                color="success"
                onClick={handleSave}
                data-cy="save-row-button"
              >
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <Button disabled={isLoading} onClick={handleCancel}>
            {localization.cancel}
          </Button>
          <Button disabled={!isValid || isLoading} onClick={handleSave} variant="contained">
            {localization.save}
          </Button>
        </>
      )}
    </Box>
  );
};
