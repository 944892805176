import React from 'react';
import { DatePickerProps, DesktopDatePicker, PickersActionBarAction } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { dateFormat } from '../../formatters';

export const getJSDateOrNull = (date: DateTime): Date | null => {
  return date ? date.set({ second: 0, millisecond: 0 }).toJSDate() : null;
};

export const mobileActionBar = (props: any) => ({
  actions: props.wrapperVariant === 'mobile' ? (['clear', 'accept'] as PickersActionBarAction[]) : [],
});

export const StandardDatePicker: React.FC<DatePickerProps<any>> = (props) => {
  return (
    <DesktopDatePicker
      {...props}
      format={dateFormat}
      value={props.value instanceof Date ? DateTime.fromJSDate(props.value) : props.value}
      slotProps={{
        actionBar: mobileActionBar,
        textField: {
          ...props.slotProps?.textField,
          placeholder: '',
          size: 'small',
          variant: (props.slotProps?.textField as any).variant ?? 'standard',
          error: (props.slotProps?.textField as any).error,
          helperText: (props.slotProps?.textField as any).helperText,
        },
      }}
    />
  );
};
