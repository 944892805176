import React, { Dispatch, ReactNode, useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  DateRangePickerProps,
  Range,
  getDateRangeFromUrl,
  getDefaultDateRange,
  updateDateRangeToUrl,
} from './MRTDateRangePicker';
import { MRT_Localization_FI } from 'material-react-table/locales/fi';
import {
  MaterialReactTable,
  MRT_TableState,
  MRT_PaginationState,
  MRT_TableOptions,
  MRT_TableInstance,
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_RowData,
} from 'material-react-table';
import { getViewSettings, updateViewSettings } from '../../views/settings';
import { MRTToolbar } from './MRTToolbar';
import { DateTime } from 'luxon';
import { dateFormat } from '../../formatters';
import { MRT_EditActionButtons } from './MRTEditActionButtons';
import { Cancel, Delete, Edit } from '@mui/icons-material';
import { Loading } from '../Loading';
import theme from '../../theme';

export const customDateTime = (rowA: any, rowB: any, columnId: string) => {
  const minJSDateInMilliseconds = -8640000000000000;
  const row1Value = rowA.getValue(columnId)
    ? DateTime.fromFormat(rowA.getValue(columnId), dateFormat).toJSDate().getTime()
    : minJSDateInMilliseconds;
  const row2Value = rowB.getValue(columnId)
    ? DateTime.fromFormat(rowB.getValue(columnId), dateFormat).toJSDate().getTime()
    : minJSDateInMilliseconds;
  return row1Value - row2Value;
};

export interface Props<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  header: string;
  settingsName: string;
  isLoading: boolean;
  customActions?: (props: { table: MRT_TableInstance<TData> }) => ReactNode;
  extraViewSettings?: Record<string, any>;
  dispatch: Dispatch<any>;
  isValid?: boolean;
  handleDeleteRow?: (id: number) => void;
  initialDateRange?: Range | null;
}

export const MRT = <TData extends MRT_RowData>({
  columns,
  data,
  header,
  settingsName,
  isLoading,
  customActions,
  dispatch,
  extraViewSettings,
  isValid,
  handleDeleteRow,
  initialDateRange,
  ...rest
}: Props<TData>) => {
  const [dateRange, setDateRange] = useState<Range>(getDateRangeFromUrl() ?? initialDateRange ?? getDefaultDateRange());

  const viewSettings = useMemo(() => getViewSettings(settingsName), []);

  const [showColumnFilters, setShowColumnFilters] = useState<MRT_TableState<TData>['showColumnFilters']>(
    viewSettings.showColumnFilters ?? rest.initialState?.showColumnFilters ?? true,
  );
  const [columnFilters, setColumnFilters] = useState<MRT_TableState<TData>['columnFilters']>(
    viewSettings.columnFilters ?? rest.initialState?.columnFilters ?? [],
  );
  const [sorting, setSorting] = useState<MRT_TableState<TData>['sorting']>(
    viewSettings.sorting ?? rest.initialState?.sorting ?? [],
  );
  const [density, setDensity] = useState<MRT_TableState<TData>['density']>(
    viewSettings.density ?? rest.initialState?.density ?? 'compact',
  );
  const [columnVisibility, setColumnVisibility] = useState<MRT_TableState<TData>['columnVisibility']>(
    viewSettings.columnVisibility ?? rest.initialState?.columnVisibility ?? {},
  );
  const [columnSizing, setColumnSizing] = useState<MRT_TableState<TData>['columnSizing']>(
    viewSettings.columnSizing ?? rest.initialState?.columnSizing ?? {},
  );
  const [columnOrder, setColumnOrder] = useState<MRT_TableState<TData>['columnOrder']>(
    viewSettings.columnOrder ?? rest.initialState?.columnOrder ?? [],
  );
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_TableState<TData>['columnFilterFns']>(
    viewSettings.columnFilterFns ?? rest.initialState?.showColumnFilters ?? {},
  );
  const [columnPinning, setColumnPinning] = useState<MRT_TableState<TData>['columnPinning']>(
    viewSettings.columnPinning ?? rest.initialState?.columnFilterFns ?? {},
  );
  const [grouping, setGrouping] = useState<MRT_TableState<TData>['grouping']>(
    viewSettings.grouping ?? rest.initialState?.grouping ?? [],
  );
  const [expanded, setExpanded] = useState<MRT_TableState<TData>['expanded']>(
    viewSettings.expanded ?? rest.initialState?.expanded ?? { expanded: false },
  );
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    viewSettings.pagination ??
      rest.initialState?.pagination ?? {
        pageIndex: 0,
        pageSize: 50,
      },
  );
  const [deletingRow, setDeletingRow] = useState<number | null>(null);

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableFilters: true,
    enableColumnActions: false,
    enableGrouping: true,
    enableHiding: true,
    enableColumnPinning: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    columnResizeMode: 'onEnd',
    enableBottomToolbar: true,
    positionToolbarAlertBanner: 'bottom',
    localization: { ...MRT_Localization_FI, filterArrIncludesSome: 'Sisältää' },
    renderToolbarInternalActions: ({ table }) => (
      <MRTToolbar table={table} dateRangePickerProps={dateRangePickerProps} dateRange={dateRange} header={header} />
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div
        data-cy="mrt-header"
        style={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
          alignSelf: 'center',
        }}
      >
        <div style={{ justifySelf: 'start' }}>
          <Typography variant="h3">
            {header} ({table.getFilteredRowModel().rows.length})
          </Typography>
        </div>
        <div style={{ justifySelf: 'end', alignItems: 'top' }}>{customActions && customActions({ table })}</div>
      </div>
    ),
    onDensityChange: setDensity,
    onShowColumnFiltersChange: setShowColumnFilters,
    onSortingChange: setSorting,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onColumnPinningChange: setColumnPinning,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    onGroupingChange: setGrouping,
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    state: {
      density,
      showColumnFilters,
      columnFilterFns,
      columnFilters,
      columnPinning,
      sorting,
      columnSizing,
      columnOrder,
      columnVisibility,
      grouping,
      pagination,
      expanded,
    },
    sortingFns: { customDateTime: customDateTime },
    filterFns: {
      dateFilterFn: (row, id, filterValue) => {
        return row.getValue<DateTime>(id).startOf('day').equals(DateTime.fromISO(filterValue));
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 120,
        Cell: ({ row, table }) => {
          return row.original.enableEditing === false ? (
            <></>
          ) : table.getState().editingRow?.id === row.id ? (
            <MRT_EditActionButtons row={row} table={table} isValid={isValid ?? true} isLoading={isLoading} />
          ) : deletingRow !== row.original.id ? (
            <>
              <IconButton
                onClick={() => {
                  table.setEditingRow(row);
                  setDeletingRow(null);
                }}
                disabled={isLoading}
                data-cy="edit-row-button"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => setDeletingRow(row.original.id)}
                disabled={isLoading}
                data-cy="delete-row-button"
              >
                <Delete color="error" />
              </IconButton>
            </>
          ) : (
            <div>
              <Tooltip arrow title={'Poista'}>
                <IconButton
                  onClick={() => handleDeleteRow && handleDeleteRow(row.original.id)}
                  disabled={isLoading}
                  data-cy="confirm-delete-row-button"
                >
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title={'Peruuta'}>
                <IconButton
                  onClick={() => setDeletingRow(null)}
                  disabled={isLoading}
                  data-cy="cancel-delete-row-button"
                >
                  <Cancel />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
    defaultColumn: {
      size: 100,
    },
    layoutMode: 'grid',
    muiColumnActionsButtonProps: {
      style: { padding: 0 },
    },
    muiFilterDatePickerProps: ({ column }) =>
      ({
        slotProps: {
          textField: {
            inputProps: {
              placeholder: '',
            },
            sx: {
              // always show date picker icon
              flexDirection: 'row-reverse',
            },
          },
        },
        value: column.getFilterValue() ? DateTime.fromISO(column.getFilterValue() as string) : null,
      }) as any,
    muiFilterTextFieldProps: {
      size: 'small',
      //filters take less space
      placeholder: '',
      margin: 'none',
      helperText: '',
    },
    muiTableBodyCellProps: ({ row, cell, column }) => {
      const tooltip = cell.getValue<string>() ?? '';
      return {
        style: {
          //detail panel is easier to read
          border: row.getIsExpanded() ? 'none' : undefined,
          //grouped rows are easier to read
          fontWeight: row.getIsGrouped() ? 'bold' : undefined,
          backgroundColor: row.getIsGrouped() ? theme.palette.table.dark : undefined,
          color: row.getIsGrouped() ? theme.palette.common.white : undefined,
        },
        //show tooltip on compact density with long fields
        ...(tooltip.length * 5 > column.getSize() &&
          density === 'compact' && {
            title: tooltip,
          }),
      };
    },
    muiTableBodyRowProps: ({ staticRowIndex }) => ({
      sx: {
        //striped table rows
        backgroundColor: staticRowIndex & 1 ? undefined : theme.palette.table.light,
      },
    }),
    muiDetailPanelProps: ({ row }) => ({
      sx: {
        //striped detail panel  rows
        backgroundColor: row.index & 1 ? undefined : theme.palette.table.light,
      },
    }),
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        whiteSpace: 'nowrap',
        //long headers wont spread into multiple rows
        ' .css-pj3kdi': {
          whiteSpace: 'nowrap',
        },
        //only show sort icons when sorted
        ' .MuiTableSortLabel-iconDirectionAsc': {
          display: column.getIsSorted() ? undefined : 'none',
        },
        // only show clear filter icons when filtered
        ' .css-1so9ih7-MuiButtonBase-root-MuiIconButton-root': {
          display: column.getIsFiltered() ? undefined : 'none',
        },
        // only show drag handles on hover
        ' .Mui-TableHeadCell-Content-Actions': {
          display: 'none',
          '> button': {
            padding: 0,
          },
        },
        '&:hover': {
          ' .Mui-TableHeadCell-Content-Actions': {
            display: 'contents',
          },
        },
      },
    }),
    muiTableHeadProps: {
      style: {
        opacity: 1,
        width: '100%',
      },
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: theme.palette.common.white,
      menuBackgroundColor: theme.palette.common.white,
      pinnedRowBackgroundColor: theme.palette.common.white,
      selectedRowBackgroundColor: theme.palette.common.white,
    }),
    ...rest,
  });

  useEffect(() => {
    updateViewSettings(settingsName, {
      ...viewSettings,
      showColumnFilters,
      columnFilters,
      sorting,
      columnVisibility,
      columnSizing,
      density,
      columnOrder,
      grouping,
      columnFilterFns,
      columnPinning,
      pagination,
      ...extraViewSettings,
    });
  }, [
    showColumnFilters,
    columnFilters,
    sorting,
    columnVisibility,
    columnSizing,
    density,
    columnOrder,
    grouping,
    columnFilterFns,
    columnPinning,
    pagination,
    extraViewSettings,
  ]);

  const dateRangePickerProps: DateRangePickerProps = {
    startDate: dateRange?.start ?? null,
    endDate: dateRange?.end ?? null,
    disabled: isLoading,
    onValue: (startDate, endDate): void => {
      const range = {
        start: startDate,
        end: endDate,
      };
      setDateRange(range);
      updateDateRangeToUrl(range);
    },
    salaryPeriodDateRange: settingsName === 'workHourMonitoring' || settingsName === 'workHours',
    billingSeasonRange: settingsName === 'jobs',
  };

  useEffect(() => {
    setDateRange(dateRange);
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: dateRange,
    });
  }, [dateRange]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <MaterialReactTable table={table} />
    </>
  );
};

export default MRT;
