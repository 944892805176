import React, { useEffect, useState } from 'react';
import { api, Assignment, FutureWorkHour, FutureWorkHourType } from '../../api';
import { Autocomplete, MenuItem, Select, SnackbarProps, TextField } from '@mui/material';
import Notification from '../../components/Notification';
import { Loading } from '../../components/Loading';
import { Range } from '../../components/DateRangePicker';
import {
  MaterialTableHeader,
  materialTableIcons,
  materialTableLocalization,
  materialTableOptions,
} from '../../components/MaterialTable';
import MaterialTable, { Column, EditComponentProps } from '@material-table/core';
import { dateFormat, formatFutureWorkHourType, formatWeekDay, timeFormat } from '../../formatters';
import { DateTime, Duration } from 'luxon';
import { StandardTimePicker } from '../../components/DateAndTimePickers/StandardTimePicker';
import { StandardDatePicker, getJSDateOrNull } from '../../components/DateAndTimePickers/StandardDatePicker';
import { assignmentIdRequired, dateRequired, timeRequired } from '../../validation';
import theme from '../../theme';
import { isWeekend } from '../../utils';

type FutureWorkHourTableProps = {
  employeeNumber: string | null;
  dateRange: Range;
};

type FutureWorkHourWithTableData = FutureWorkHour & {
  //Material table requires tableData
  tableData?: {
    id?: number;
  };
};

type WithHelperText = {
  helperText?: string;
};

const validators = {
  date: dateRequired,
  starts_at: timeRequired,
  ends_at: timeRequired,
  assignment_id: assignmentIdRequired,
} as const;

const createValidator = (
  fieldName: keyof typeof validators,
): ((row: FutureWorkHourWithTableData) => string | boolean) => {
  return (row: FutureWorkHourWithTableData): string | boolean => {
    if (
      ((fieldName === 'starts_at' || fieldName === 'ends_at') &&
        (row.type === 'vacation' ||
          row.type === 'partial_allowance' ||
          row.type === 'allowance' ||
          row.type === 'sick' ||
          row.type === 'pekkanen')) ||
      (fieldName === 'assignment_id' && row.type !== 'normal')
    ) {
      return true;
    }
    const result = validators[fieldName].validate(row[fieldName as keyof FutureWorkHourWithTableData]);
    if (result.error !== undefined) {
      return result.error.message;
    }
    return true;
  };
};

type AssignmentWithOrganizationName = Assignment & { organizationName: string };

const FutureWorkHourTable: React.FC<FutureWorkHourTableProps> = ({ employeeNumber, dateRange }) => {
  const [futureWorkHours, setFutureWorkHours] = useState<FutureWorkHourWithTableData[]>([]);
  const [assignments, setAssignments] = useState<AssignmentWithOrganizationName[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const snackbarProps: SnackbarProps = {
    onClose: (): void => setMessage(null),
    open: message !== null,
    message: message,
    key: message,
  };

  const loadFutureWorkHoursByEmployeeNumber = async () => {
    setLoading(true);
    try {
      if (!employeeNumber) {
        return;
      }
      const response = await api.futureWorkHour.getFutureWorkHoursByEmployeeNumber({
        employeeNumber: employeeNumber,
        agreedDeliveryWindowDateRangeStartsAt: dateRange.start.toJSDate(),
        agreedDeliveryWindowDateRangeEndsAt: dateRange.end.toJSDate(),
      });
      setFutureWorkHours(response.data);
    } catch (err) {
      console.error(err);
      setMessage('Tuntien lataus epäonnistui');
    } finally {
      setLoading(false);
    }
  };

  const loadAssignments = async () => {
    try {
      const organizations = (await api.organizations.getOrganizations({ alsoInactive: false })).data;
      const orgIds = organizations.map((o) => o.id);
      const response = (await api.assignments.getAssignments()).data;
      setAssignments(
        response
          .filter((a) => orgIds.includes(a.organization_id))
          .map((a) => {
            return {
              ...a,
              organizationName: organizations.find((organization) => organization.id === a.organization_id)?.name ?? '',
            };
          }),
      );
    } catch (err) {
      console.error(err);
      setMessage('Työntekijöiden lataus epäonnistui!');
    }
  };

  useEffect(() => {
    loadAssignments();
  }, []);

  const assignmentListItems = assignments.reduce(
    (obj, item) => (
      ((obj as any)[item.assignment_specific_id] =
        `${item.organizationName} - ${item.assignment_specific_id} - ${item.description}`),
      obj
    ),
    {},
  );

  const typeList = [
    'normal',
    'waiting',
    'vacation',
    'pekkanen',
    'sick',
    'own_time_off',
    'compensatory_time_off',
    'bank',
    'allowance',
    'partial_allowance',
    // 'partial_sick',
  ];

  const getFutureWorkHourTypeMenuItems = () =>
    typeList.map((type) => (
      <MenuItem key={type} value={type}>
        {formatFutureWorkHourType(type)}
      </MenuItem>
    ));

  useEffect(() => {
    loadFutureWorkHoursByEmployeeNumber();
  }, [dateRange, employeeNumber]);

  let currentType = FutureWorkHourType.Normal;

  const isEditable = () => {
    return currentType === FutureWorkHourType.Normal || currentType === FutureWorkHourType.Waiting;
  };

  const columns: Array<Column<FutureWorkHourWithTableData>> = [
    {
      title: 'Päivämäärä',
      field: 'date',
      type: 'date',
      width: '12rem',
      editable: 'always',
      validate: createValidator('date'),
      render: (rowData) => (rowData.date ? DateTime.fromJSDate(rowData.date).toFormat(dateFormat) : null),
      editComponent: (props: EditComponentProps<FutureWorkHourWithTableData> & WithHelperText) => (
        <StandardDatePicker
          value={props.value ?? null}
          className="date"
          slotProps={{
            textField: {
              name: 'date',
              error: props.error,
              helperText: props.helperText,
            },
          }}
          onChange={(date: DateTime) => {
            props.onRowDataChange({ ...props.rowData, date: date.toJSDate() });
          }}
        />
      ),
    },
    {
      title: 'Päivä',
      field: 'date',
      type: 'date',
      editable: 'never',
      render: (rowData) => (rowData.date ? formatWeekDay(DateTime.fromJSDate(rowData.date)) : null),
    },
    {
      title: 'Tyyppi',
      field: 'type',
      editable: 'always',
      render: (rowData) => {
        return rowData.type ? formatFutureWorkHourType(rowData.type) : rowData.type;
      },
      initialEditValue: currentType ?? FutureWorkHourType.Normal,
      editComponent: (props: EditComponentProps<FutureWorkHourWithTableData> & WithHelperText) => {
        currentType = props.value;
        return (
          <Select
            defaultValue={FutureWorkHourType.Normal}
            value={props.value}
            onChange={(event) => {
              currentType = event.target.value;
              props.onRowDataChange({ ...props.rowData, type: event.target.value });
            }}
          >
            {getFutureWorkHourTypeMenuItems()}
          </Select>
        );
      },
    },
    {
      title: 'Aloitus',
      field: 'starts_at',
      type: 'time',
      width: '6rem',
      validate: createValidator('starts_at'),
      render: (rowData) => (rowData.starts_at ? DateTime.fromJSDate(rowData.starts_at).toFormat(timeFormat) : null),
      editComponent: (props: EditComponentProps<FutureWorkHourWithTableData> & WithHelperText) =>
        isEditable() && (
          <StandardTimePicker
            value={props.value ?? null}
            className="starts_at"
            slotProps={{
              textField: {
                name: 'starts_at',
                error: props.error,
                helperText: props.helperText,
              },
            }}
            onChange={(time) => props.onRowDataChange({ ...props.rowData, starts_at: getJSDateOrNull(time) })}
          />
        ),
    },
    {
      title: 'Lopetus',
      field: 'ends_at',
      type: 'time',
      width: '6rem',
      validate: createValidator('ends_at'),
      render: (rowData) => (rowData.ends_at ? DateTime.fromJSDate(rowData.ends_at).toFormat(timeFormat) : null),
      editComponent: (props: EditComponentProps<FutureWorkHourWithTableData> & WithHelperText) =>
        isEditable() && (
          <StandardTimePicker
            value={props.value ?? null}
            className="ends_at"
            slotProps={{
              textField: {
                name: 'ends_at',
                error: props.error,
                helperText: props.helperText,
              },
            }}
            onChange={(time) => props.onRowDataChange({ ...props.rowData, ends_at: getJSDateOrNull(time) })}
          />
        ),
    },
    {
      title: 'Työ',
      field: 'assignment_id',
      validate: createValidator('assignment_id'),
      type: 'numeric',
      lookup: assignmentListItems,
      width: '20rem',
      editComponent: (props: EditComponentProps<FutureWorkHourWithTableData> & WithHelperText) =>
        isEditable() && (
          <Autocomplete
            fullWidth
            options={assignments}
            autoHighlight
            getOptionLabel={(assignment) =>
              `${assignment.organizationName} - ${assignment.assignment_specific_id} - ${assignment.description}`
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, assignment) => (
              <li
                {...props}
              >{`${assignment.organizationName} - ${assignment.assignment_specific_id} - ${assignment.description}`}</li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={props.error}
                helperText={props.helperText}
                label="Työtehtävä"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            value={assignments.find((assignment) => assignment.assignment_specific_id === props.value) ?? null}
            onChange={(_event, assignment) =>
              props.onRowDataChange({ ...props.rowData, assignment_id: assignment?.assignment_specific_id ?? -1 })
            }
          />
        ),
    },
    {
      title: 'Selite',
      field: 'note',
      editComponent: (props: EditComponentProps<FutureWorkHourWithTableData> & WithHelperText) =>
        isEditable() && (
          <TextField
            {...props}
            error={props.error}
            helperText={props.helperText}
            placeholder="Selite"
            onChange={(e) => props.onRowDataChange({ ...props.rowData, note: e.target.value })}
            value={props.value}
          />
        ),
    },
    {
      title: 'Netto',
      field: 'net_hours',
      render: (rowData) => (rowData.net_hours ? Duration.fromISO(rowData.net_hours).toFormat('h:mm') : null),
      editable: 'never',
      width: '6rem',
    },
    {
      title: 'Ilta',
      field: 'evening_hours',
      render: (rowData) => (rowData.evening_hours ? Duration.fromISO(rowData.evening_hours).toFormat('h:mm') : null),
      editable: 'never',
      width: '6rem',
    },
    {
      title: 'Yö',
      field: 'night_hours',
      render: (rowData) => (rowData.night_hours ? Duration.fromISO(rowData.night_hours).toFormat('h:mm') : null),
      editable: 'never',
      width: '6rem',
    },
  ];

  return (
    <>
      <Loading isLoading={loading} />
      <MaterialTable
        style={{ margin: '0.5rem' }}
        icons={materialTableIcons}
        localization={{
          ...materialTableLocalization,
          body: {
            emptyDataSourceMessage: 'Ei tunteja',
            addTooltip: 'Lisää tunti',
            deleteTooltip: 'Poista tunti',
            editTooltip: 'Muokkaa tunteja',
            editRow: {
              deleteText: 'Haluatko varmasti poistaa tunnit?',
              cancelTooltip: 'Peruuta',
              saveTooltip: 'Tallenna',
            },
          },
        }}
        data={futureWorkHours.map((x) => ({
          ...x,
          //material-table adds duplicate tableData id's for rows with no id
          tableData: { ...x.tableData, id: (x.tableData?.id ?? -1) + Math.random() },
        }))}
        columns={columns}
        title={<MaterialTableHeader title={`Työntekijä ${employeeNumber} tunnit`} />}
        options={{
          ...materialTableOptions,
          rowStyle: (data, _index, _level) => {
            return {
              ...materialTableOptions.rowStyle,
              backgroundColor:
                data.date && isWeekend(DateTime.fromJSDate(data.date))
                  ? theme.palette.table.light
                  : data.date &&
                      (DateTime.fromJSDate(data.date).weekday === 2 || DateTime.fromJSDate(data.date).weekday === 4)
                    ? theme.palette.action.hover
                    : undefined,
            };
          },
          maxColumnSort: 3,
          showColumnSortOrder: true,
          defaultOrderByCollection: [
            { orderBy: 0, sortOrder: 1, orderByField: 'date', orderDirection: 'asc' },
            { orderBy: 2, sortOrder: 2, orderByField: 'starts_at', orderDirection: 'asc' },
            { orderBy: 3, sortOrder: 3, orderByField: 'ends_at', orderDirection: 'asc' },
          ],
          paging: false,
          search: false,
          addRowPosition: 'first',
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
        }}
        editable={{
          onRowAdd: (newWorkHour) => {
            return new Promise<void>((resolve) => {
              if (!employeeNumber) {
                return;
              }
              api.futureWorkHour
                .postFutureWorkHour({
                  employeeNumber: employeeNumber,
                  futureWorkHourPostBody: {
                    ...newWorkHour,
                    type: newWorkHour.type,
                    ...(newWorkHour.assignment_id && { assignment_id: newWorkHour.assignment_id }),
                    ...(newWorkHour.note && { note: newWorkHour.note }),
                    ...(newWorkHour.starts_at && { starts_at: newWorkHour.starts_at }),
                    ...(newWorkHour.ends_at && { ends_at: newWorkHour.ends_at }),
                  },
                })
                .then(async () => {
                  const response = await api.futureWorkHour.getFutureWorkHoursByEmployeeNumber({
                    employeeNumber: employeeNumber,
                    agreedDeliveryWindowDateRangeStartsAt: dateRange.start.toJSDate(),
                    agreedDeliveryWindowDateRangeEndsAt: dateRange.end.toJSDate(),
                  });
                  setFutureWorkHours(response.data);
                  setMessage('Tunnit päivitety onnistuneesti');
                });
              resolve();
            });
          },
          onRowAddCancelled: () => (currentType = FutureWorkHourType.Normal),
          onRowUpdateCancelled: () => (currentType = FutureWorkHourType.Normal),
          onRowDeleteCancelled: () => (currentType = FutureWorkHourType.Normal),
          onRowUpdate: (newWorkHour, oldWorkHour) => {
            return new Promise<void>((resolve) => {
              if (employeeNumber && oldWorkHour?.date) {
                api.futureWorkHour
                  .patchFutureWorkHour({
                    previousDate: oldWorkHour.date,
                    workHourId: oldWorkHour.id ?? -1,
                    employeeNumber: employeeNumber,
                    futureWorkHourPatchBody: {
                      type: newWorkHour.type,
                      note: newWorkHour.note,
                      ...(newWorkHour.date && { date: newWorkHour.date }),
                      ...(newWorkHour.assignment_id && { assignment_id: newWorkHour.assignment_id }),
                      ...(newWorkHour.starts_at && { starts_at: newWorkHour.starts_at }),
                      ...(newWorkHour.ends_at && { ends_at: newWorkHour.ends_at }),
                    },
                  })
                  .then(async () => {
                    const response = await api.futureWorkHour.getFutureWorkHoursByEmployeeNumber({
                      employeeNumber: employeeNumber,
                      agreedDeliveryWindowDateRangeStartsAt: dateRange.start.toJSDate(),
                      agreedDeliveryWindowDateRangeEndsAt: dateRange.end.toJSDate(),
                    });
                    setFutureWorkHours(response.data);
                    setMessage('Tunnit päivitety onnistuneesti');
                  });
              }
              resolve();
            });
          },
          onRowDelete: (oldWorkHour) => {
            return new Promise<void>((resolve) => {
              if (!employeeNumber) {
                return;
              }
              api.futureWorkHour
                .deleteFutureWorkHour({
                  workHourId: oldWorkHour.id ?? -1,
                  previousDate: oldWorkHour.date,
                  employeeNumber: employeeNumber,
                  type: oldWorkHour.type,
                })
                .then(async () => {
                  const response = await api.futureWorkHour.getFutureWorkHoursByEmployeeNumber({
                    employeeNumber: employeeNumber,
                    agreedDeliveryWindowDateRangeStartsAt: dateRange.start.toJSDate(),
                    agreedDeliveryWindowDateRangeEndsAt: dateRange.end.toJSDate(),
                  });
                  setFutureWorkHours(response.data);
                  setMessage('Tunnit poistettu onnistuneesti');
                });
              resolve();
            });
          },
        }}
      />

      <Notification {...snackbarProps} />
    </>
  );
};

export default FutureWorkHourTable;
