import React, { FunctionComponent, useReducer, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { api, ShipmentRow, UnhandledShipmentRow } from '../../api';
import { ReactTable } from '../../components/ReactTable';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';
import { Button, Link } from '@mui/material';
import { Accessor, CellValue, Row } from 'react-table';
import { DateRangePicker, getDateRangePickerPropsFromState } from '../../components/DateRangePicker';
import { getViewSettings } from '../settings';
import { AutoCompleteFilter, NoFilter } from '../../components/TableFilters';
import { dateFormatUnderscore, formatShipmentRowState } from '../../formatters';
import { copyShipment, createAdditionalServiceFields } from '../shipments/shipment.state';
import { FilterCheckbox } from '../../components/FilterCheckbox';
import { FilterContainer } from '../../components/StyledComponents/FilterContainer';
import {
  filterShipmentRows,
  getInitialState,
  load,
  reducer,
  ShipmentRowsCoordinationViewSettings,
} from './shipmentRowsCoordination.state';

const VIEW_ID = 'coordination_shipment_rows' as const;

const ShipmentRowsCoordination: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const navigate = useNavigate();

  const getCreateNewShipmentButton = ({ row }: { row: Row<UnhandledShipmentRow> }) => {
    return (
      <Button
        id="create-new-shipment-button"
        onClick={async () => {
          const shipmentRowShipment = (await api.shipments.getShipment({ shipmentId: row.original.shipment_id })).data;
          if (!shipmentRowShipment) {
            throw new Error('Prerequisities not met');
          }
          const [
            shipmentRowShipmentAdditionalServices,
            organizationAdditionalServices,
            shipmentRowShipmentShipmentRows,
          ] = await Promise.all([
            api.organizationShipments.getOrganizationShipmentAdditionalServices({
              organizationId: shipmentRowShipment.organization_id,
              shipmentId: shipmentRowShipment.id,
            }),
            api.organizationAdditionalServices.getOrganizationAdditionalServices({
              organizationId: shipmentRowShipment.organization_id,
            }),
            api.shipments.getShipmentRows({
              shipmentId: shipmentRowShipment.id,
            }),
          ]);
          const failedShipmentRowShipmentShipmentRows = shipmentRowShipmentShipmentRows.data.filter(
            (shipmentRow) =>
              shipmentRow.state !== 'delivered' &&
              shipmentRow.state !== 'ready_for_pickup' &&
              shipmentRow.state !== 'picked_up',
          );
          navigate('/shipments/new', {
            state: {
              shipment: {
                ...copyShipment(shipmentRowShipment),
                weight_kg: failedShipmentRowShipmentShipmentRows.reduce(
                  (weight, row) => (weight += row.total_weight_kg ?? 0),
                  0,
                ),
                volume_m3: failedShipmentRowShipmentShipmentRows.reduce(
                  (volume, row) => (volume += row.volume_m3 ?? 0),
                  0,
                ),
              },
              shipmentRows: failedShipmentRowShipmentShipmentRows,
              additionalServiceFields: createAdditionalServiceFields(
                organizationAdditionalServices.data,
                shipmentRowShipmentAdditionalServices.data,
              ),
            },
          });
        }}
      >
        Luo uusi toimitus
      </Button>
    );
  };

  const getRelatedShipmentIdLinks = ({ row }: { row: Row<UnhandledShipmentRow> }) => {
    if (row.original.relatedShipmentRows) {
      return (
        <ul>
          {row.original.relatedShipmentRows.map((relatedShipmentRow: ShipmentRow, index: number) => (
            <li key={index} value={relatedShipmentRow.shipment_id} style={{ listStyleType: 'none' }}>
              <Link component={RouterLink} to={{ pathname: `/shipments/${relatedShipmentRow.shipment_id}` }}>
                {relatedShipmentRow.shipment_id}
              </Link>
            </li>
          ))}
        </ul>
      );
    } else {
      return <></>;
    }
  };

  const viewSettings = React.useMemo(() => getViewSettings<ShipmentRowsCoordinationViewSettings>(VIEW_ID), []);

  useEffect(() => {
    if (state.dateRange) {
      load(state, dispatch);
    }
  }, [state.dateRange]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Toimitusid',
        accessor: 'shipment_id',
        Cell: ({ value }: { value: CellValue }) => (
          <Link component={RouterLink} to={{ pathname: `/shipments/${value}` }}>
            {value}
          </Link>
        ),
      },
      {
        Header: 'Asiakas',
        accessor: 'organizationName',
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Asiakasviite',
        accessor: 'shipmentReferenceNumber',
      },
      {
        Header: 'Vastaanottaja',
        accessor: 'shipmentDeliveryName',
      },
      {
        Header: 'Osoite',
        accessor: 'shipmentDeliveryAddress',
      },
      {
        id: 'state',
        Header: 'Tila',
        accessor: ((cell: CellValue) => (cell.state ? formatShipmentRowState(cell.state) : '')) as Accessor<any>,
        Filter: AutoCompleteFilter,
      },
      {
        Header: 'Sarjanumero',
        accessor: 'serial_number',
      },
      {
        Header: 'Tyyppi',
        accessor: 'parcel_id',
      },
      {
        Header: 'Tunnus',
        accessor: 'parcel_type',
      },
      {
        Header: 'Nimi',
        accessor: 'name',
      },
      {
        Header: 'Kpl',
        accessor: 'quantity',
      },
      {
        Header: 'Paino (kg)',
        accessor: 'total_weight_kg',
      },
      {
        Header: 'Tilavuus (m³)',
        accessor: 'volume_m3',
      },
      {
        Header: 'Pituus (mm)',
        accessor: 'longest_side_length_mm',
      },
      {
        Header: 'Lisätietoja',
        accessor: 'shipmentNote',
      },
      {
        Header: 'Uusi toimitus',
        accessor: 'relatedShipmentRows',
        Cell: ({ row }: { row: Row<UnhandledShipmentRow> }) => getRelatedShipmentIdLinks({ row }),
      },
      {
        Header: 'Toiminnot',
        id: 'actions',
        Cell: ({ row }: { row: Row<UnhandledShipmentRow> }) => getCreateNewShipmentButton({ row }),
        Filter: NoFilter,
      },
    ],
    [navigate],
  );
  const data = React.useMemo(
    () => filterShipmentRows(state.shipmentRows, state.viewSettings.onlyUnhandledShipmentRows),
    [state.shipmentRows, state.viewSettings.onlyUnhandledShipmentRows],
  );

  return (
    <>
      <FilterContainer style={{ paddingRight: '1rem' }}>
        <FilterCheckbox
          disabled={state.isLoading}
          label={'Vain hoitamattomat toimitusrivit'}
          checked={state.viewSettings.onlyUnhandledShipmentRows}
          onChange={(_e) => {
            dispatch({
              type: 'SET_VIEW_SETTINGS',
              payload: {
                ...state.viewSettings,
                onlyUnhandledShipmentRows: !state.viewSettings.onlyUnhandledShipmentRows,
              },
            });
          }}
          name="unhandled-shipment-rows"
          className="unhandled-shipment-rows"
          tooltip="Näytä vain toimitusrivit, joille ei ole vielä tehty uutta toimitusta."
        />
      </FilterContainer>
      <ReactTable
        columns={columns}
        data={data}
        header="Toimittamattomat toimitusrivit"
        dateRangePicker={<DateRangePicker {...getDateRangePickerPropsFromState(state, dispatch)}></DateRangePicker>}
        isLoading={state.isLoading}
        emptyText="Ei näytettäviä toimitusrivejä."
        initialState={{
          sortBy: viewSettings.sortBy ?? [
            {
              id: 'state',
              asc: true,
            },
          ],
          filters: viewSettings.filters ?? [],
          hiddenColumns: viewSettings.hiddenColumns ?? [],
        }}
        columnSelector={true}
        onStateChange={(state) => {
          dispatch({
            type: 'SET_TABLE_SETTINGS',
            payload: state,
          });
        }}
        exportName={`Purkuraportti_${state.dateRange?.start.toFormat(
          dateFormatUnderscore,
        )}_${state.dateRange?.end.toFormat(dateFormatUnderscore)}`}
        viewId={VIEW_ID}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default ShipmentRowsCoordination;
