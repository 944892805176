import { jsPDF } from 'jspdf';
import { Office, Organization } from '../api';
import TimecapLogo from '../../static/img/timecaplogo.png';
import { DateTime } from 'luxon';
import { dateFormat, formatFloat, formatPrice, formatPriceBasis } from '../formatters';
import { ReportShipment } from '../views/reports/reports.state';
import { Range } from './DateRangePicker';
import { sumBy } from 'lodash';

const rowHeigth = 4;

const firstColumn = 10;
const secondColumn = 40;
const thirdColumn = 80;
const fourthColumn = 120;
const fifthColumn = 145;
const sixthColumn = 180;

const infoRow = 10;
const firstPageHeaderRow = 55;
const firstPageShipmentRow = 70;

const getInvoiceHeader = (
  doc: jsPDF,
  dateRange: Range | null,
  organizationName: Organization['name'] | undefined,
  shipments: ReportShipment[],
  selectedOffices: Office[],
  showBillingOffices: boolean,
) => {
  const selectedOfficesWithoutEmpty = selectedOffices.filter((office) => office.name && office.id > 0);
  const showMoreThanOneBillingOffice = showBillingOffices && selectedOfficesWithoutEmpty.length > 1;
  const billingOfficeColumnLocation = showMoreThanOneBillingOffice ? fifthColumn : thirdColumn;
  const billingOfficeRowLocation = showMoreThanOneBillingOffice ? infoRow : infoRow + rowHeigth * 5;
  const billingInfoColumnLocation = showMoreThanOneBillingOffice ? fifthColumn - 10 : sixthColumn;
  const selectedOfficeColumnLocation = showMoreThanOneBillingOffice ? fifthColumn : sixthColumn;
  const billingInfoRowLocation = showMoreThanOneBillingOffice ? rowHeigth : rowHeigth * 5;

  doc.addImage(TimecapLogo, 'PNG', firstColumn, 8, 50, 14);

  doc.text('ERITTELY', thirdColumn, infoRow);
  doc.text(
    `${dateRange?.start.toFormat(dateFormat)} - ${dateRange?.end.toFormat(dateFormat)}`,
    thirdColumn,
    infoRow + rowHeigth * 2,
  );
  doc.text('Asiakas', thirdColumn, infoRow + rowHeigth * 4);
  showBillingOffices
    ? doc.text('Toimipaikka / Kustannuspaikka', billingOfficeColumnLocation, billingOfficeRowLocation)
    : null;
  doc.text('Paino yhteensä', thirdColumn, infoRow + rowHeigth * 6);
  doc.text('Toimituksia yhteensä', thirdColumn, infoRow + rowHeigth * 7);
  doc.text('Laskutus yhteensä', thirdColumn, infoRow + rowHeigth * 8);

  const selectedOfficeNames =
    showBillingOffices && selectedOfficesWithoutEmpty.length > 0
      ? selectedOfficesWithoutEmpty.map((office) => office.name ?? '')
      : '';

  const combinedWeight = formatFloat(sumBy(shipments, 'chargeable_weight_kg'));
  const combinedPrice = formatPrice(sumBy(shipments, 'price'));
  const numberOfShipments = String(shipments.length);

  doc.text(organizationName ?? '', billingInfoColumnLocation, infoRow + rowHeigth * 4, undefined, 'right');
  doc.text(
    selectedOfficeNames,
    selectedOfficeColumnLocation,
    infoRow + billingInfoRowLocation,
    undefined,
    showMoreThanOneBillingOffice ? 'left' : 'right',
  );
  doc.text(combinedWeight, billingInfoColumnLocation, infoRow + rowHeigth * 6, undefined, 'right');
  doc.text(numberOfShipments, billingInfoColumnLocation, infoRow + rowHeigth * 7, undefined, 'right');
  doc.text(combinedPrice, billingInfoColumnLocation, infoRow + rowHeigth * 8, undefined, 'right');
};

const getShipmentsHeader = (doc: jsPDF, startPosition: number) => {
  doc.setLineWidth(0.5);
  doc.line(firstColumn, startPosition - rowHeigth, sixthColumn + 20, startPosition - rowHeigth);

  doc.text('Päiväys', firstColumn, startPosition);
  doc.text('Rahtikirjan nro', firstColumn, startPosition + rowHeigth);
  doc.text('Toimitus-id', firstColumn, startPosition + rowHeigth * 2);

  doc.text('Lähtöpaikka', secondColumn, startPosition);

  doc.text('Vastaanottaja', thirdColumn, startPosition);

  doc.text('Rahdituspaino', fourthColumn, startPosition);
  doc.text('Etäisyys', fourthColumn, startPosition + rowHeigth);
  doc.text('Tunnit', fourthColumn, startPosition + rowHeigth * 2);

  doc.text('Hintaperuste', fifthColumn, startPosition);

  doc.text('EUR', sixthColumn + 10, startPosition);

  doc.setLineWidth(0.5);
  doc.line(firstColumn, startPosition + rowHeigth * 2.5, sixthColumn + 20, startPosition + rowHeigth * 2.5);
};

const stayOnCurrentOrChangePage = (
  pageNumber: number,
  currentRowPosition: number,
  rowsInFirstPage: number,
  rowsInRestOfPages: number,
) => {
  return (
    (pageNumber === 1 && currentRowPosition >= rowsInFirstPage) ||
    (pageNumber > 1 && currentRowPosition >= rowsInRestOfPages)
  );
};

const getRowCountFromPriceBasisString = (priceBasisString: string, minRowCount: number) => {
  const priceBasisRowCount = priceBasisString.split(/\r\n|\r|\n/).length;
  if (priceBasisRowCount < minRowCount) {
    return minRowCount;
  } else {
    return priceBasisRowCount;
  }
};

const getTotalNumberOfPages = (
  shipments: ReportShipment[],
  rowsInFirstPage: number,
  rowsInRestOfPages: number,
  showNote: boolean,
) => {
  let pageNumber = 1;
  let currentRowPosition = 0;
  shipments.forEach((shipment) => {
    if (stayOnCurrentOrChangePage(pageNumber, currentRowPosition, rowsInFirstPage, rowsInRestOfPages)) {
      pageNumber += 1;
      currentRowPosition = 0;
    }
    const minRowCount = showNote && shipment.note ? 5 : 4;
    const priceBasisString = formatPriceBasis(shipment.price_basis, false);
    const rowCount = getRowCountFromPriceBasisString(priceBasisString, minRowCount);
    currentRowPosition += rowCount;
  });
  return pageNumber;
};

const getShipmentText = (
  doc: jsPDF,
  shipment: ReportShipment,
  shipmentRowStartPosition: number,
  showNote: boolean,
  priceBasis: string,
) => {
  //Date
  doc.text(
    shipment.agreed_delivery_window_starts_at
      ? DateTime.fromJSDate(shipment.agreed_delivery_window_starts_at).toFormat(dateFormat)
      : '',
    firstColumn,
    shipmentRowStartPosition,
  );

  //Reference number
  const referenceNumberMaxLength = 18;
  doc.text(
    shipment.reference_number?.substring(0, referenceNumberMaxLength) ?? '',
    firstColumn,
    shipmentRowStartPosition + rowHeigth,
  );
  //Id
  doc.text(String(shipment.id), firstColumn, shipmentRowStartPosition + rowHeigth * 2);
  //Note
  const noteMaxLength = 80;
  const noteWithoutLineBreaks = shipment.note?.replace(/\r?\n|\r/g, ' ') ?? '';
  const formattedNote =
    noteWithoutLineBreaks.length >= noteMaxLength
      ? noteWithoutLineBreaks.substring(0, noteMaxLength) + '...'
      : noteWithoutLineBreaks;
  showNote ? doc.text(formattedNote, firstColumn, shipmentRowStartPosition + rowHeigth * 3) : null;

  //Pickup name
  const pickupDeliveryNameMaxLength = 20;
  doc.text(
    shipment.pickup_name?.substring(0, pickupDeliveryNameMaxLength) ?? '',
    secondColumn,
    shipmentRowStartPosition,
  );
  //Pickup city
  doc.text(shipment.pickup_city ?? '', secondColumn, shipmentRowStartPosition + rowHeigth);

  //Delivery name
  doc.text(
    shipment.delivery_name?.substring(0, pickupDeliveryNameMaxLength) ?? '',
    thirdColumn,
    shipmentRowStartPosition,
  );
  //Delivery city
  doc.text(shipment.delivery_city ?? '', thirdColumn, shipmentRowStartPosition + rowHeigth);

  //Chargeable weigth
  doc.text(formatFloat(shipment.chargeable_weight_kg ?? 0), fourthColumn, shipmentRowStartPosition);
  //Legacy etaisyys field
  doc.text(String(shipment.legacy_etaisyys_field ?? 0), fourthColumn, shipmentRowStartPosition + rowHeigth);
  //Hourly work hours
  doc.text(String(shipment.hourly_work_hours ?? 0), fourthColumn, shipmentRowStartPosition + rowHeigth * 2);

  //Price basis
  doc.text(priceBasis, fifthColumn, shipmentRowStartPosition);

  //Price
  doc.text(formatPrice(shipment.price ?? 0), sixthColumn + 20, shipmentRowStartPosition, undefined, 'right');
};

const getListOfShipments = (doc: jsPDF, shipments: ReportShipment[], showNote: boolean) => {
  const minJSDateInMilliseconds = -8640000000000000;
  const shipmentsSortedByDate = [...shipments].sort((shipment1, shipment2) =>
    (shipment1.agreed_delivery_window_starts_at ?? new Date(minJSDateInMilliseconds)) >
    (shipment2.agreed_delivery_window_starts_at ?? new Date(minJSDateInMilliseconds))
      ? 1
      : -1,
  );
  const rowsInFirstPage = 53;
  const rowsInRestOfPages = 61;
  const totalNumberOfPages = getTotalNumberOfPages(shipmentsSortedByDate, rowsInFirstPage, rowsInRestOfPages, showNote);
  let currentRowPosition = 0;
  let pageNumber = 1;
  shipmentsSortedByDate.map((shipment, index) => {
    if (stayOnCurrentOrChangePage(pageNumber, currentRowPosition, rowsInFirstPage, rowsInRestOfPages)) {
      doc.addPage();
      pageNumber += 1;
      currentRowPosition = 0;
    }
    if (currentRowPosition === 0) {
      getShipmentsHeader(doc, index === 0 ? firstPageHeaderRow : 20);
      doc.text(`Sivu ${pageNumber}/${totalNumberOfPages}`, sixthColumn + 20, infoRow, undefined, 'right');
    }
    const minRowCount = showNote && shipment.note ? 5 : 4;
    const contractPriceString = `Sopimushinta: ${shipment.price ? formatPrice(shipment.price) : '0,00'} €`;
    const normalPriceBasisString = shipment.price_basis ? formatPriceBasis(shipment.price_basis, false) : '';
    const priceBasisString = shipment.has_contract_price ? contractPriceString : normalPriceBasisString;
    const rowCount = getRowCountFromPriceBasisString(priceBasisString, minRowCount);
    const shipmentRowStartPosition = (pageNumber === 1 ? firstPageShipmentRow : 36) + rowHeigth * currentRowPosition;
    currentRowPosition += rowCount;
    getShipmentText(doc, shipment, shipmentRowStartPosition, showNote, priceBasisString);
  });
};

const saveInvoice = (
  doc: jsPDF,
  organizationName: Organization['name'] | undefined,
  billingDate: Date | null,
  selectedOffices: Office[],
  showBillingOffices: boolean,
) => {
  const formattedBillingDate = DateTime.fromJSDate(billingDate ?? new Date())
    .toFormat('yyyy_MM_dd')
    .trim();
  const formattedOrganizationName = (organizationName ?? '').trim().replace(/\s/g, '_').toLocaleLowerCase();
  const formattedSelectedOffices = showBillingOffices
    ? selectedOffices
        .map((office) => office.name)
        .join('_')
        .replace(/\s/g, '_')
        .toLocaleLowerCase()
    : '';
  doc.save(
    `${formattedBillingDate}_${formattedOrganizationName}${
      showBillingOffices ? '_' : ''
    }${formattedSelectedOffices}_erittely`,
  );
};

export const downloadInvoice = (
  shipments: ReportShipment[],
  billingDate: Date | null,
  dateRange: Range | null,
  organizationName: Organization['name'] | undefined,
  selectedOffices: Office[],
  showNote: boolean,
  showBillingOffices: boolean,
): void => {
  const doc = new jsPDF({
    compress: true,
  });
  doc.setFontSize(8);
  getInvoiceHeader(doc, dateRange, organizationName, shipments, selectedOffices, showBillingOffices);
  getListOfShipments(doc, shipments, showNote);
  saveInvoice(doc, organizationName, billingDate, selectedOffices, showBillingOffices);
};
