import React, { FunctionComponent, useEffect, useMemo, useReducer, useState } from 'react';
import { api, getAllPages, TimeOff, WorkHour } from '../../api';
import { Box, Chip, Divider, FormControlLabel, IconButton, Menu, MenuItem, Switch, Tooltip } from '@mui/material';
import { getViewSettings } from '../settings';
import {
  dateFormat,
  formatFloat,
  formatFloatNumber,
  formatTimeString,
  formatWeekDay,
  timeFormat,
  timeFormatWithSeconds,
} from '../../formatters';
import { isEmpty, orderBy, upperFirst } from 'lodash';
import { DateTime, Interval } from 'luxon';
import MRT from '../../components/MaterialReactTable/MRT';
import { MRT_TableOptions, createMRTColumnHelper } from 'material-react-table';
import theme from '../../theme';
import { Add, KeyboardDoubleArrowDown } from '@mui/icons-material';
import { MRT_EditCellTimePicker } from '../../components/MaterialReactTable/MRTEditCellTimePicker';
import {
  Action,
  DriverWithWorkHoursAndTimeOffs,
  getInitialState,
  reducer,
  State,
  WorkHourOrTimeOff,
} from './workHours.state';
import { CreateNewWorkHourModal } from './workHourModal';
import Notification, { getSnackbarPropsFromState } from '../../components/Notification';

const VIEW_ID = 'workHours' as const;

const filterDriversWithWorkHoursAndTimeOffs = (workHoursAndTimeOffs: WorkHourOrTimeOff[], hideEmpty: boolean) => {
  const result = workHoursAndTimeOffs.filter((i) => i.net_hours !== 0 || i.time_off_type !== 0 || i.note);
  return hideEmpty ? result : workHoursAndTimeOffs;
};

const getDriversWithWorkHoursAndTimeOffs = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  abortController: AbortController,
) => {
  try {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    if (state.dateRange?.start && state.dateRange?.end) {
      const driverWithWorkHoursAndTimeOffs = await getAllPages(
        api.drivers.getDriversWithWorkHoursAndTimeOffs.bind(api.drivers),
        {
          agreedDeliveryWindowDateRangeStartsAt: state.dateRange.start.toJSDate(),
          agreedDeliveryWindowDateRangeEndsAt: state.dateRange.end.toJSDate(),
        },
        abortController,
      );
      const organizations = (await api.organizations.getOrganizations({})).data;
      const assignments = (await api.assignments.getAssignments()).data;
      dispatch({
        type: 'INITIALIZE',
        payload: {
          driverWithWorkHoursAndTimeOffs: driverWithWorkHoursAndTimeOffs as DriverWithWorkHoursAndTimeOffs[],
          assignments: assignments,
          organizations: organizations,
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: 'SET_MESSAGE',
      payload: {
        message: 'Virhe haettaessa työtunteja',
        severity: 'error',
      },
    });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  }
};

const getTimeOffType = (timeOffType: TimeOff['time_off_type']): string => {
  switch (timeOffType) {
    case 1: {
      return 'Loma';
    }
    case 2: {
      return 'Pekkanen';
    }
    case 3: {
      return 'Sairas';
    }
    default: {
      return '';
    }
  }
};

export function getDateFromDateAndTime(
  date: WorkHourOrTimeOff['date'],
  time: WorkHourOrTimeOff['starts_at'] | WorkHourOrTimeOff['ends_at'],
) {
  if (!time) {
    return undefined;
  }
  const formattedTime = DateTime.fromFormat(time, 'HH:mm:ss');
  const dateWithTime =
    date !== null && date instanceof Date && formattedTime.isValid
      ? DateTime.fromJSDate(date).set({
          hour: formattedTime.hour ? formattedTime.hour : undefined,
          minute: formattedTime.minute ? formattedTime.minute : undefined,
          second: 0,
          millisecond: 0,
        })
      : null;
  return dateWithTime;
}

export function multipleDateRangeOverlaps(
  workHours: Pick<WorkHour, 'date' | 'starts_at' | 'ends_at' | 'id' | 'net_hours'>[],
  workHour: Pick<WorkHour, 'date' | 'starts_at' | 'ends_at' | 'id' | 'net_hours'>,
) {
  if (workHour.net_hours === 0) return false;
  const todaysWorkHours = workHours.filter((w) => w.net_hours !== 0 && workHour.id !== w.id);
  if (todaysWorkHours.length === 0) return false;
  let dateRangeOverlaps = false;
  for (let i = 0; i < todaysWorkHours.length; i += 1) {
    const aStartDate = getDateFromDateAndTime(todaysWorkHours[i].date, todaysWorkHours[i].starts_at ?? '');
    let aEndDate = getDateFromDateAndTime(todaysWorkHours[i].date, todaysWorkHours[i].ends_at ?? '');
    const bStartDate = getDateFromDateAndTime(workHour.date, workHour.starts_at ?? '');
    let bEndDate = getDateFromDateAndTime(workHour.date, workHour.ends_at ?? '');
    if (
      aStartDate &&
      aStartDate.isValid &&
      aEndDate &&
      aEndDate.isValid &&
      bStartDate &&
      bStartDate.isValid &&
      bEndDate &&
      bEndDate.isValid
    ) {
      if (aStartDate >= aEndDate) {
        //we need to add one day to be able to calculate night hours
        aEndDate = aEndDate.plus({ days: 1 });
      }
      if (bStartDate >= bEndDate) {
        //we need to add one day to be able to calculate night hours
        bEndDate = bEndDate.plus({ days: 1 });
      }
      const dateRangesIntersect = Interval.fromDateTimes(aStartDate, aEndDate).overlaps(
        Interval.fromDateTimes(bStartDate, bEndDate),
      );
      dateRangeOverlaps = dateRangesIntersect;
      if (dateRangeOverlaps) break;
    }
  }
  return dateRangeOverlaps;
}

type LunchBreak = {
  workHourId: number;
  type: string;
};

const lunchBreakAmount = 0.5;

export function getLunchBreak(workHours: WorkHour[]): LunchBreak {
  const emptyLunchBreak: LunchBreak = { workHourId: -1, type: '' };
  const orderedWorkHours = orderBy([...workHours], ['date', 'starts_at', 'ends_at'], ['asc', 'asc', 'asc']);
  if (orderedWorkHours && orderedWorkHours.length === 0) {
    return emptyLunchBreak;
  }
  let allBreaks = 0;
  let allNetHours = 0;
  for (let i = 0; i < orderedWorkHours.length; i += 1) {
    if (i > 0) {
      const aEndDate = getDateFromDateAndTime(orderedWorkHours[i - 1].date, orderedWorkHours[i - 1].ends_at ?? '');
      const bStartDate = getDateFromDateAndTime(orderedWorkHours[i].date, orderedWorkHours[i].starts_at ?? '');
      if (aEndDate && aEndDate.isValid && bStartDate && bStartDate.isValid) {
        allBreaks += bStartDate.diff(aEndDate, ['hours']).hours;
      }
    }
    allNetHours += orderedWorkHours[i]?.net_hours ?? 0;
    if (allNetHours >= 6) {
      if (allBreaks >= lunchBreakAmount) {
        return { workHourId: orderedWorkHours[i].id, type: '*' };
      } else {
        return { workHourId: orderedWorkHours[i].id, type: lunchBreakAmount.toString() };
      }
    }
  }
  return emptyLunchBreak;
}

function getAllWorkHoursAndTimeOffs(state: State) {
  const emptyWorkHour = {
    id: -1,
    employee_number: '',
    employee: '',
    date: null,
    starts_at: null,
    ends_at: null,
    net_hours: 0,
    evening_hours: 0,
    night_hours: 0,
    assignment_id: null,
    note: '',
    received_at: null,
    employee_name: '',
    assignment_description: '',
    time_off_type: 0,
    overlaps: false,
    lunchBreak: '',
  };
  if (!state.dateRange?.start || !state.dateRange?.end) {
    return [];
  }
  const AllWorkHoursAndTimeOffs: WorkHourOrTimeOff[][] = [];
  Interval.fromDateTimes(state.dateRange?.start, state.dateRange?.end)
    .splitBy({ day: 1 })
    .map((d, i) => {
      state.driverWithWorkHoursAndTimeOffs.map((driver) => {
        const currentDayWorkHours = driver.work_hours.filter(
          (workHour) => workHour.date?.toLocaleString() === d.start.toJSDate().toLocaleString(),
        );
        const currentDayTimeOffs = driver.time_offs.filter(
          (timeOff) => timeOff.date?.toLocaleString() === d.start.toJSDate().toLocaleString(),
        );
        const lunchBreak = getLunchBreak(currentDayWorkHours);
        if (currentDayWorkHours.length > 0) {
          AllWorkHoursAndTimeOffs.push(
            currentDayWorkHours.map((w) => {
              return {
                ...w,
                employee: driver.employee_name,
                time_off_type: 0,
                overlaps: multipleDateRangeOverlaps(currentDayWorkHours, w) ?? false,
                enableEditing: true,
                dateIndex: i,
                net_hours:
                  lunchBreak.workHourId === w.id && lunchBreak.type === lunchBreakAmount.toString() && w.net_hours
                    ? w.net_hours - lunchBreakAmount
                    : w.net_hours,
                lunchBreak: lunchBreak.workHourId === w.id ? lunchBreak.type : '',
              };
            }),
          );
        }
        if (currentDayTimeOffs.length > 0) {
          AllWorkHoursAndTimeOffs.push(
            currentDayTimeOffs.map((timeOff) => {
              return {
                ...emptyWorkHour,
                id: Math.random(),
                employee: driver.employee_name,
                date: timeOff.date,
                time_off_type: timeOff.time_off_type,
                overlaps: currentDayTimeOffs.length > 0 && currentDayWorkHours.length > 0,
                enableEditing: false,
                dateIndex: i,
              } as any;
            }),
          );
        }
        if (
          currentDayWorkHours.length === 0 &&
          currentDayTimeOffs.length === 0 &&
          d.start.weekday !== 6 &&
          d.start.weekday !== 7
        ) {
          AllWorkHoursAndTimeOffs.push([
            {
              ...emptyWorkHour,
              id: Math.random(),
              employee: driver.employee_name,
              date: d.start.toJSDate(),
              enableEditing: false,
              dateIndex: i,
            } as any,
          ]);
        }
      });
    });
  return orderBy(AllWorkHoursAndTimeOffs.flat(), ['date', 'starts_at', 'ends_at'], ['asc', 'asc', 'asc']);
}

const WorkHours: FunctionComponent = () => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const viewSettings = React.useMemo(() => getViewSettings<any>(VIEW_ID), []);

  useEffect(() => {
    dispatch({
      type: 'SET_HIDE_EMPTY',
      payload: { hideEmpty: viewSettings.hideEmpty ?? state.hideEmpty },
    });
  }, []);

  useEffect(() => {
    if (state.dateRange) {
      const abortController = new AbortController();
      getDriversWithWorkHoursAndTimeOffs(state, dispatch, abortController);
      return () => abortController.abort();
    }
  }, [state.dateRange]);

  const timeColumnSize = 145;
  const hourColumnSize = 120;

  const handleSaveRow: MRT_TableOptions<WorkHourOrTimeOff>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }): Promise<void> => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const abortController = new AbortController();
    const formattedStartsAt = DateTime.fromFormat(values.starts_at, timeFormat).toFormat(timeFormatWithSeconds);
    const formattedEndsAt = DateTime.fromFormat(values.ends_at, timeFormat).toFormat(timeFormatWithSeconds);
    const formattedWorkHourChanges = {
      ...(formattedStartsAt !== row.original.starts_at && {
        starts_at: formattedStartsAt,
      }),
      ...(formattedEndsAt !== row.original.ends_at && {
        ends_at: formattedEndsAt,
      }),
    };
    if (isEmpty(formattedWorkHourChanges)) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: 'Ei muutoksia työtunteihin.', severity: 'info' },
      });
    } else {
      try {
        api.workHours
          .patchWorkHour({
            workHourId: row.original.id,
            workHourPatchBody: formattedWorkHourChanges,
          })
          .then(() => {
            getDriversWithWorkHoursAndTimeOffs(state, dispatch, abortController);
            dispatch({
              type: 'SET_MESSAGE',
              payload: { message: 'Työtunnit tallennettu!' },
            });
          });
      } catch (err) {
        dispatch({
          type: 'SET_MESSAGE',
          payload: { message: 'Tallennus epäonnistui. Yritä uudelleen', severity: 'error' },
        });
        console.error(err);
      }
    }
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
    exitEditingMode();
  };

  const handleDeleteRow = (id: number) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const abortController = new AbortController();
    try {
      api.workHours
        .deleteWorkHour({
          workHourId: id,
        })
        .then(() => {
          getDriversWithWorkHoursAndTimeOffs(state, dispatch, abortController);
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: 'Työtunti poistettu!' },
          });
        });
    } catch (err) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: 'Poisto epäonnistui. Yritä uudelleen' },
      });
      console.error(err);
    }
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };

  const handleCreateNewRow = () => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    const abortController = new AbortController();
    try {
      if (
        !(state.fields.date.value instanceof Date) ||
        !state.fields.assignment_id.value ||
        (state.fields.assignment_id.value && isNaN(state.fields.assignment_id.value))
      ) {
        throw new Error('Creating work hour failed');
      }
      api.workHours
        .createWorkHour({
          workHourPostBody: {
            employee_number: state.fields.employee_number.value,
            date: state.fields.date.value,
            starts_at: state.fields.starts_at.value,
            ends_at: state.fields.ends_at.value,
            assignment_id: state.fields.assignment_id.value,
            note: state.fields.note.value,
          },
        })
        .then(() => {
          getDriversWithWorkHoursAndTimeOffs(state, dispatch, abortController);
          dispatch({
            type: 'SET_MESSAGE',
            payload: { message: 'Työtunti tallennettu!' },
          });
        });
    } catch (err) {
      dispatch({
        type: 'SET_MESSAGE',
        payload: { message: 'Tallennus epäonnistui. Yritä uudelleen', severity: 'error' },
      });
      console.error(err);
    }
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };

  const columnHelper = createMRTColumnHelper<WorkHourOrTimeOff>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => (row.date ? DateTime.fromJSDate(row.date) : row.date), {
        id: 'date',
        header: 'Päivämäärä',
        filterFn: 'dateFilterFn',
        filterVariant: 'date',
        enableEditing: false,
        Cell: ({ cell }) => cell.getValue<DateTime>().toFormat(dateFormat),
      }),
      columnHelper.accessor((row) => (row.date ? upperFirst(formatWeekDay(DateTime.fromJSDate(row.date))) : row.date), {
        id: 'day',
        header: 'Päivä',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
        enableEditing: false,
      }),
      columnHelper.accessor('employee', {
        header: 'Työntekijä',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
        enableEditing: false,
      }),
      columnHelper.accessor((row) => (row.starts_at ? formatTimeString(row.starts_at) : row.starts_at), {
        id: 'starts_at',
        header: 'Aloitus',
        size: timeColumnSize,
        Edit: ({ cell, table }) => (
          <MRT_EditCellTimePicker cell={cell as any} table={table} fields={state.fields} dispatch={dispatch} />
        ),
      }),
      columnHelper.accessor((row) => (row.ends_at ? formatTimeString(row.ends_at) : row.ends_at), {
        id: 'ends_at',
        header: 'Lopetus',
        size: timeColumnSize,
        Edit: ({ cell, table }) => (
          <MRT_EditCellTimePicker cell={cell as any} table={table} fields={state.fields} dispatch={dispatch} />
        ),
      }),
      columnHelper.accessor('organizationName', {
        header: 'Asiakas',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
        enableEditing: false,
      }),
      columnHelper.accessor('assignment_id', {
        header: 'Työnro',
        enableEditing: false,
      }),
      columnHelper.accessor('assignmentName', {
        header: 'Työtehtävä',
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
        enableEditing: false,
      }),
      columnHelper.accessor((row) => (row.net_hours ? formatFloatNumber(row.net_hours) : ''), {
        id: 'net_hours',
        header: 'Netto',
        aggregationFn: 'sum',
        sortingFn: 'alphanumeric',
        AggregatedCell: ({ cell }) => {
          const hoursPerDayInDateRange =
            ((state.dateRange?.start &&
              state.dateRange?.end &&
              Interval.fromDateTimes(state.dateRange?.start, state.dateRange?.end)
                .splitBy({ day: 1 })
                .filter((d) => d.start.weekday !== 6 && d.start.weekday !== 7).length) ??
              0) * 8;
          return (
            <Box
              sx={{
                color: Number(cell.getValue() ?? 0) > hoursPerDayInDateRange ? theme.palette.error.main : undefined,
              }}
            >
              {formatFloat(Number(cell.getValue() ?? 0))}
            </Box>
          );
        },
        size: hourColumnSize,
        enableEditing: false,
      }),
      columnHelper.accessor((row) => (row.evening_hours ? formatFloatNumber(row.evening_hours) : ''), {
        id: 'evening_hours',
        header: 'Ilta',
        aggregationFn: 'sum',
        size: hourColumnSize,
        enableEditing: false,
      }),
      columnHelper.accessor((row) => (row.night_hours ? formatFloatNumber(row.night_hours) : ''), {
        id: 'night_hours',
        header: 'Yö',
        aggregationFn: 'sum',
        size: hourColumnSize,
        enableEditing: false,
      }),
      columnHelper.accessor('lunchBreak', {
        header: 'Ruoka',
        aggregationFn: 'sum',
        size: hourColumnSize,
        enableEditing: false,
      }),
      columnHelper.accessor('note', {
        header: 'Selite',
        enableEditing: false,
      }),
      columnHelper.accessor((row) => (row.time_off_type ? getTimeOffType(row.time_off_type) : row.time_off_type), {
        id: 'time_off_type',
        header: 'Vapaa',
        size: timeColumnSize,
        aggregationFn: 'unique',
        sortingFn: 'basic',
        Cell: ({ cell }) => {
          if (cell.getValue<string>() === 'Pekkanen') {
            return <Chip size="small" color="success" label="Pekkanen" />;
          } else if (cell.getValue<string>() === 'Loma') {
            return <Chip size="small" color="warning" label="Loma" />;
          } else if (cell.getValue<string>() === 'Sairas') {
            return <Chip size="small" color="error" label="Sairas" />;
          } else {
            return '';
          }
        },
        AggregatedCell: ({ cell }) => {
          return cell.getValue<string[]>().map((timeOff, index) => {
            if (timeOff === 'Pekkanen') {
              return <Chip key={index} size="small" color="success" label="Pekkanen" />;
            } else if (timeOff === 'Loma') {
              return <Chip key={index} size="small" color="warning" label="Loma" />;
            } else if (timeOff === 'Sairas') {
              return <Chip key={index} size="small" color="error" label="Sairas" />;
            } else {
              return '';
            }
          });
        },
        filterVariant: 'multi-select',
        filterFn: 'arrIncludesSome',
        filterSelectOptions: ['Pekkanen', 'Loma', 'Sairas'],
        enableEditing: false,
      }),
      columnHelper.accessor(
        (row) => `${row.overlaps ? 'Päällekkäin' : ''}${row.lunchBreak === '*' ? 'Ruokatunti' : ''}`,
        {
          id: 'caution',
          header: 'Huom',
          aggregationFn: 'unique',
          sortingFn: 'basic',
          Cell: ({ cell }) => (
            <>
              {cell.row.original.overlaps ? <Chip size="small" color="error" label="Päällekkäin" /> : null}
              {cell.row.original.lunchBreak === '*' ? <Chip size="small" color="info" label="Ruokatunti" /> : null}
            </>
          ),
          AggregatedCell: ({ cell }) => {
            {
              return (
                <>
                  {cell.getValue<string[]>().includes('Päällekkäin') ? (
                    <Chip size="small" color="error" label="Päällekkäin" />
                  ) : null}
                  {cell.getValue<string[]>().includes('Ruokatunti') ? (
                    <Chip size="small" color="info" label="Ruokatunti" />
                  ) : null}
                </>
              );
            }
          },
          filterVariant: 'multi-select',
          filterFn: 'arrIncludesSome',
          filterSelectOptions: ['Päällekkäin', 'Ruokatunti'],
          enableEditing: false,
        },
      ),
    ],
    [state.fields.starts_at.feedback, state.fields.ends_at.feedback, state.dateRange],
  );

  const data = React.useMemo(
    () =>
      state.dateRange ? filterDriversWithWorkHoursAndTimeOffs(getAllWorkHoursAndTimeOffs(state), state.hideEmpty) : [],
    [state.driverWithWorkHoursAndTimeOffs, state.hideEmpty],
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const tableOptions: MRT_TableOptions<WorkHourOrTimeOff> = {
    data,
    columns,
    initialState: {
      grouping: ['employee'],
      sorting: [
        { id: 'date', desc: false },
        { id: 'employee', desc: false },
      ],
      expanded: true,
      pagination: { pageIndex: 0, pageSize: 500 },
    },
    editDisplayMode: 'row' as MRT_TableOptions<WorkHourOrTimeOff>['editDisplayMode'],
    enableEditing: true,
    enableRowActions: true,
    onEditingRowSave: handleSaveRow,
  };

  return (
    <>
      <MRT
        isLoading={state.isLoading}
        settingsName={VIEW_ID}
        header="Työtunnit"
        extraViewSettings={{ hideEmpty: state.hideEmpty }}
        isValid={state.isValid}
        handleDeleteRow={handleDeleteRow}
        dispatch={dispatch}
        customActions={({ table }) => (
          <>
            <Tooltip arrow={true} title="Luo uusi työtunti">
              <IconButton
                data-cy="create-new-work-hour-button"
                color="success"
                onClick={() => {
                  table.setEditingRow(null);
                  dispatch({
                    type: 'CLEAR_FIELDS',
                  });
                  dispatch({
                    type: 'SET_CREATE_NEW',
                    payload: { createNew: true },
                  });
                  setCreateModalOpen(true);
                  dispatch({
                    type: 'VALIDATE_FIELDS',
                  });
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip arrow={true} title="Pikavalinnat">
              <IconButton
                id="work-hours-extra-menu-button"
                aria-controls={open ? 'work-hours-extra-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <KeyboardDoubleArrowDown />
              </IconButton>
            </Tooltip>
            <Menu
              id="work-hours-extra-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'work-hours-extra-menu-button',
              }}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.hideEmpty}
                      onChange={(_e) => {
                        dispatch({
                          type: 'SET_HIDE_EMPTY',
                          payload: { hideEmpty: !state.hideEmpty },
                        });
                      }}
                    />
                  }
                  label="Piilota tyhjät"
                />
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={(_e) => {
                  table.setGrouping(['employee']);
                  table.setSorting([
                    { id: 'date', desc: false },
                    { id: 'employee', desc: false },
                  ]);
                  handleClose();
                }}
              >
                Ryhmitelle työntekijän mukaan
              </MenuItem>
              <MenuItem
                onClick={(_e) => {
                  table.setGrouping(['organizationName']);
                  table.setSorting([
                    { id: 'date', desc: false },
                    { id: 'organizationName', desc: false },
                  ]);
                  handleClose();
                }}
              >
                Ryhmitelle asiakkaan mukaan
              </MenuItem>
              <MenuItem
                onClick={(_e) => {
                  table.setGrouping(['assignmentName']);
                  table.setSorting([
                    { id: 'date', desc: false },
                    { id: 'assignmentName', desc: false },
                  ]);
                  handleClose();
                }}
              >
                Ryhmitelle työtehtävän mukaan
              </MenuItem>
            </Menu>
          </>
        )}
        {...tableOptions}
      />
      <CreateNewWorkHourModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          dispatch({
            type: 'SET_CREATE_NEW',
            payload: { createNew: false },
          });
          dispatch({
            type: 'VALIDATE_FIELDS',
          });
        }}
        onSubmit={handleCreateNewRow}
        fields={state.fields}
        dispatch={dispatch}
        organizations={state.organizations}
        assignments={state.assignments}
        drivers={state.driverWithWorkHoursAndTimeOffs}
        isValid={state.isValid}
      />
      <Notification {...getSnackbarPropsFromState(state, dispatch)} />
    </>
  );
};

export default WorkHours;
