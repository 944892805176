import { Grid, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { api, ShipmentRow } from '../../api';
import { ShipmentRowProductsModal } from '../ShipmentRowProductsModal';
import { orderBy } from 'lodash';
import { formatShipmentRowState, formatShortDateTime } from '../../formatters';
import { ListAlt } from '@mui/icons-material';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { canAccessCoordination } from '../../utils';

interface ShipmentRowSubRowsProps {
  shipmentRows: ShipmentRow[];
  hideSizeInformation?: boolean;
}

const getStateTimestamp = (shipmentRow: ShipmentRow): string => {
  const state = shipmentRow.state;
  switch (state) {
    case 'ready_for_pickup': {
      return '';
    }
    case 'picked_up': {
      return shipmentRow.picked_up_at ? formatShortDateTime(shipmentRow.picked_up_at) : '';
    }
    case 'lost': {
      return shipmentRow.lost_at ? formatShortDateTime(shipmentRow.lost_at) : '';
    }
    case 'failed': {
      return shipmentRow.failed_at ? formatShortDateTime(shipmentRow.failed_at) : '';
    }
    case 'delivered': {
      return shipmentRow.delivered_at ? formatShortDateTime(shipmentRow.delivered_at) : '';
    }
    default: {
      return '';
    }
  }
};

export const ShipmentRowSubRows: React.FC<ShipmentRowSubRowsProps> = ({ shipmentRows, hideSizeInformation }) => {
  const [modalShipmentRow, setModalShipmentRow] = useState<ShipmentRow | undefined>(undefined);

  const small = hideSizeInformation ? 1 : 0.25;
  const medium = hideSizeInformation ? 1.5 : 0.75;
  const large = hideSizeInformation ? 2 : 1;
  const extraLarge = hideSizeInformation ? 3.5 : 1.5;

  if (shipmentRows.length === 0) {
    return <div>Ei toimitusrivejä.</div>;
  } else {
    return (
      <Grid container sx={{ width: '90vw' }}>
        {orderBy(shipmentRows, ['parcel_type', 'total_weight_kg'], ['asc', 'desc']).map((shipmentRow, index) => {
          return (
            <Grid container spacing={1} key={index} data-cy="shipment-row-grid">
              <Grid item xs={small}></Grid>
              <Grid item xs={large}>
                {shipmentRow.parcel_id ?? ''}
              </Grid>
              <Grid item xs={medium}>
                {shipmentRow.parcel_type ?? ''}
              </Grid>
              <Grid item xs={extraLarge}>
                {shipmentRow.name}
              </Grid>
              {hideSizeInformation ? null : (
                <>
                  <Grid item xs={small}>{`${shipmentRow.quantity} kpl`}</Grid>
                  <Grid item xs={small}>{`${shipmentRow.total_weight_kg} kg`}</Grid>
                  <Grid item xs={small}>{`${shipmentRow.volume_m3 ?? 0} m³`}</Grid>
                  <Grid item xs={small}>{`${shipmentRow.longest_side_length_mm ?? 0} mm`}</Grid>
                </>
              )}
              <Grid item xs={medium}>
                {shipmentRow.state ? formatShipmentRowState(shipmentRow.state) : ''}
              </Grid>
              <Grid item xs={medium}>
                {getStateTimestamp(shipmentRow)}
              </Grid>
              <Grid item xs={small}>
                <Tooltip
                  data-cy="show-shipment-row-products-button-tooltip"
                  placement="bottom"
                  title={'Näytä lähetysluettelo'}
                  aria-label={'Näytä lähetysluettelo'}
                >
                  <span>
                    <IconButton
                      data-cy="show-shipment-row-products-button"
                      onClick={() => setModalShipmentRow(shipmentRow)}
                      size="small"
                    >
                      <ListAlt />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          );
        })}
        {modalShipmentRow && (
          <ShipmentRowProductsModal
            shipmentRow={modalShipmentRow}
            open={modalShipmentRow !== undefined}
            onClose={() => setModalShipmentRow(undefined)}
          />
        )}
      </Grid>
    );
  }
};

interface SubRowAsyncProps {
  row: any;
  hideSizeInformation?: boolean;
}

export const ShipmentRowSubRowAsync: React.FC<SubRowAsyncProps> = ({ row, hideSizeInformation }) => {
  const [shipmentRows, setShipmentRows] = useState<ShipmentRow[]>([]);
  const currentUser = useCurrentUser();
  const hasCoordinationAccess = canAccessCoordination(currentUser);

  const load = async () => {
    const shipmentRows = hasCoordinationAccess
      ? (
          await api.shipments.getShipmentRows({
            shipmentId: Number(row.original.id),
          })
        ).data
      : (
          await api.organizationShipments.getOrganizationShipmentRows({
            organizationId: currentUser?.organization_id ?? '',
            shipmentId: Number(row.original.id),
          })
        ).data;
    setShipmentRows(shipmentRows as ShipmentRow[]);
  };

  useEffect(() => {
    load();
  }, [row.original.id]);

  return <ShipmentRowSubRows shipmentRows={shipmentRows} hideSizeInformation={hideSizeInformation} />;
};
